import React, { useState,useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import EventPackageInfo from "../../Components/EventPackageInfo/EventPackageInfo";
import TouristicDestinationSidebar from "../../Components/TouristicDestinationSidebar/TouristicDestinationSidebar";
import TouristicDestinationInfo from "../../Components/TouristicDestinationInfo/TouristicDestinationInfo";
import useTouristicDestinationPackage from "../../library/hooks/useTouristicDestination/useTouristicDestination";


const useStyles = makeStyles((theme) => ({
  link: {
    color: "#26529c",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      color: "#26529c",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  separator: {
    fontSize: "20px",
    color: "#26529c",
  },
}));



export default function TouristicDestinationPage() {
  const navigate = useNavigate();
  let { touristicDestinationId } = useParams();
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  const {
    callFetchAllTouristicDestinationApi,
    callCreateTouristicDestinationApi,
    callUpdateTouristicDestinationApi,
    callFetchTouristicDestinationByIdApi,
  } = useTouristicDestinationPackage();
  const [form, setForm] = useState({
    id: "-",
    name: "",
    startingPrice:"",
    order: null,
    packageType:"",
    externalRedirect:"",
    images: [],
    isActive: false,
    createdDate: new Date().toISOString(),
    createdBy: "-",
    touristicId: "",
    selectedImageFileChanged: false,
  });

  const classes = useStyles();

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const sendValueToParent = ()=>{

  }

  useEffect(() => {
    if (touristicDestinationId && touristicDestinationId != 0) {
      callFetchTouristicDestinationByIdApi(touristicDestinationId)
        .then((res) => {
          if (res && res.data) {
            setForm(res.data);
          } else {
            alert("No record Found!");
            navigate("/touristic-destination");
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    } 
  }, [touristicDestinationId]);

  const saveInformation = async (
    name,
    startingPrice,
    order,
    packageType,
    externalRedirect,
    images,
    selectedImageFileChanged
  ) => {
    let tempObject = {
      ...form,
      name: name,
      startingPrice:startingPrice,
      order: order == null ? 0 : order,
      packageType:packageType,
      externalRedirect:externalRedirect,
      images:images,
      selectedImageFileChanged : selectedImageFileChanged
    };
    console.log(tempObject,"<==Temp")
    await save(tempObject);
  };

  const save = async (saveObject) => {
    //call Api
    let imagefiles = null;
      imagefiles = saveObject.images;

      let formData = form;

    if (saveObject.id === "-") {

      let tempObject = {
        ...saveObject,
        images : [],
      };
      
      await callCreateTouristicDestinationApi(tempObject,imagefiles)
        .then((res) => {
          if (res.isSuccess) {
            // navigate(`/touristic-destination/${res.data.id}`);
            navigate(`/hoescape`);
            
          } else {
            console.error("Error: Create Touristic Destination", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Create Touristic Destination", err);
        });
    } else {
      await callUpdateTouristicDestinationApi(
        {
          ...saveObject,
          images: saveObject.selectedImageFileChanged ? "" : saveObject.images,
        },
        saveObject.selectedImageFileChanged ? saveObject.images : null // send image file 
      )
        .then((res) => {
          if (res.isSuccess) {

            navigate(`/hoescape`);
           
              // navigate(
              //   `/touristic-destination/${
              //     res.data.id !== null &&
              //     res.data.id !== undefined &&
              //     res.data.id !== ""
              //       ? res.data.id
              //       : ""
              //   }${res.data.id}`
              // );
           
          } else {
            console.error("Error: Update Touristic Destination", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Update Touristic Destination", err);
        });
    }
  };

  const CustomSeparator = () => (
    <Typography variant="body1" className={classes.separator}>
      ›
    </Typography>
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/hoEscape"
      className={classes.link}
    >
      HoEscape
    </Link>,
    <Link underline="hover" key="2" color="inherit" className={classes.link}>
      Create Touristic Destination
    </Link>,
  ];

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ height: "48px", padding: "10px" }}>
        <Stack spacing={2} sx={{ marginLeft: "20px" }}>
          <Breadcrumbs separator={<CustomSeparator />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        sx={{ backgroundColor: "#f2f2f2", minHeight: "90vh", padding: "40px" }}
      >
        <Box sx={{ display: "flex" }}>
          <TouristicDestinationSidebar />
          <TouristicDestinationInfo 
           propName={form.name}
           propStartingPrice={form.startingPrice}
           propOrder={form.order}
           propPackageType = {form.packageType}
           propExternalRedirect = {form.externalRedirect}
           propImages = {form.images}
           propImageEditCase = {form.selectedImageFileChanged}
           sendValueToParent={saveInformation}
          />
        </Box>
      </Box>
    </Layout>
  );
}
