export function formatAmount(amount) {
    // Check if the amount is valid
    if (amount == null || isNaN(amount)) {
        return ''; // Return an empty string or any default value if the amount is not valid
    }

    // Use Intl.NumberFormat to format the number
    const formattedAmount = new Intl.NumberFormat('de-DE', { 
        style: 'currency', 
        currency: 'EUR', 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
    }).format(amount);

    // Remove the space between the number and the currency symbol
    return formattedAmount.replace(/\s/g, '');
}
