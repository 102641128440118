import { Box } from "@mui/material";
import DashboardHeader from "Components/Header/DashboardHeader";
import Layout from "Components/Layout/Layout";
import React, { useState } from "react";
import StatisticsCard from "./components/StatisticsCard";
import TopHotels from "./components/TopHotels";
import PopularContantSource from "./components/PopularContantSource";
import BrandCard from "./components/BrandCard";
import RecommandedRemarketingActions from "./components/RecommandedRemarketingActions";
// import { Notification } from "Pages/Team/Components/Notification";
import { DashboardNotification } from "./components/DashboardNotification";
// import DashboardTask from "./Components/DashboardTask";
import BarChart from "./components/BarChart";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import { format } from "date-fns";
import { it } from "date-fns/locale";

const Dashboard = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const { state: employeeState } = useAuthenticationContext();

  const today = new Date();
  const formattedDate = format(today, "EEEE, d MMMM yyyy", { locale: it });

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };
  return (
    <Layout
      handleToggle={handleToggle}
      isSidebarOpened={isSidebarOpened}
      isMessageIconVisable={false}
      backgroundColor="#F2F2F2"
    >
      <Box
        sx={{ marginLeft: "50px", marginRight: "50px", marginBottom: "20px" }}
      >
        <DashboardHeader
          title={`Bentornato, ${employeeState.userData?.data?.name}`}
          subtitle={formattedDate}
          isDatePicker={true}
        />

        <div className="flex space-x-[18px] mt-[28px]">
          {/* statistics  */}
          <div className="w-[72%]">
            {/* common statistics data and top 3 hotel  */}
            <div>
              {/* statistic cards  */}
              <div className="flex items-center space-x-[18px]">
                <StatisticsCard />
                <TopHotels />
              </div>
              {/* top 3 hotel  */}
              <div></div>
            </div>
            {/* bar chart  */}
            <BarChart />
            {/* content source and recommanded remarketing actions  */}
            <div>
              {/* popular content source and brand name  */}
              <div className="mt-[18px] flex items-start space-x-[18px]">
                {/* popular contant source  */}
                <div className="w-[40%]">
                  <PopularContantSource />
                  {/* brand name  */}
                  <BrandCard />
                </div>
                {/* recommanded remarketing actions  */}
                <RecommandedRemarketingActions />
              </div>
            </div>
          </div>
          {/* notification and task  */}
          <div className="w-[28%] space-y-[18px]">
            {/* notification */}
            <DashboardNotification />
            {/* task  */}
            {/* <DashboardTask /> */}
          </div>
        </div>
      </Box>
    </Layout>
  );
};

export default Dashboard;
