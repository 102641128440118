import { PERMISSIONS } from "utils/permisson";

export const checkUserPermissions = (requiredPermission, user) => {
  const userRoles = user.roles.map((role) => role.name);
  if (userRoles.includes("ADMIN")) {
    return true;
  }

  const userPermissions = user.roles.flatMap((role) =>
    role.rights.map((right) => right.name)
  );
  return userPermissions.includes(requiredPermission);
};

export const securityLevelToPermissionMap = {
  Admin: PERMISSIONS.CREATE_ADMIN_USERS,
  Statista: PERMISSIONS.CREATE_STATISTA_USERS,
  "Contatti e Negoziazioni": PERMISSIONS.CREATE_CONTACT_NEGOTIATION_USERS,
  HoEscape: PERMISSIONS.CREATE_HOESCAPE_USERS,
  Hotel: PERMISSIONS.CREATE_HOTEL_USERS,
  Custom: PERMISSIONS.CREATE_CUSTOM_USERS,
};

export const rightsOptionMapping = {
  "L'utente può creare Utenti Admin": "CREATE_ADMIN_USERS",
  "L'utente può creare Utenti Statista": "CREATE_STATISTA_USERS",
  "L'utente può creare Utenti Contatti e Negoziazioni":
    "CREATE_CONTACT_NEGOTIATION_USERS",
  "L'utente può creare Utenti HoEscape": "CREATE_HOESCAPE_USERS",
  "L'utente può creare Utenti Hotel": "CREATE_HOTEL_USERS",
  "L'utente può creare Utenti Custom": "CREATE_CUSTOM_USERS",
  "L'utente può vedere le statistiche del sito HoEscape": "VIEW_HOESCAPE_STATS",
  "L'utente può vedere le statistiche degli Hotel": "VIEW_HOTEL_STATS",
  "L'utente può vedere le statistiche dei Plugin WP": "VIEW_WP_STATS",
  "L'utente può vedere i Contatti e Negoziazioni":
    "VIEW_CONTACT_NEGOTIATION_STATS",
  "L'utente può modificare i Contatti e Negoziazioni":
    "EDIT_CONTACT_NEGOTIATION",
  "L'utente può creare, eliminare o modificare i Plugin WP": "EDIT_WP_PLUGINS",
  "L'utente può modificare gli Hotel": "EDIT_HOTEL",
  "L'utente può modificare il sito HoEscape": "EDIT_HOESCAPE",
};

export const securityLevelToRoleMapping = {
  Admin: PERMISSIONS.ADMIN,
  Statista: PERMISSIONS.STATISTICS,
  "Contatti e Negoziazioni": PERMISSIONS.CONTACTS_NEGOTIATIONS,
  HoEscape: PERMISSIONS.HOESCAPE,
  Hotel: PERMISSIONS.HOTEL,
  Custom: PERMISSIONS.CUSTOM,
};

export const reverseRightsOptionMapping = Object.fromEntries(
  Object.entries(rightsOptionMapping).map(([key, value]) => [value, key])
);

export const reverseSecurityLevelToRoleMapping = Object.fromEntries(
  Object.entries(securityLevelToRoleMapping).map(([key, value]) => [value, key])
);
