import { createContext, useContext, useReducer } from "react";
import hotelsDashboardReducer from "../reducers/HotelsDashboarReducer";

export const HotelsDashboardContext = createContext({
    state: {...hotelsDashboardReducer.initialState },
    dispatch: () => {},
});

export const useHotelsDashboardContext = () => useContext(HotelsDashboardContext);

function HotelsDashboardSlicer({children}) {
    const [state, dispatch] = useReducer(
        hotelsDashboardReducer.reducer,
        hotelsDashboardReducer.initialState,
    );

    return (
        <HotelsDashboardContext.Provider value={{state, dispatch}}>
            {children}
        </HotelsDashboardContext.Provider>
    );
}

export default HotelsDashboardSlicer;