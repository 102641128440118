import React from "react";
import hoEscape from "assets/images/ho-escape.png";

const AuthCard = ({ title, subtitle }) => {
  return (
    <div className="bg-primary p-[26px] rounded-[15px] w-full">
      <div className="flex justify-end items-center space-x-1">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1083_4829)">
            <path
              d="M2.72292 0.746448C3.00081 0.803884 3.22212 0.990593 3.35962 1.23727L3.79764 2.02309C3.95898 2.31251 4.09475 2.55608 4.18342 2.76782C4.27748 2.99242 4.33334 3.21391 4.30781 3.45879C4.28227 3.70366 4.18191 3.90886 4.04354 4.10923C3.91309 4.29811 3.72998 4.50842 3.5124 4.75834L2.86852 5.49793C2.75314 5.63046 2.69545 5.69673 2.69017 5.77955C2.6849 5.86238 2.73219 5.93313 2.82675 6.07461C3.63274 7.28051 4.71902 8.36715 5.9259 9.17383C6.06739 9.2684 6.13813 9.31569 6.22096 9.31042C6.30379 9.30515 6.37005 9.24745 6.50259 9.13207L7.24222 8.48816C7.49213 8.27057 7.70245 8.08747 7.89133 7.95702C8.0917 7.81865 8.29689 7.71829 8.54177 7.69275C8.78665 7.66721 9.00814 7.72308 9.23274 7.81714C9.44445 7.90579 9.68802 8.04157 9.97741 8.20288L10.7633 8.64094C11.01 8.77844 11.1967 8.99975 11.2541 9.27764C11.3121 9.55843 11.2265 9.84067 11.0375 10.0726C10.3515 10.9143 9.25238 11.4502 8.09938 11.2176C7.39064 11.0747 6.69136 10.8365 5.84559 10.3514C4.14623 9.37686 2.62257 7.85236 1.64912 6.15497C1.16407 5.3092 0.925881 4.60992 0.782913 3.90118C0.550325 2.74818 1.08623 1.64909 1.92791 0.963083C2.15988 0.774019 2.44212 0.688412 2.72292 0.746448Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1083_4829">
              <rect
                width="11.7692"
                height="11.7692"
                fill="white"
                transform="translate(0.115356 0.115234)"
              />
            </clipPath>
          </defs>
        </svg>
        <p className="text-white text-[15px] font-normal leading-[22.5px]">
          +39 338 398 0892
        </p>
      </div>
      <div className="flex flex-col items-center justify-center ">
        <div>
          <img className="w-[521px] " src={hoEscape} alt="logo" />

          <div className="mt-[92px] flex-1 justify-self-start">
            <h2 className="text-[40px] font-semibold text-white leading-[60px]">
              {title}
            </h2>
            <p className="text-[20px] leading-[30px] font-light text-gray-50">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthCard;
