import { useCallback } from "react";
import { useNegotiationsContext } from "../../store/slices/NegotiationsSlicer";
import { FetchNegotiations } from "../../store/reducers/NegotiationsReducer";
import NegotiationsService from '../../services/API/negotiations';

const useNegotiations = () => {
    const { dispatch: fetchNegotiationsDispatch } = useNegotiationsContext();

    const callFetchAllNegotiationsApi = useCallback(
        async (body) => {
            await NegotiationsService.fetchAllNegotiations(body).then((response) => {
                fetchNegotiationsDispatch(FetchNegotiations(response));
            });
        }, [fetchNegotiationsDispatch]);

    return {
        callFetchAllNegotiationsApi
    };
}
export default useNegotiations;

