import axiosConfig from "../axiosConfig";
class EventPackageService {
  fetchAllEventPackages = async () => {
    return await axiosConfig.get(`/EventPackage/getAll`);
  };

  fetchEventPackageById = async (eventPackageId) => {
    if (eventPackageId) {
      return await axiosConfig.get(`/EventPackage/get/${eventPackageId}`);
    }
    console.warn(
      "eventPackageId not specified, EventPackageService.fetchEventPackageById"
    );

    return null;
  };


  createEventPackage = async (payload) => {
    if (payload) {
      return await axiosConfig.post(`/EventPackage/create`, payload);
    }
    console.warn(
      "payload not provided, EventPackageServices.eventPackageParams"
    );
    return null;
  };

  updateEventPackage = async (payload) => {
    if (payload) {
      return await axiosConfig.post(`/EventPackage/update`, payload);
    }
    console.warn(
      "payload not provided, EventPackageServices.eventPackageParams"
    );
    return null;
  };

  deleteEventPackageById = async (eventPackageId) => {
    if (eventPackageId) {
      return await axiosConfig.delete(
        `/EventPackage/delete/${eventPackageId}`
      );
    }
    console.warn(
      "eventPackageId not specified, EventPackageIdService.deleteEventPackageIdById"
    );

    return null;
  };

  enableDisableEventPackageById = async (eventPackageId, isEnabled) => {
    if (eventPackageId) {
      return await axiosConfig.get(
        `/EventPackage/enableDisable/${eventPackageId}/${isEnabled}`
      );
    }
    console.warn(
      "eventPackageId not specified, EventPAckageService.deleteEventPackageById"
    );

    return null;
  };
}

const instance = new EventPackageService();

export default instance;
