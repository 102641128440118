import React from "react";
import { Box, Modal, Button } from "@mui/material";
import PropTypes from "prop-types";

export default function ImageModal({
  hotelImages,
  isOpen,
  onClose,
  selectedImages,
  setSelectedImages,
}) {
  const toggleImageSelection = (selectedImage) => {
    if (selectedImages.some((image) => image.imageUrl === selectedImage)) {
      setSelectedImages(
        selectedImages.filter((image) => image.imageUrl !== selectedImage)
      );
    } else {
      setSelectedImages([...selectedImages, { imageUrl: selectedImage }]);
    }
  };

  const handleDoneClick = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: "20px",
          borderRadius: "10px",
          minWidth: "500px",
          maxHeight: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Select Image</h2>
          <Box onClick={onClose} sx={{ cursor: "pointer" }}>
            <h2>X</h2>
          </Box>
        </Box>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "calc(800px - 120px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {hotelImages?.map((image, index) => {
              const newRoot = "https://hoescapeapi2.azurewebsites.net";

              const newImageUrl = image?.imageUrl?.replace(
                /https:\/\/localhost:\d+/,
                newRoot
              );
              return (
                <img
                  key={index}
                  src={newImageUrl}
                  alt={`Hotel${index + 1}`}
                  style={{
                    width: "200px",
                    height: "150px",
                    margin: "10px",
                    border: selectedImages?.some(
                      (item) => item.imageUrl === newImageUrl
                    )
                      ? "2px solid blue"
                      : "none",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleImageSelection(newImageUrl)}
                />
              );
            })}
          </Box>
        </div>
        <Button
          variant="contained"
          onClick={handleDoneClick}
          sx={{ margin: "10px", display: "flex", justifyContent: "center" }}
        >
          Done
        </Button>
      </Box>
    </Modal>
  );
}

ImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
