import { createContext, useContext, useReducer } from "react";
import  contactReducer from "../reducers/ContactReducer";

export const ContactContext = createContext({
    state: {...contactReducer.initialState },
    dispatch: () => { },
});

export const useContactContext = () => useContext(ContactContext);

function ContactSlicer({ children }) { 
    const [state, dispatch] = useReducer(
        contactReducer.reducer,
        contactReducer.initialState,
    );
   
    return (
        <ContactContext.Provider value={{state, dispatch}}>
            {children}
        </ContactContext.Provider>
    );
}

export default ContactSlicer;