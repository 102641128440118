import React, { useState } from "react";
import phoneIcon from "assets/icons/call2.svg";
import emailIcon from "assets/icons/email2.svg";
import personIcon from "assets/icons/person.svg";

const remarketingActionsData = [
  {
    id: 1,
    name: "Maria Roselia",
    description: "Ha visitato più volte lo stesso hotel",
  },
  {
    id: 2,
    name: "Ralph Edwards",
    description:
      "Ha effettuato 5 richieste di preventivo negli ultimi 7 giorni",
  },
  {
    id: 3,
    name: "Darrell Steward",
    description: "Ha visitato più volte lo stesso hotel",
  },
  {
    id: 4,
    name: "Edwin Adenike",
    description:
      "Ha effettuato 5 richieste di preventivo negli ultimi 7 giorni",
  },
  {
    id: 5,
    name: "Sophia Nguyen",
    description: "Has viewed luxury car listings multiple times.",
  },
  {
    id: 6,
    name: "Jacob Smith",
    description:
      "Has added items to the shopping cart but not completed the purchase.",
  },
  {
    id: 7,
    name: "Emily Johnson",
    description: "Has clicked on email links for vacation packages.",
  },
  {
    id: 8,
    name: "David Martinez",
    description: "Has spent more than 10 minutes on the pricing page.",
  },
  {
    id: 9,
    name: "Ava Wilson",
    description:
      "Has subscribed to the newsletter but not engaged with any emails.",
  },
  {
    id: 10,
    name: "Michael Thompson",
    description: "Has attended three webinars in the past month.",
  },
];

const RecommandedRemarketingActions = () => {
  const [displayedItems, setDisplayedItems] = useState(4);

  const loadAll = () => {
    setDisplayedItems(remarketingActionsData.length);
  };

  return (
    <div className="bg-white rounded-[20px] w-[60%] h-[460px] overflow-y-auto col-span-2 shadow-lg pb-[22px] px-[32px]">
      <div className="sticky top-[0px] pt-[23px] pb-[14px] bg-white z-10">
        <h2 className="text-[18px] font-bold text-[#1E1B39]">
          Azioni di Remarketing Consigliate
        </h2>
        <p className="text-[12px] leading-[13px] text-[#84818A]">Dati</p>
      </div>
      <div className="space-y-[8px]">
        {remarketingActionsData
          .slice(0, displayedItems)
          .map(({ id, name, description }) => (
            <div
              key={id}
              className="border-[1.2px] border-[#E5E5E5] rounded-[4px] h-[74px] shadow-sm"
            >
              <div className="flex items-center justify-between pl-[14px] pr-[12px] pt-[10px]">
                <h2 className="text-[14px] font-semibold text-[#2E2C34]">
                  {name}
                </h2>
                <div className="flex items-center space-x-[6px]">
                  <div className="w-[24px] flex items-center justify-center h-[24px] p-[4.62px] rounded-full cursor-pointer bg-[#005CAB1A] ">
                    <img src={phoneIcon} alt="phone" />
                  </div>
                  <div className="w-[24px] flex items-center justify-center h-[24px] p-[4.62px] rounded-full cursor-pointer bg-[#005CAB1A] ">
                    <img src={emailIcon} alt="email" />
                  </div>{" "}
                  <div className="w-[24px] flex items-center justify-center h-[24px] p-[4.62px] rounded-full cursor-pointer bg-[#005CAB1A] ">
                    <img src={personIcon} alt="person" />
                  </div>
                </div>
              </div>
              <div className="bg-[#F5F5F5] mt-[9px] pl-[15px] h-[32px] flex items-center border">
                <h2 className="text-[12px] font-semibold text-[#191919] leading-[18px]">
                  {description}
                </h2>
              </div>
            </div>
          ))}
      </div>
      {displayedItems < remarketingActionsData.length && (
        <h2
          onClick={loadAll}
          className="mt-[16px] cursor-pointer text-center text-[16px] font-semibold text-[#005CAB]"
        >
          Vedi Tutte
        </h2>
      )}
    </div>
  );
};

export default RecommandedRemarketingActions;
