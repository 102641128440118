import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import deleteIcon from "../../assets/delete.svg";
import circularIcon from "../../assets/circular.svg";
import leftIcon from "../../assets/Polygon1.svg";
import rightIcon from "../../assets/Polygon2.svg";
import HotelDashboardService from "../../library/services/API/hotelDashboard";
import useHotelDashboard from "../../library/hooks/UserHotelDashboard/useHotelDashboard";
import MiniModificaBanner from "../../Pages/MiniModificaBanner/MiniModificaBanner";

const scrollbarStyles = `
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent; /* make scrollbar transparent */
    }
`;

export default function MiniBannerCard() {
  const [dataArr, setDataArr] = useState([]);
  const [items, setItems] = useState(dataArr);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [imageDetails, setImageDetails] = useState();

  const updateDataArr = (index, newData) => {
    const updatedDataArr = [...dataArr];
    // const index = updatedDataArr.length;
    updatedDataArr[index] = { ...newData };
    setDataArr(updatedDataArr);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HotelDashboardService.getBannerImages({
          type: "MINI",
        });
        setDataArr(response);
      } catch (error) {
        console.error("Error fetching hotel banner:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setItems(dataArr);
  }, [dataArr, updateDataArr]);

  const handleAddButton = () => {
    setIsModalOpen(true);
    setIsEdit(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setImageDetails();
    setIsEdit(false);
  };

  const handleDeleteImage = async (index, imageId) => {
    if (window.confirm("Vuoi rimuovere questa immagine? (la modifica verrà effettuata solo al click del pulsante salva alla fine)")) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
      // updateDataArr(index, updatedItems[index]);
      await HotelDashboardService.deteleBannerImages(imageId);
      try {
        const response = await HotelDashboardService.getBannerImages({
          type: "MINI",
        });
        setDataArr(response);
      } catch (error) {
        console.error("Error fetching hotel banner:", error);
      }
    }
  };

  const handleEditImage = (index, item) => {
    setImageDetails(item);
    setEditIndex(index);
    setIsModalOpen(true);
    setIsEdit(true);
  };
  return (
    <>
      <style>{scrollbarStyles}</style>
      <Box sx={{ overflowX: "auto", overflowY: "hidden", borderRadius: "5px" }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginLeft: "30px", display: "flex" }}>
            {items?.map((item, index) => (
              <Box sx={{ display: "flex", position: "relative" }} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "-65px",
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: "20px",
                      height: "27px",
                      position: "relative",
                      right: "30px",
                      fontSize: "30px",
                      fontWeight: "600",
                      color: "white",
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "489px",
                    height: "147px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "32px",
                    fontWeight: "600",
                    textDecoration: "underline",
                    flexShrink: 0,
                    maxWidth: "310px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={item?.imageUrl}
                    alt={item?.altText}
                    style={{ width: "310px" }}
                    data-tags={item?.tagMetaTag}
                    data-author={item?.authorMetaTag}
                    data-copyright={item?.copyRightMetaTag}
                  />
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    top: "10px",
                    right: "70px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEditImage(index, item)}
                >
                  <img src={circularIcon} alt="img" />
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    top: "10px",
                    right: "60px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="img"
                    onClick={() => handleDeleteImage(index, item?.id)}
                  />
                </Box>
              </Box>
            ))}
            {dataArr?.length === 0 && (
              <Button
                sx={{
                  marginLeft: "-20px",
                  width: "489px",
                  height: "147px",
                  border: "1px dashed #26529C",
                  borderWidth: "2px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                }}
                onClick={handleAddButton}
              >
                <Typography
                  sx={{
                    color: "#26529C",
                    textDecoration: "underline",
                    fontSize: "32px",
                    fontWeight: "600",
                    textTransform: "none",
                  }}
                >
                  Carica Immagine
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {isModalOpen && (
        <MiniModificaBanner
          onClose={handleCloseModal}
          updateDataArr={updateDataArr}
          index={editIndex}
          item={items[editIndex]}
          imageDetails={imageDetails}
          isEdit={isEdit}
          setDataArr={setDataArr}
        />
      )}
    </>
  );
}
