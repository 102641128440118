import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Store from "./library/store/Store";
import ProtectedRoute from "utils/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routesConfig } from "utils/routesConfig";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const theme = createTheme({
  palette: {
    background: {
      default: "#26529C",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <ToastContainer />
      <BrowserRouter>
        <Store>
          <Routes>
            {routesConfig.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.requiredAuthentication ? (
                    <ProtectedRoute
                      requiredPermissions={route.requiredPermissions}
                    >
                      {route.element}
                    </ProtectedRoute>
                  ) : (
                    route.element
                  )
                }
              />
            ))}
          </Routes>
        </Store>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
