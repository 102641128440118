import { createContext, useContext, useReducer } from "react";
import  hotelDashboardConfigurationReducer from "../reducers/HotelDashboardConfigurationReducer";

export const HotelDashboardConfigurationContext = createContext({
    state: {...hotelDashboardConfigurationReducer.initialState },
    dispatch: () => { },
});

export const useHotelConfigurationContext = () => useContext(HotelDashboardConfigurationContext);

function HotelConfigurationSlicer({ children }) {
    const [state, dispatch] = useReducer(
        hotelDashboardConfigurationReducer.reducer,
        hotelDashboardConfigurationReducer.initialState,
    );

    return (
        <HotelDashboardConfigurationContext.Provider value={{state, dispatch}}>
            {children}
        </HotelDashboardConfigurationContext.Provider>
    );
}

export default HotelConfigurationSlicer;