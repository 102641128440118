import { useCallback } from "react";
import EventPackageService from "../../services/API/EventPackageService";

const useEventPackage = () => {
  const callFetchAllEventPackagesApi = useCallback(async () => {
    return await EventPackageService.fetchAllEventPackages().then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callFetchEventPackageByIdApi = useCallback(async (eventPackageId) => {
    return await EventPackageService.fetchEventPackageById(
      eventPackageId
    ).then((response) => {
      return response;
    });
  }, []);

  const callCreateEventPackageApi = useCallback(async (payload) => {
    return await EventPackageService.createEventPackage(payload).then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callUpdateEventPackageApi  = useCallback(async (payload) => {
    return await EventPackageService.updateEventPackage(payload).then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callDeleteEventPackageByIdApi = useCallback(
    async (eventPackageId) => {
      return await EventPackageService.deleteEventPackageById(
        eventPackageId
      ).then((response) => {
        return response;
      });
    },
    []
  );
  const callEnableDisableEventPackageByIdApi = useCallback(
    async (eventPackageId, isEnabled) => {
      return await EventPackageService.enableDisableEventPackageById(
        eventPackageId,
        isEnabled
      ).then((response) => {
        return response;
      });
    },
    []
  );

  return {
    callFetchAllEventPackagesApi,
    callFetchEventPackageByIdApi,
    callCreateEventPackageApi,
    callUpdateEventPackageApi,
    callDeleteEventPackageByIdApi,
    callEnableDisableEventPackageByIdApi
  };
};

export default useEventPackage;
