import React, { useEffect, useState } from "react";
import ArrowTextButtonHeader from "../../../Components/Header/ArrowTextButtonHeader";
import "react-international-phone/style.css";
import { PhoneInput } from "react-international-phone";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { MenuItem, Select } from "@mui/material";
import dayjs from 'dayjs';
import useContactSetting from "../../../library/hooks/Contact/Contact";

const AboutContactForm = ({ employees, info, type }) => {
  const [expandForm, setExpandForm] = useState(false);
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [numeroTelefono, setNumeroTelefono] = useState("");
  const [eta, setEta] = useState(null);
  const [luogoNascita, setLuogoNascita] = useState("");
  const [assegnatoA, setAssegnatoA] = useState("");
  const [tipoCliente, setTipoCliente] = useState("");
  const [ultimaInterazioneOperatori, setUltimaInterazioneOperatori] =
    useState("");
  const [ultimaInterazioneEseguitaDa, setUltimaInterazioneEseguitaDa] =
    useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);

  const [dateValue, setDateValue] = React.useState(null);
  const [infoData, setInfoData] = useState();

  const { dispatchForContactNegotiationsAssignedToUpdate } = useContactSetting();

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birth = new Date(birthdate);
    let age = today.getFullYear() - birth.getFullYear();
    const month = today.getMonth() - birth.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (birthDate) => {
    const age = calculateAge(birthDate);
    setEta(age);
    setDateValue(birthDate);
  };

  useEffect(() => {
    if (type == "contact") {
      if (info != null) {
        const dateObject = dayjs(info?.birthDate);
        handleDateChange(dateObject)
        setInfoData(info);
        const assignedTo = assegnatoA == "" || assegnatoA == null ? info?.assignedTo : assegnatoA;
        setAssegnatoA(assignedTo);
      }
      setInfoData(info);
    } else {
      if (info != null && info?.contactNegotiation != null && info?.contactNegotiation.negotiations.length > 0) {
        setInfoData(info?.contactNegotiation.negotiations[0]);
        const assignedTo = assegnatoA == "" || assegnatoA == null ? info?.contactNegotiation.negotiations[0].assignedTo : assegnatoA;
        setAssegnatoA(assignedTo);
      }
    }
  });

  const OnChangeAssignedTo = (value) => {
    let body = { id: info?.id, type: type == "contact" ? "contact" : "negotiation", assignedTo: value };
    setAssegnatoA(value);
    dispatchForContactNegotiationsAssignedToUpdate(body);
  }

  const handleKeyDown = (event, fieldName) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      switch (fieldName) {
        case "nome":
          setNome(value);
          break;
        case "cognome":
          setCognome(value);
          break;
        case "numeroTelefono":
          setNumeroTelefono(value);
          break;
        case "assegnatoA":
          setAssegnatoA(value);
          break;
        case "tipoCliente":
          setTipoCliente(value);
          break;
        case "ultimaInterazioneOperatori":
          setUltimaInterazioneOperatori(value);
          break;
        case "ultimaInterazioneEseguitaDa":
          setUltimaInterazioneEseguitaDa(value);
          break;
        default:
          break;
      }
    }
  };

  const handleSearchChange = async (event) => {
    const searchTerm = event.target.value.trim();
    setSearchTerm(searchTerm);

    if (!searchTerm) {
      setSearchResults([]);
      setShowSearchDropdown(false);
      return;
    }

    const provider = new OpenStreetMapProvider();
    const results = await provider.search({ query: searchTerm });
    setSearchResults(results);
    setShowSearchDropdown(true);
  };

  const handleSelectLocation = (result) => {
    setSearchTerm(result.label);
    setSearchResults([]);
    setShowSearchDropdown(false);
  };

  return (
    <div>
      <ArrowTextButtonHeader
        title="Informazioni su questo contatto"
        marginTop={15}
        paddingLeft={38}
        onClick={() => setExpandForm(() => !expandForm)}
        isExpand={expandForm}
      />

      <div
        className={`mt-[15px] px-[38px] transition-[height] duration-200 ease-in-out ${expandForm ? "h-auto" : "h-0 overflow-hidden"
          }`}
      >
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Nome
        </label>
        <input
          onKeyDown={(event) => handleKeyDown(event, "nome")}
          placeholder="Nome"
          value={infoData?.firstName}
          className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        />
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Cognome
        </label>
        <input
          onKeyDown={(event) => handleKeyDown(event, "cognome")}
          placeholder="Cognome"
          value={infoData?.lastName}
          className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        />
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Numero di Telefono
        </label>
        <PhoneInput
          placeholder="Numero di Telefono"
          inputClassName="!w-full !h-full relative !bg-transparent placeholder:!text-primary placeholder:!text-opacity-50 !text-[20px] !font-semibold !text-primary"
          defaultCountry="it"
          countrySelectorStyleProps={{
            buttonStyle: {
              background: "transparent",
              height: "100%",
            },

            dropdownArrowStyle: { color: "#26529C" },
            dropdownStyleProps: {
              className:
                "!max-w-[270px] bg-white outline-none border-none rounded-[10px] !p-[10px]",
            },
          }}
          inputProps={{
            onKeyDown: (event) => handleKeyDown(event, "numeroTelefono"),
          }}
          value={infoData?.phoneNumber}
          className="mt-[4px]  mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        />
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Età
        </label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            sx={{
              marginTop: "4px",
              paddingTop: "0px",
              marginBottom: "8px",
              height: "52px",
              background: "#26529C12",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row-reverse !important",
              justifyContent: "center",
              alignItems: "center",
              paddingX: "16px",

              "& .MuiFormControl-root": {
                width: "100%",
                maxWidth: "auto",
              },
            }}
            components={["DatePicker"]}
          >
            <DatePicker
              disableFuture={true}
              value={dateValue}
              onChange={(newValue) => handleDateChange(newValue)}
              slotProps={{
                // layout: {
                //   sx: {
                //     width: "200px",

                //     "& .MuiDateCalendar-root": {
                //       borderRadius: "30px !importand",
                //     },
                //   },
                // },
                textField: {
                  variant: "outlined",
                  sx: {
                    "& .MuiInputBase-root": {
                      paddingX: "0px",
                      color: "#26529C",
                      fontSize: "20px",
                      fontWeight: "600",

                      paddingY: "0px",
                    },
                    "& .MuiInputBase-input": {
                      padding: "0px",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                },
              }}
            />
            {eta !== null && (
              <h3 className="text-[20px] !ml-0 !mt-0 !mr-2 font-semibold text-primary ">
                ({eta})
              </h3>
            )}
          </DemoContainer>
        </LocalizationProvider>
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Luogo di Nascita
        </label>
        <input
          type="text"
          placeholder="Luogo di Nascita"
          className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
          value={info?.birthPlace?.name}
          onChange={handleSearchChange}
        />
        {searchTerm && showSearchDropdown && searchResults.length > 0 && (
          <ul className="bg-white pt-2  pb-4 shadow-lg rounded-[5px] max-h-[200px] overflow-auto list-none">
            {searchResults.map((result) => (
              <li
                onClick={() => handleSelectLocation(result)}
                className="text-primary px-4 text-[16px] cursor-pointer py-2 hover:bg-slate-200"
                key={result.x}
              >
                {result.label}
              </li>
            ))}
          </ul>
        )}
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Assegnato a
        </label>
        <select
          onChange={(event) => OnChangeAssignedTo(event.target.value)}
          value={assegnatoA}
          placeholder="Assegnato a"
          className="mt-[4px] select_input placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        >
          <option value="" disabled selected>
            Select Assegnato a
          </option>
          {employees != null && employees.map((option, index) => (
            <option key={index} value={option.id}>{option.name} {option.surname}</option>
          ))}
        </select>
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Tipo Cliente
        </label>
        <select
          value={tipoCliente}
          onChange={(event) => setTipoCliente(event.target.value)}
          placeholder="Assegnato a"
          className="mt-[4px] select_input placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        >
          <option>Option 1</option>
          <option>Option 2</option>
          <option>Option 3</option>
        </select>
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Customer record code 
        </label>
        <input
          readOnly
          value={infoData?.customerRecord}
          onKeyDown={(event) =>
            handleKeyDown(event, "ultimaInterazioneOperatori")
          }
          placeholder="Customer record code"
          className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        />
        <label className="text-primary text-[16px] leading-5 font-semibold">
          Creation date of the contact
        </label>
        <input
          readOnly
          value={infoData?.createdDate ? new Date(infoData?.createdDate).toLocaleDateString('en-US') : null}
          onKeyDown={(event) =>
            handleKeyDown(event, "ultimaInterazioneOperatori")
          }
          placeholder="Creation date of the contact"
          className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        />
         <label className="text-primary text-[16px] leading-5 font-semibold">
           Fiscal Code
        </label>
        <input
          readOnly
          value={info?.fiscalCode}
          onKeyDown={(event) =>
            handleKeyDown(event, "ultimaInterazioneOperatori")
          }
          placeholder="Fiscal Code"
          className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[20px] font-semibold px-[16px]"
        />

      </div>
      <div
        className={` ${expandForm ? "mt-[30px]" : "mt-[5px]"
          } border-[1.3px] border-primary w-full`}
      />
    </div>
  );
};

export default AboutContactForm;
