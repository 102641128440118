export const initialState = [];


const ContactsAction = {
    FetchContact: "FetchContact",
}

export function FetchContact(response) {
    return {
        type: ContactsAction.FetchContact,
        payload: {
            value: response,
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case ContactsAction.FetchContact:
            const newState = action.payload.value; //contactNegotiationsRS
            return  newState
        default:
            throw new Error('Error while Get Contact Request: Contact.reducer');    
    }
}

const ContactReducer = { initialState, reducer }

export default ContactReducer;