import { createContext, useContext, useEffect, useReducer } from "react";
import authenticationReducer from "../reducers/AuthenticationReducer";

export const AuthenticationContext = createContext({
  state: { ...authenticationReducer.initialState },
  dispatch: () => {},
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

function AuthenticationSlicer({ children }) {
  const [state, dispatch] = useReducer(
    authenticationReducer.reducer,
    authenticationReducer.initialState,
    (initial) => {
      const storedUserData = localStorage.getItem("userData");
      return storedUserData
        ? { ...initial, userData: JSON.parse(storedUserData) }
        : initial;
    }
  );

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationSlicer;
