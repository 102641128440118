import axiosConfig from "../axiosConfig";
class LandingPageService {
  fetchAllLandingPages = async () => {
    return await axiosConfig.get(`/LandingPage/getAll`);
  };

  fetchLandingPageById = async (landingPageId) => {
    if (landingPageId) {
      return await axiosConfig.get(`/LandingPage/get/${landingPageId}`);
    }
    console.warn(
      "landingPageId not specified, LandingPageService.fetchGetLandingPageById"
    );

    return null;
  };

  fetchIsUrlAlreadyExist = async (landingPageUrl) => {
    if (landingPageUrl) {
      return await axiosConfig.get(
        `/LandingPage/isUrlAlreadyExist/${landingPageUrl}`
      );
    }
    console.warn(
      "landingPageId not specified, LandingPageService.fetchIsUrlAlreadyExist"
    );

    return null;
  };

  createLandingPage = async (body, file) => {
    if (body) {
      const formData = new FormData();

      const appendFormData = (prefix, obj) => {
        Object.keys(obj).forEach((key) => {
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
              appendFormData(`${newKey}[${index}]`, item);
            });
          } else {
            formData.append(newKey, obj[key]);
          }
        });
      };

      appendFormData("", body);

      // attach file
      if (file) {
        formData.append("bannerImageFile", file);
      }

      // Make the request with FormData
      return await axiosConfig.post(`/LandingPage/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    console.warn("body not specified, LandingPageService.createLandingPage");

    return null;
  };

  updateLandingPage = async (body, file) => {
    if (body) {
      const formData = new FormData();

      const appendFormData = (prefix, obj) => {
        Object.keys(obj).forEach((key) => {
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
              appendFormData(`${newKey}[${index}]`, item);
            });
          } else {
            formData.append(newKey, obj[key]);
          }
        });
      };

      appendFormData("", body);

      // attach file
      if (file) {
        formData.append("bannerImageFile", file);
      }

      // Make the request with FormData
      return await axiosConfig.post(`/LandingPage/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    console.warn("body not specified, LandingPageService.updateLandingPage");

    return null;
  };

  deleteLandingPageById = async (landingPageId) => {
    if (landingPageId) {
      return await axiosConfig.delete(`/LandingPage/delete/${landingPageId}`);
    }
    console.warn(
      "landingPageId not specified, LandingPageService.deleteLandingPageById"
    );

    return null;
  };

  enableDisableLandingPageById = async (landingPageId, isEnabled) => {
    if (landingPageId) {
      return await axiosConfig.get(
        `/LandingPage/enableDisable/${landingPageId}/${isEnabled}`
      );
    }
    console.warn(
      "landingPageId not specified, LandingPageService.deleteLandingPageById"
    );

    return null;
  };
}

const instance = new LandingPageService();

export default instance;
