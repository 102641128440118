import React, { useState } from "react";
import { Box, Modal, Button, Grid } from "@mui/material";
// Import statements for i1 to i235 icons
import i1 from "../../assets/icon/i1.svg";
import i2 from "../../assets/icon/i2.svg";
import i3 from "../../assets/icon/i3.svg";
import i4 from "../../assets/icon/i4.svg";
import i5 from "../../assets/icon/i5.svg";
import i6 from "../../assets/icon/i6.svg";
import i7 from "../../assets/icon/i7.svg";
import i8 from "../../assets/icon/i8.svg";
import i9 from "../../assets/icon/i9.svg";
import i10 from "../../assets/icon/i10.svg";
import i11 from "../../assets/icon/i11.svg";
import i12 from "../../assets/icon/i12.svg";
import i13 from "../../assets/icon/i13.svg";
import i14 from "../../assets/icon/i14.svg";
import i15 from "../../assets/icon/i15.svg";
import i16 from "../../assets/icon/i16.svg";
import i17 from "../../assets/icon/i17.svg";
import i18 from "../../assets/icon/i18.svg";
import i19 from "../../assets/icon/i19.svg";
import i20 from "../../assets/icon/i20.svg";
import i21 from "../../assets/icon/i21.svg";
import i22 from "../../assets/icon/i22.svg";
import i23 from "../../assets/icon/i23.svg";
import i24 from "../../assets/icon/i24.svg";
import i25 from "../../assets/icon/i25.svg";
import i26 from "../../assets/icon/i26.svg";
import i27 from "../../assets/icon/i27.svg";
import i28 from "../../assets/icon/i28.svg";
import i29 from "../../assets/icon/i29.svg";
import i30 from "../../assets/icon/i30.svg";
import i31 from "../../assets/icon/i31.svg";
import i32 from "../../assets/icon/i32.svg";
import i33 from "../../assets/icon/i33.svg";
import i34 from "../../assets/icon/i34.svg";
import i35 from "../../assets/icon/i35.svg";
import i36 from "../../assets/icon/i36.svg";
import i37 from "../../assets/icon/i37.svg";
import i38 from "../../assets/icon/i38.svg";
import i39 from "../../assets/icon/i39.svg";
import i40 from "../../assets/icon/i40.svg";
import i41 from "../../assets/icon/i41.svg";
import i42 from "../../assets/icon/i42.svg";
import i43 from "../../assets/icon/i43.svg";
import i44 from "../../assets/icon/i44.svg";
import i45 from "../../assets/icon/i45.svg";
import i46 from "../../assets/icon/i46.svg";
import i47 from "../../assets/icon/i47.svg";
import i48 from "../../assets/icon/i48.svg";
import i49 from "../../assets/icon/i49.svg";
import i50 from "../../assets/icon/i50.svg";
import i51 from "../../assets/icon/i51.svg";
import i52 from "../../assets/icon/i52.svg";
import i53 from "../../assets/icon/i53.svg";
import i54 from "../../assets/icon/i54.svg";
import i55 from "../../assets/icon/i55.svg";
import i56 from "../../assets/icon/i56.svg";
import i57 from "../../assets/icon/i57.svg";
import i58 from "../../assets/icon/i58.svg";
import i59 from "../../assets/icon/i59.svg";
import i60 from "../../assets/icon/i60.svg";
import i61 from "../../assets/icon/i61.svg";
import i62 from "../../assets/icon/i62.svg";
import i63 from "../../assets/icon/i63.svg";
import i64 from "../../assets/icon/i64.svg";
import i65 from "../../assets/icon/i65.svg";
import i66 from "../../assets/icon/i66.svg";
import i67 from "../../assets/icon/i67.svg";
import i68 from "../../assets/icon/i68.svg";
import i69 from "../../assets/icon/i69.svg";
import i70 from "../../assets/icon/i70.svg";
import i71 from "../../assets/icon/i71.svg";
import i72 from "../../assets/icon/i72.svg";
import i73 from "../../assets/icon/i73.svg";
import i74 from "../../assets/icon/i74.svg";
import i75 from "../../assets/icon/i75.svg";
import i76 from "../../assets/icon/i76.svg";
import i77 from "../../assets/icon/i77.svg";
import i78 from "../../assets/icon/i78.svg";
import i79 from "../../assets/icon/i79.svg";
import i80 from "../../assets/icon/i80.svg";
import i81 from "../../assets/icon/i81.svg";
import i82 from "../../assets/icon/i82.svg";
import i83 from "../../assets/icon/i83.svg";
import i84 from "../../assets/icon/i84.svg";
import i85 from "../../assets/icon/i85.svg";
import i86 from "../../assets/icon/i86.svg";
import i87 from "../../assets/icon/i87.svg";
import i88 from "../../assets/icon/i88.svg";
import i89 from "../../assets/icon/i89.svg";
import i90 from "../../assets/icon/i90.svg";
import i91 from "../../assets/icon/i91.svg";
import i92 from "../../assets/icon/i92.svg";
import i93 from "../../assets/icon/i93.svg";
import i94 from "../../assets/icon/i94.svg";
import i95 from "../../assets/icon/i95.svg";
import i96 from "../../assets/icon/i96.svg";
import i97 from "../../assets/icon/i97.svg";
import i98 from "../../assets/icon/i98.svg";
import i99 from "../../assets/icon/i99.svg";
import i100 from "../../assets/icon/i100.svg";
import i101 from "../../assets/icon/i101.svg";
import i102 from "../../assets/icon/i102.svg";
import i103 from "../../assets/icon/i103.svg";
import i104 from "../../assets/icon/i104.svg";
import i105 from "../../assets/icon/i105.svg";
import i106 from "../../assets/icon/i106.svg";
import i107 from "../../assets/icon/i107.svg";
import i108 from "../../assets/icon/i108.svg";
import i109 from "../../assets/icon/i109.svg";
import i110 from "../../assets/icon/i110.svg";
import i111 from "../../assets/icon/i111.svg";
import i112 from "../../assets/icon/i112.svg";
import i113 from "../../assets/icon/i113.svg";
import i114 from "../../assets/icon/i114.svg";
import i115 from "../../assets/icon/i115.svg";
import i116 from "../../assets/icon/i116.svg";
import i117 from "../../assets/icon/i117.svg";
import i118 from "../../assets/icon/i118.svg";
import i119 from "../../assets/icon/i119.svg";
import i120 from "../../assets/icon/i120.svg";
import i121 from "../../assets/icon/i121.svg";
import i122 from "../../assets/icon/i122.svg";
import i123 from "../../assets/icon/i123.svg";
import i124 from "../../assets/icon/i124.svg";
import i125 from "../../assets/icon/i125.svg";
import i126 from "../../assets/icon/i126.svg";
import i127 from "../../assets/icon/i127.svg";
import i128 from "../../assets/icon/i128.svg";
import i129 from "../../assets/icon/i129.svg";
import i130 from "../../assets/icon/i130.svg";
import i131 from "../../assets/icon/i131.svg";
import i132 from "../../assets/icon/i132.svg";
import i133 from "../../assets/icon/i133.svg";
import i134 from "../../assets/icon/i134.svg";
import i135 from "../../assets/icon/i135.svg";
import i136 from "../../assets/icon/i136.svg";
import i137 from "../../assets/icon/i137.svg";
import i138 from "../../assets/icon/i138.svg";
import i139 from "../../assets/icon/i139.svg";
import i140 from "../../assets/icon/i140.svg";
import i141 from "../../assets/icon/i141.svg";
import i142 from "../../assets/icon/i142.svg";
import i143 from "../../assets/icon/i143.svg";
import i144 from "../../assets/icon/i144.svg";
import i145 from "../../assets/icon/i145.svg";
import i146 from "../../assets/icon/i146.svg";
import i147 from "../../assets/icon/i147.svg";
import i148 from "../../assets/icon/i148.svg";
import i149 from "../../assets/icon/i149.svg";
import i150 from "../../assets/icon/i150.svg";
import i151 from "../../assets/icon/i151.svg";
import i152 from "../../assets/icon/i152.svg";
import i153 from "../../assets/icon/i153.svg";
import i154 from "../../assets/icon/i154.svg";
import i155 from "../../assets/icon/i155.svg";
import i156 from "../../assets/icon/i156.svg";
import i157 from "../../assets/icon/i157.svg";
import i158 from "../../assets/icon/i158.svg";
import i159 from "../../assets/icon/i159.svg";
import i160 from "../../assets/icon/i160.svg";
import i161 from "../../assets/icon/i161.svg";
import i162 from "../../assets/icon/i162.svg";
import i163 from "../../assets/icon/i163.svg";
import i164 from "../../assets/icon/i164.svg";
import i165 from "../../assets/icon/i165.svg";
import i166 from "../../assets/icon/i166.svg";
import i167 from "../../assets/icon/i167.svg";
import i168 from "../../assets/icon/i168.svg";
import i169 from "../../assets/icon/i169.svg";
import i170 from "../../assets/icon/i170.svg";
import i171 from "../../assets/icon/i171.svg";
import i172 from "../../assets/icon/i172.svg";
import i173 from "../../assets/icon/i173.svg";
import i174 from "../../assets/icon/i174.svg";
import i175 from "../../assets/icon/i175.svg";
import i176 from "../../assets/icon/i176.svg";
import i177 from "../../assets/icon/i177.svg";
import i178 from "../../assets/icon/i178.svg";
import i179 from "../../assets/icon/i179.svg";
import i180 from "../../assets/icon/i180.svg";
import i181 from "../../assets/icon/i181.svg";
import i182 from "../../assets/icon/i182.svg";
import i183 from "../../assets/icon/i183.svg";
import i184 from "../../assets/icon/i184.svg";
import i185 from "../../assets/icon/i185.svg";
import i186 from "../../assets/icon/i186.svg";
import i187 from "../../assets/icon/i187.svg";
import i188 from "../../assets/icon/i188.svg";
import i189 from "../../assets/icon/i189.svg";
import i190 from "../../assets/icon/i190.svg";
import i191 from "../../assets/icon/i191.svg";
import i192 from "../../assets/icon/i192.svg";
import i193 from "../../assets/icon/i193.svg";
import i194 from "../../assets/icon/i194.svg";
import i195 from "../../assets/icon/i195.svg";
import i196 from "../../assets/icon/i196.svg";
import i197 from "../../assets/icon/i197.svg";
import i198 from "../../assets/icon/i198.svg";
import i199 from "../../assets/icon/i199.svg";
import i200 from "../../assets/icon/i200.svg";
import i201 from "../../assets/icon/i201.svg";
import i202 from "../../assets/icon/i202.svg";
import i203 from "../../assets/icon/i203.svg";
import i204 from "../../assets/icon/i204.svg";
import i205 from "../../assets/icon/i205.svg";
import i206 from "../../assets/icon/i206.svg";
import i207 from "../../assets/icon/i207.svg";
import i208 from "../../assets/icon/i208.svg";
import i209 from "../../assets/icon/i209.svg";
import i210 from "../../assets/icon/i210.svg";
import i211 from "../../assets/icon/i211.svg";
import i212 from "../../assets/icon/i212.svg";
import i213 from "../../assets/icon/i213.svg";
import i214 from "../../assets/icon/i214.svg";
import i215 from "../../assets/icon/i215.svg";
import i216 from "../../assets/icon/i216.svg";
import i217 from "../../assets/icon/i217.svg";
import i218 from "../../assets/icon/i218.svg";
import i219 from "../../assets/icon/i219.svg";
import i220 from "../../assets/icon/i220.svg";
import i221 from "../../assets/icon/i221.svg";
import i222 from "../../assets/icon/i222.svg";
import i223 from "../../assets/icon/i223.svg";
import i224 from "../../assets/icon/i224.svg";
import i225 from "../../assets/icon/i225.svg";
import i226 from "../../assets/icon/i226.svg";
import i227 from "../../assets/icon/i227.svg";
import i228 from "../../assets/icon/i228.svg";
import i229 from "../../assets/icon/i229.svg";
import i230 from "../../assets/icon/i230.svg";
import i231 from "../../assets/icon/i231.svg";
import i232 from "../../assets/icon/i232.svg";
import i233 from "../../assets/icon/i233.svg";
import i234 from "../../assets/icon/i234.svg";
import i235 from "../../assets/icon/i235.svg";


export default function IconModal({
  isOpen,
  onClose,
  color,
  selectedEmojis,
  setSelectedEmojis,
}) {
  const icons = [
    { iconSrc: i1, value: "i1.svg" },
    { iconSrc: i2, value: "i2.svg" },
    { iconSrc: i3, value: "i3.svg" },
    { iconSrc: i4, value: "i4.svg" },
    { iconSrc: i5, value: "i5.svg" },
    { iconSrc: i6, value: "i6.svg" },
    { iconSrc: i7, value: "i7.svg" },
    { iconSrc: i8, value: "i8.svg" },
    { iconSrc: i9, value: "i9.svg" },
    { iconSrc: i10, value: "i10.svg" },
    { iconSrc: i11, value: "i11.svg" },
    { iconSrc: i12, value: "i12.svg" },
    { iconSrc: i13, value: "i13.svg" },
    { iconSrc: i14, value: "i14.svg" },
    { iconSrc: i15, value: "i15.svg" },
    { iconSrc: i16, value: "i16.svg" },
    { iconSrc: i17, value: "i17.svg" },
    { iconSrc: i18, value: "i18.svg" },
    { iconSrc: i19, value: "i19.svg" },
    { iconSrc: i20, value: "i20.svg" },
    { iconSrc: i21, value: "i21.svg" },
    { iconSrc: i22, value: "i22.svg" },
    { iconSrc: i23, value: "i23.svg" },
    { iconSrc: i24, value: "i24.svg" },
    { iconSrc: i25, value: "i25.svg" },
    { iconSrc: i26, value: "i26.svg" },
    { iconSrc: i27, value: "i27.svg" },
    { iconSrc: i28, value: "i28.svg" },
    { iconSrc: i29, value: "i29.svg" },
    { iconSrc: i30, value: "i30.svg" },
    { iconSrc: i31, value: "i31.svg" },
    { iconSrc: i32, value: "i32.svg" },
    { iconSrc: i33, value: "i33.svg" },
    { iconSrc: i34, value: "i34.svg" },
    { iconSrc: i35, value: "i35.svg" },
    { iconSrc: i36, value: "i36.svg" },
    { iconSrc: i37, value: "i37.svg" },
    { iconSrc: i38, value: "i38.svg" },
    { iconSrc: i39, value: "i39.svg" },
    { iconSrc: i40, value: "i40.svg" },
    { iconSrc: i41, value: "i41.svg" },
    { iconSrc: i42, value: "i42.svg" },
    { iconSrc: i43, value: "i43.svg" },
    { iconSrc: i44, value: "i44.svg" },
    { iconSrc: i45, value: "i45.svg" },
    { iconSrc: i46, value: "i46.svg" },
    { iconSrc: i47, value: "i47.svg" },
    { iconSrc: i48, value: "i48.svg" },
    { iconSrc: i49, value: "i49.svg" },
    { iconSrc: i50, value: "i50.svg" },
    { iconSrc: i51, value: "i51.svg" },
    { iconSrc: i52, value: "i52.svg" },
    { iconSrc: i53, value: "i53.svg" },
    { iconSrc: i54, value: "i54.svg" },
    { iconSrc: i55, value: "i55.svg" },
    { iconSrc: i56, value: "i56.svg" },
    { iconSrc: i57, value: "i57.svg" },
    { iconSrc: i58, value: "i58.svg" },
    { iconSrc: i59, value: "i59.svg" },
    { iconSrc: i60, value: "i60.svg" },
    { iconSrc: i61, value: "i61.svg" },
    { iconSrc: i62, value: "i62.svg" },
    { iconSrc: i63, value: "i63.svg" },
    { iconSrc: i64, value: "i64.svg" },
    { iconSrc: i65, value: "i65.svg" },
    { iconSrc: i66, value: "i66.svg" },
    { iconSrc: i67, value: "i67.svg" },
    { iconSrc: i68, value: "i68.svg" },
    { iconSrc: i69, value: "i69.svg" },
    { iconSrc: i70, value: "i70.svg" },
    { iconSrc: i71, value: "i71.svg" },
    { iconSrc: i72, value: "i72.svg" },
    { iconSrc: i73, value: "i73.svg" },
    { iconSrc: i74, value: "i74.svg" },
    { iconSrc: i75, value: "i75.svg" },
    { iconSrc: i76, value: "i76.svg" },
    { iconSrc: i77, value: "i77.svg" },
    { iconSrc: i78, value: "i78.svg" },
    { iconSrc: i79, value: "i79.svg" },
    { iconSrc: i80, value: "i80.svg" },
    { iconSrc: i81, value: "i81.svg" },
    { iconSrc: i82, value: "i82.svg" },
    { iconSrc: i83, value: "i83.svg" },
    { iconSrc: i84, value: "i84.svg" },
    { iconSrc: i85, value: "i85.svg" },
    { iconSrc: i86, value: "i86.svg" },
    { iconSrc: i87, value: "i87.svg" },
    { iconSrc: i88, value: "i88.svg" },
    { iconSrc: i89, value: "i89.svg" },
    { iconSrc: i90, value: "i90.svg" },
    { iconSrc: i91, value: "i91.svg" },
    { iconSrc: i92, value: "i92.svg" },
    { iconSrc: i93, value: "i93.svg" },
    { iconSrc: i94, value: "i94.svg" },
    { iconSrc: i95, value: "i95.svg" },
    { iconSrc: i96, value: "i96.svg" },
    { iconSrc: i97, value: "i97.svg" },
    { iconSrc: i98, value: "i98.svg" },
    { iconSrc: i99, value: "i99.svg" },
    { iconSrc: i100, value: "i100.svg" },
    { iconSrc: i101, value: "i101.svg" },
    { iconSrc: i102, value: "i102.svg" },
    { iconSrc: i103, value: "i103.svg" },
    { iconSrc: i104, value: "i104.svg" },
    { iconSrc: i105, value: "i105.svg" },
    { iconSrc: i106, value: "i106.svg" },
    { iconSrc: i107, value: "i107.svg" },
    { iconSrc: i108, value: "i108.svg" },
    { iconSrc: i109, value: "i109.svg" },
    { iconSrc: i110, value: "i110.svg" },
    { iconSrc: i111, value: "i111.svg" },
    { iconSrc: i112, value: "i112.svg" },
    { iconSrc: i113, value: "i113.svg" },
    { iconSrc: i114, value: "i114.svg" },
    { iconSrc: i115, value: "i115.svg" },
    { iconSrc: i116, value: "i116.svg" },
    { iconSrc: i117, value: "i117.svg" },
    { iconSrc: i118, value: "i118.svg" },
    { iconSrc: i119, value: "i119.svg" },
    { iconSrc: i120, value: "i120.svg" },
    { iconSrc: i121, value: "i121.svg" },
    { iconSrc: i122, value: "i122.svg" },
    { iconSrc: i123, value: "i123.svg" },
    { iconSrc: i124, value: "i124.svg" },
    { iconSrc: i125, value: "i125.svg" },
    { iconSrc: i126, value: "i126.svg" },
    { iconSrc: i127, value: "i127.svg" },
    { iconSrc: i128, value: "i128.svg" },
    { iconSrc: i129, value: "i129.svg" },
    { iconSrc: i130, value: "i130.svg" },
    { iconSrc: i131, value: "i131.svg" },
    { iconSrc: i132, value: "i132.svg" },
    { iconSrc: i133, value: "i133.svg" },
    { iconSrc: i134, value: "i134.svg" },
    { iconSrc: i135, value: "i135.svg" },
    { iconSrc: i136, value: "i136.svg" },
    { iconSrc: i137, value: "i137.svg" },
    { iconSrc: i138, value: "i138.svg" },
    { iconSrc: i139, value: "i139.svg" },
    { iconSrc: i140, value: "i140.svg" },
    { iconSrc: i141, value: "i141.svg" },
    { iconSrc: i142, value: "i142.svg" },
    { iconSrc: i143, value: "i143.svg" },
    { iconSrc: i144, value: "i144.svg" },
    { iconSrc: i145, value: "i145.svg" },
    { iconSrc: i146, value: "i146.svg" },
    { iconSrc: i147, value: "i147.svg" },
    { iconSrc: i148, value: "i148.svg" },
    { iconSrc: i149, value: "i149.svg" },
    { iconSrc: i150, value: "i150.svg" },
    { iconSrc: i151, value: "i151.svg" },
    { iconSrc: i152, value: "i152.svg" },
    { iconSrc: i153, value: "i153.svg" },
    { iconSrc: i154, value: "i154.svg" },
    { iconSrc: i155, value: "i155.svg" },
    { iconSrc: i156, value: "i156.svg" },
    { iconSrc: i157, value: "i157.svg" },
    { iconSrc: i158, value: "i158.svg" },
    { iconSrc: i159, value: "i159.svg" },
    { iconSrc: i160, value: "i160.svg" },
    { iconSrc: i161, value: "i161.svg" },
    { iconSrc: i162, value: "i162.svg" },
    { iconSrc: i163, value: "i163.svg" },
    { iconSrc: i164, value: "i164.svg" },
    { iconSrc: i165, value: "i165.svg" },
    { iconSrc: i166, value: "i166.svg" },
    { iconSrc: i167, value: "i167.svg" },
    { iconSrc: i168, value: "i168.svg" },
    { iconSrc: i169, value: "i169.svg" },
    { iconSrc: i170, value: "i170.svg" },
    { iconSrc: i171, value: "i171.svg" },
    { iconSrc: i172, value: "i172.svg" },
    { iconSrc: i173, value: "i173.svg" },
    { iconSrc: i174, value: "i174.svg" },
    { iconSrc: i175, value: "i175.svg" },
    { iconSrc: i176, value: "i176.svg" },
    { iconSrc: i177, value: "i177.svg" },
    { iconSrc: i178, value: "i178.svg" },
    { iconSrc: i179, value: "i179.svg" },
    { iconSrc: i180, value: "i180.svg" },
    { iconSrc: i181, value: "i181.svg" },
    { iconSrc: i182, value: "i182.svg" },
    { iconSrc: i183, value: "i183.svg" },
    { iconSrc: i184, value: "i184.svg" },
    { iconSrc: i185, value: "i185.svg" },
    { iconSrc: i186, value: "i186.svg" },
    { iconSrc: i187, value: "i187.svg" },
    { iconSrc: i188, value: "i188.svg" },
    { iconSrc: i189, value: "i189.svg" },
    { iconSrc: i190, value: "i190.svg" },
    { iconSrc: i191, value: "i191.svg" },
    { iconSrc: i192, value: "i192.svg" },
    { iconSrc: i193, value: "i193.svg" },
    { iconSrc: i194, value: "i194.svg" },
    { iconSrc: i195, value: "i195.svg" },
    { iconSrc: i196, value: "i196.svg" },
    { iconSrc: i197, value: "i197.svg" },
    { iconSrc: i198, value: "i198.svg" },
    { iconSrc: i199, value: "i199.svg" },
    { iconSrc: i200, value: "i200.svg" },
    { iconSrc: i201, value: "i201.svg" },
    { iconSrc: i202, value: "i202.svg" },
    { iconSrc: i203, value: "i203.svg" },
    { iconSrc: i204, value: "i204.svg" },
    { iconSrc: i205, value: "i205.svg" },
    { iconSrc: i206, value: "i206.svg" },
    { iconSrc: i207, value: "i207.svg" },
    { iconSrc: i208, value: "i208.svg" },
    { iconSrc: i209, value: "i209.svg" },
    { iconSrc: i210, value: "i210.svg" },
    { iconSrc: i211, value: "i211.svg" },
    { iconSrc: i212, value: "i212.svg" },
    { iconSrc: i213, value: "i213.svg" },
    { iconSrc: i214, value: "i214.svg" },
    { iconSrc: i215, value: "i215.svg" },
    { iconSrc: i216, value: "i216.svg" },
    { iconSrc: i217, value: "i217.svg" },
    { iconSrc: i218, value: "i218.svg" },
    { iconSrc: i219, value: "i219.svg" },
    { iconSrc: i220, value: "i220.svg" },
    { iconSrc: i221, value: "i221.svg" },
    { iconSrc: i222, value: "i222.svg" },
    { iconSrc: i223, value: "i223.svg" },
    { iconSrc: i224, value: "i224.svg" },
    { iconSrc: i225, value: "i225.svg" },
    { iconSrc: i226, value: "i226.svg" },
    { iconSrc: i227, value: "i227.svg" },
    { iconSrc: i228, value: "i228.svg" },
    { iconSrc: i229, value: "i229.svg" },
    { iconSrc: i230, value: "i230.svg" },
    { iconSrc: i231, value: "i231.svg" },
    { iconSrc: i232, value: "i232.svg" },
    { iconSrc: i233, value: "i233.svg" },
    { iconSrc: i234, value: "i234.svg" },
    { iconSrc: i235, value: "i235.svg" }
];


  const toggleEmojiSelection = (emoji) => {
    if (selectedEmojis === emoji) {
      color ? setSelectedEmojis(color, "") : setSelectedEmojis("");
    } else {
      color ? setSelectedEmojis(color, emoji) : setSelectedEmojis(emoji);
    }
  };

  const handleDoneClick = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        padding: "20px",
        borderRadius: "10px",
        minWidth: "900px",
        minHeight: "500px",
        maxHeight: "80vh", // Adjusted to make modal scrollable with max height
        overflowY: "auto", // Enable vertical scrolling if content exceeds maxHeight
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky", // Ensures it stays fixed at top
          top: 0, // Fixes it at the top of the modal
          backgroundColor: "background.paper", // Matches modal background
          zIndex: 1, // Ensures it's above scrolling content
          padding: "10px 20px", // Adjust padding for spacing
          borderBottom: "1px solid #ccc", // Bottom border for separation
          borderRadius: "10px 10px 0 0", // Rounds top corners
        }}
      >
        <h2>Select Icon</h2>
        <Box onClick={onClose} sx={{ cursor: "pointer" }}>
          <h2>X</h2>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: "calc(80vh - 70px)", // Adjusted to leave space for top bar
          overflowY: "auto", // Enable scrolling only for icon section
          padding: "10px", // Padding for inner content
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "10px",
            width: "100%",
          }}
        >
          <Grid container spacing={1}>
            {icons.map((icon, index) => (
              <Grid item xs={12} md={2} alignItems={"center"} key={index}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    border:
                      icon.value === selectedEmojis ? "1px solid black" : "none",
                    padding: "5px",
                  }}
                  onClick={() => toggleEmojiSelection(icon.value)}
                >
                  <img
                    src={icon.iconSrc}
                    alt={`Icon ${icon.value}`}
                    height={icon.value === selectedEmojis ? "70px" : "50px"}
                    width={icon.value === selectedEmojis ? "70px" : "50px"}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
      sx={{
        position: "sticky", // Ensures it stays fixed at the bottom
        bottom: "20px", // Distance from the bottom of the modal
        display: "flex",
        justifyContent: "center",
        width: "100%",
        zIndex: 1, // Ensures it's above scrolling content
        padding: "10px 20px"
      }}
    >
      <Button variant="contained" onClick={handleDoneClick}>
        Done
      </Button>
    </Box>
    </Box>
  </Modal>
  

  );
}
