import {
  Box,
  Divider,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function TouristicDestinationInfo({
  propName,
  propStartingPrice,
  propOrder,
  propPackageType,
  propExternalRedirect,
  propImages,
  propImageEditCase,
  sendValueToParent,
}) {
  const [name, setName] = useState(null);
  const [startingPrice, setStartingPrice] = useState(null);
  const [order, setOrder] = useState(null);
  const [packageType, setPackageType] = useState(null);
  const [externalRedirect, setexternalRedirect] =useState(null);
  const [plainQuery, setPlainQuery] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedImageFileChanged, setSelectedImageFileChanged] =
    useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [nameError, setNameError] = useState(false);
  const [startingPriceError, setStartingPriceError] = useState(false);
  const [packageTypeError, setPackageTypeError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [externalRedirectError, setExternalRedirectError] =
    useState(false);
  const [plainQueryError, setPlainQueryError] = useState(false);

  const inputRef = useRef(null);

  /**
   * Side effects
   */
  useEffect(() => {
    setName(propName);
    setStartingPrice(propStartingPrice);
    setSelectedImage(propImages.length > 0 ? propImages[0] : null)
    setOrder(propOrder === 0 ? null : propOrder);
    let packageTypeSelect = 
    propPackageType === "1"
        ? "ExternalRedirect"
        : propPackageType === "2"
        ? "PlainQuery"
        : propPackageType != null && propPackageType != "" ? "LandingPage" : null;
    setPackageType(
      packageTypeSelect === "ExternalRedirect"
        ? "1"
        : packageTypeSelect === "PlainQuery"
        ? "2"
        : packageTypeSelect === "LandingPage"
        ? "3"
        : null
    );
    setexternalRedirect(
      propPackageType === "1"
        ? propExternalRedirect
        : null
    );
    setPlainQuery(
      propPackageType === "2"
        ? propExternalRedirect
        : null
    );

    // setSelectedOption(
    //   propPackageType === "ExternalRedirect"
    //     ? "External Redirect"
    //     : propPackageType === "PlainQuery"
    //     ? "Plain Query"
    //     : propPackageType === "LandingPage"
    //     ? "Landing Page"
    //     : ""
    // );

    setSelectedOption(
      propPackageType === "1"
        ? "External Redirect"
        : propPackageType === "2"
        ? "Plain Query"
        : propPackageType === "3"
        ? "Landing Page"
        : null
    );

    setSelectedImageFile(
      propImageEditCase == undefined
        ? propImages != null || propImages != ""
          ? propImages[0]
          : null
        : propImageEditCase
    );
    // setSelectedImage(
    //   selectedImageFile != null ? URL.createObjectURL(selectedImageFile) : null
    // );
  }, [
    propName,
    propStartingPrice,
    propOrder,
    propPackageType,
    propExternalRedirect,
    propImages,
    propImageEditCase,
  ]);

  /**
   * handler functions
   */
  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.name);
    setPackageType(
      event.target.name === "External Redirect"
        ? "1"
        : event.target.name === "Plain Query"
        ? "2"
        : event.target.name === "Landing Page"
        ? "3"
        : 0
    );
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    setSelectedImageFile(file);
    setSelectedImageFileChanged(true);
  };

  const handleImageClick = () => {
    if (selectedImage) {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };

  const handleNext = () => {
    // check validation
    let error = false;
    if (name === "" || name === null) {
      setNameError(true);
      error = true;
    }

    if (packageType === 0 || packageType === null) {
      setPackageTypeError(true);
      error = true;
    }

    if (startingPrice === 0 || startingPrice === null || startingPrice === "") {
      setStartingPriceError(true);
      error = true;
    }

    if (
      packageType === 1 &&
      (externalRedirect === null ||
        externalRedirectError === "")
    ) {
      setexternalRedirect(true);
      error = true;
    }

    if (
      packageType === 2 &&
      (plainQuery === null || plainQuery === "")
    ) {
      setPlainQueryError(true);
      error = true;
    }

    if (selectedImageFile === null && selectedImageFile === "") {
      setImageError(true);
      error = true;
    }
    // call back
    if (!error) {
      let redirectUrl =
      packageType === "1" || packageType === 1
          ? externalRedirect
          : packageType === "2" || packageType === 2
          ? plainQuery
          : "";

      sendValueToParent(
        name,
        startingPrice,
        order,
        packageType,
        redirectUrl,
        selectedImageFile,
        selectedImageFileChanged
      );
    }
  };

  return (
    <Box
      sx={{
        marginLeft: "34px",
        width: "1003px",
        minHeight: "587px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Ideal Vacation Name <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            Enter the name of the event package that will be shown on the Home
            page
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
            borderColor: nameError ? "red" : undefined,
          }}
          variant="outlined"
          placeholder="Name"
          value={name}
          error={nameError}
          onChange={(e) => {
            setName(e.target.value);
            setNameError(e.target.value === "");
          }}
        />
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            Starting Price <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            From STARTINGPRICE per person
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
            borderColor: startingPriceError ? "red" : undefined,
          }}
          variant="outlined"
          placeholder="Starting Prices"
          value={startingPrice}
          error={startingPriceError}
          type="number"
          onChange={(e) => {
            setStartingPrice(e.target.value);
            setStartingPriceError(e.target.value === "");
          }}
        />
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "0px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Order
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingTop: "6px",
                marginLeft: "10px",
              }}
            >
              (optional)
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            If the order is left blank it will be checked by the system
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="the lower the number, the higher priority it is"
          value={order}
          onChange={(e) => {
            setOrder(e.target.value);
          }}
        />
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "0px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Destination Image <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            The image inserted here will be shown in the Home
          </Typography>
        </Box>
        {selectedImage ? (
          <img
            src={selectedImage}
            alt="Selected Image"
            style={{
              width: "564px",
              height: "132px",
              cursor: "pointer",
              borderRadius: "10px",
            }}
            onClick={handleImageClick}
          />
        ) : (
          <button
            style={{
              width: "564px",
              height: "132px",
              border: "1px dashed #26529C",
              borderRadius: "10px",
              textDecoration: "underline",
              fontSize: "32px",
              fontWeight: "600",
              cursor: "pointer",
              color: "#26529C",
              backgroundColor: "white",
            }}
            onClick={() => inputRef.current.click()}
          >
            <label
              htmlFor="image-upload"
              style={{
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <Typography sx={{ fontSize: "24px", fontWeight: "400" }}>
                Upload Image
              </Typography>
            </label>
          </button>
        )}
        <input
          ref={inputRef}
          id="image-upload"
          type="file"
          accept="image/*"
          multiple
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </Box>

      <Divider sx={{ marginTop: "68px" }} />

      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Package’s Type <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            Select what happens when the customer click in the touristic
            destination in the home
          </Typography>
        </Box>
        {/* //here */}
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              marginRight: "10px",
              width: "180px",
              height: "48px",
              border: "1px solid #E7E7E7",
              paddingLeft: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOption === "External Redirect"}
                  onChange={handleCheckboxChange}
                  name="External Redirect"
                />
              }
              label={
                <Typography sx={{ fontSize: "15px" }}>
                  External Redirect
                </Typography>
              }
            />
          </Box>
          <Box
            sx={{
              marginRight: "10px",
              width: "180px",
              height: "48px",
              border: "1px solid #E7E7E7",
              paddingLeft: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOption === "Plain Query"}
                  onChange={handleCheckboxChange}
                  name="Plain Query"
                />
              }
              label="Plain Query"
            />
          </Box>
          <Box
            sx={{
              width: "180px",
              height: "48px",
              border: "1px solid #E7E7E7",
              paddingLeft: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOption === "Landing Page"}
                  onChange={handleCheckboxChange}
                  name="Landing Page"
                />
              }
              label="Landing Page"
            />
          </Box>
        </Box>
      </Box>

      <Box>
        {selectedOption === "External Redirect" && (
          <Box
            sx={{
              marginTop: "19px",
              backgroundColor: "white",
              borderRadius: "10px",
              height: "105px",
              padding: "15px 20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  External Redirect <span style={{ color: "red" }}>*</span>
                </Typography>
              </Box>
              <Typography
                sx={{
                  width: "313px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#C7C7C7",
                }}
              >
                When the customer will click on the package the Url will be
                activated
              </Typography>
            </Box>
            <TextField
              sx={{
                marginTop: "10px",
                width: "564px",
                height: "48px",
                borderRadius: "5px",
                borderColor: externalRedirectError ? "red" : undefined,
              }}
              variant="outlined"
              placeholder="https://url.com"
              value={externalRedirect}
              error={externalRedirectError}
              onChange={(e) => {
                setexternalRedirect(e.target.value);
                setExternalRedirectError(e.target.value === "");
              }}
            />
          </Box>
        )}

        {selectedOption === "Plain Query" && (
          <Box
            sx={{
              marginTop: "19px",
              backgroundColor: "white",
              borderRadius: "10px",
              height: "105px",
              padding: "15px 20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
                  Plain Query <span style={{ color: "red" }}>*</span>
                </Typography>
              </Box>
              <Typography
                sx={{
                  width: "313px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#C7C7C7",
                }}
              >
                When the customer will click on the package the Url will be
                activated
              </Typography>
            </Box>
            <TextField
              sx={{
                marginTop: "10px",
                width: "564px",
                height: "48px",
                borderRadius: "5px",
              }}
              variant="outlined"
              placeholder="https://url.com"
              value={plainQuery}
              error={plainQueryError}
              onChange={(e) => {
                setPlainQuery(e.target.value);
                setPlainQueryError(e.target.value === "");
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <Button
          sx={{
            width: "186px",
            height: "44px",
            backgroundColor: "#26529C",
            color: "white",
            "&:hover": { backgroundColor: "#26529C" },
          }}
          onClick={handleNext}
        >
          {selectedOption === "External Redirect" ||
          selectedOption === "Plain Query"
            ? "save"
            : "Next"}
        </Button>
      </Box>
    </Box>
  );
}
