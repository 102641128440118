import { Box, Divider, TextField, Typography, Button } from "@mui/material";
import React from "react";
import upload from "../../assets/upload.svg";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import useLandingPage from "../../library/hooks/useLandingPage/useLandingPage";

export default function LandingPageInfo({
  setSidebarIndex,
  propPageUrl,
  propPageTitle,
  propH1Page,
  propH2Page,
  propPageMarkdown,
  propBannerImage,
  propBannerImageEditCase,
  sendValueToParent,
}) {
  /**
   * Api Context
   */

  //helper state
  const { callLandingPageUrlAlreadyExistApi } = useLandingPage();

  const [selectedImageFileChanged, setSelectedImageFileChanged] =
    useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(
    propBannerImageEditCase == null
      ? propBannerImage?.length > 0
        ? propBannerImage[0]
        : null
      : propBannerImageEditCase
  );
  const [selectedImage, setSelectedImage] = useState(
    selectedImageFile != null ? URL.createObjectURL(selectedImageFile) : null
  );
  const [editorState, setEditorState] = useState(
    propPageMarkdown
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(propPageMarkdown))
        )
      : ""
  );
  const [pageUrl, setPageUrl] = useState(propPageUrl);
  const [pageTitle, setPageTitle] = useState(propPageTitle);
  const [h1Page, setH1Page] = useState(propH1Page);
  const [h2Page, setH2Page] = useState(propH2Page);

  /**
   * for validation
   */
  const [pageUrlError, setPageUrlError] = useState(false);
  const [pageURLAlreadyExistError, setPageURLAlreadyExistError] =
    useState(false);
  const [pageTitleError, setPageTitleError] = useState(false);

  const handleImageChange = (event) => {
    const files = event.target.files;
    // Check if files are selected
    if (files.length > 0) {
      const file = files[0];
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setSelectedImageFile(file);
      setSelectedImageFileChanged(true);
    } else {
      // If no files are selected, remove the selected image
      setSelectedImage(null);
      setSelectedImageFile(null);
      setSelectedImageFileChanged(false);
    }
  };

  const handlePageUrlBlur = async () => {
    if (pageUrl == "") return;

    await callLandingPageUrlAlreadyExistApi(pageUrl)
      .then((response) => {
        if (response && response?.isUrlExist == true) {
          setPageUrlError(true);
          setPageURLAlreadyExistError(true);
        } else {
          setPageURLAlreadyExistError(false);
        }
      })
      .catch((err) => {
        console.error("Error: callLandingPageUrlAlreadyExistApi!", err);
      });
  };

  const handleNext = () => {
    // Validate pageUrl and pageTitle
    if (pageUrl === "") {
      setPageUrlError(true);
    }
    if (pageTitle === "") {
      setPageTitleError(true);
    }

    if (pageUrl !== "" && pageTitle !== "" && !pageURLAlreadyExistError) {
      setSidebarIndex(2);

      let description_html = "";
      let contentState;

      if (editorState && editorState !== "") {
        contentState = editorState?.getCurrentContent();
        description_html = contentState ? convertToHTML({})(contentState) : "";
      }
      //call back
      sendValueToParent(
        pageUrl,
        pageTitle,
        h1Page,
        h2Page,
        description_html,
        selectedImageFile,
        selectedImageFileChanged
      );
    }
  };

  return (
    <Box
      sx={{
        marginLeft: "34px",
        width: "1003px",
        minHeight: "815px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Create the page URL <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            The system will automatically check that it is not already present
          </Typography>
        </Box>
        <Box sx={{ display: "block" }}>
          <TextField
            sx={{
              marginTop: "10px",
              width: "564px",
              height: "48px",
              borderRadius: "5px",
              borderColor: pageUrlError ? "red" : undefined,
            }}
            variant="outlined"
            placeholder="https://domain.com/"
            value={pageUrl}
            error={pageUrlError}
            onBlur={handlePageUrlBlur}
            onChange={(e) => {
              setPageUrl(e.target.value);
              setPageUrlError(e.target.value === "");
            }}
          />

          {pageURLAlreadyExistError && (
            <p style={{ marginTop: "15px", color: "red" }}>
              please change Url, It is already taken.
            </p>
          )}
        </Box>
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          // marginTop: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Page title <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            It will be inserted in the head of the page, i.e. shown in the tab
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
            borderColor: pageTitleError ? "red" : undefined,
          }}
          variant="outlined"
          placeholder="Page Title"
          value={pageTitle}
          error={pageTitleError}
          onChange={(e) => {
            setPageTitle(e.target.value);
            setPageTitleError(e.target.value === "");
          }}
        />
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          // marginTop: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              h1 of the page
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the h1 text below the search bar on the landing page
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="h1 of the page"
          value={h1Page}
          onChange={(e) => setH1Page(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              h2 of the page
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the h2 text below the h1 on the landing page
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="h2 of the page"
          value={h2Page}
          onChange={(e) => setH2Page(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          minHeight: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Page markdown
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the text under the h2 on the landing page and is HTML
            markdown
          </Typography>
        </Box>
        <Box
          style={{
            margin: "0px 10px 10px 40px",
            width: "564px",
            border: "1px solid #e3e3e3",
            borderRadius: "5px",
            padding: "10px",
            minHeight: "316px",
          }}
        >
          <Editor
            placeholder="Markdown Text"
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={setEditorState}
          />
        </Box>
      </Box>

      <Divider sx={{ margin: "20px 0px" }} />

      <Box
        sx={{
          marginTop: "15px",
          backgroundColor: "white",
          borderRadius: "10px",
          // height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Banner Landing Page
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingTop: "6px",
                marginLeft: "10px",
              }}
            >
              (optional)
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            This is the text under the h2 on the landing page and is HTML
            markdown
          </Typography>
        </Box>
        {selectedImage ? (
          <div>
            <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
              <img
                src={selectedImage}
                alt="Selected"
                style={{
                  width: "564px",
                  height: "132px",
                  cursor: "pointer",
                }}
              />
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </label>
          </div>
        ) : (
          <div>
            <button
              style={{
                width: "564px",
                height: "132px",
                border: "1px dashed #26529C",
                borderRadius: "10px",
                fontSize: "32px",
                fontWeight: "600",
                cursor: "pointer",
                color: "#26529C",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => {
                const inputElement = document.getElementById("image-upload");
                inputElement.click();
              }}
            >
              <img
                src={upload}
                alt="upload button"
                style={{ marginTop: "15px" }}
              />
              <label
                htmlFor="image-upload"
                style={{
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: "400" }}>
                  Upload the file to .webp
                </Typography>
              </label>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </button>
          </div>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", padding: "20px" }}>
        <Button
          sx={{
            width: "186px",
            height: "44px",
            backgroundColor: "#26529C",
            color: "white",
            "&:hover": { backgroundColor: "#26529C" },
          }}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
