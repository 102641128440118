import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import profile from "../../assets/Profile.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock"; // Import the lock icon
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import { hasPermission, PERMISSIONS } from "utils/permisson";
import { routesConfig } from "utils/routesConfig";
import { Button,Avatar  } from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { Logout } from "library/store/reducers/AuthenticationReducer";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: "-${drawerWidth}px",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft({ children, handleToggle }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [userDataLocal,setUserDataLocal] = useState();

  const drawerRef = useRef(null);
  const { state: userState, dispatch } = useAuthenticationContext();

  const initials = userState.userData?.data?.name && userState.userData?.data?.surname ? `${userState.userData?.data?.name[0]}${userState.userData?.data?.surname[0]}` : '';
  const handleDrawerOpen = () => {
    handleToggle();
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleToggle();
  };

  const location = useLocation();
  useEffect(() => {
    if(open){
      const storedUserData = localStorage.getItem("userData");
      let userData = JSON.parse(storedUserData);
      setUserDataLocal(userData);
    }
    const handleOutsideClick = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        open
      ) {
        handleDrawerClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  const filteredRoutes = routesConfig.filter(
    (route) => route.showInSidebar !== false
  );

  const handleLogout = () => {
    dispatch(Logout());
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        // onClick={handleDrawerOpen}
        onMouseEnter={handleDrawerOpen}
        edge="start"
        sx={{
          color: "white",
          paddingTop: "50px",
          display: "flex",
          alignItems: "start",
          height: "100vh",
          ...(open && { display: "none" }),
        }}
      >
        <ChevronRightIcon />
      </IconButton>

      <Drawer
        ref={drawerRef}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            border: 0,
            backgroundColor: "#26529c",
            overflow: "hidden",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <List>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ marginLeft: "170px", color: "white" }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Box
            sx={{
              width: "72px",
              height: "72px",
              marginTop: "20px",
              marginLeft: "50px",
            }}
          >
            {userDataLocal?.data?.imageUrl ? (
            <img
            src={userDataLocal?.data?.imageUrl}
            alt="profile image"
          /> ) :
            ( <Avatar
              sx={{
                width: "72px",
                height: "72px",
                bgcolor: "#CBD329",
                fontSize: "24px",
              }}
            >
              {initials}
            </Avatar>
          )}
          </Box>
          <Box
            sx={{
              marginLeft: "50px",
              fontSize: "30px",
              fontWeight: "600",
              color: "white",
              marginTop: "8px",
            }}
          >
            {userState.userData?.data?.name}
          </Box>
          <Box
            sx={{
              fontSize: "17px",
              marginLeft: "50px",
              fontWeight: "400",
              color: "#FFFFFF",
              marginTop: "0px",
            }}
          >
            {userState.userData?.data?.email}
          </Box>
          <Box sx={{ marginTop: "30px" }}>
            {filteredRoutes.map((route) => (
              <ListItem
                disablePadding
                sx={{ marginLeft: "30px" }}
                key={route.path}
              >
                <ListItemButton
                  disabled={
                    !hasPermission(route.requiredPermissions, userState)
                  }
                  sx={{
                    color:
                      location.pathname === route.path ? "#FFFFFF" : "#92a8ce",
                    "&:hover": {
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={() => {
                    if (hasPermission(route.requiredPermissions, userState)) {
                      navigate(route.path);
                    }
                  }}
                >
                  {!hasPermission(route.requiredPermissions, userState) && (
                    <LockIcon sx={{ marginRight: 1 }} />
                  )}

                  <Typography sx={{ fontSize: "25px", fontWeight: 600 }}>
                    {route.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
        </List>
        <Box
          sx={{ marginTop: "auto", marginBottom: "16px", marginLeft: "30px" }}
        >
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                color: "#FFFFFF",
                "&:hover": {
                  color: "#92a8ce",
                },
              }}
            >
              <LogoutIcon sx={{ marginRight: 1 }} />
              <Typography sx={{ fontSize: "25px", fontWeight: 600 }}>
                Logout
              </Typography>
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box
          sx={{
            opacity: open ? "0.8" : "",
          }}
          onMouseEnter={open ? handleDrawerClose : undefined}
        >
          {children}
        </Box>
      </Main>
    </Box>
  );
}
