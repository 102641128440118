import AuthenticationSlicer from "./slices/AuthenticationSlicer";
import HotelConfigurationSlicer from "./slices/HotelConfigurationSlicer";
import HotelsDashboardSlicer from "./slices/HotelsDashboardSlicer";
import HotelsDashboardFilterSlicer from "./slices/HotelsDashboardFilterSlicer";
import HotelDetailsConfigurationSlicer from "./slices/HotelDetailsConfigurationSlicer";
import TagSlicer from "./slices/TagSlicer";
import HotelSearchParamSlicer from "./slices/HotelSearchParamsSlicer";
import SearchParamSlicer from "./slices/SearchParamsSlicer";
import HotelSetting from "./slices/HotelSetting";
import ContactSlicer from "./slices/ContactSlicer";
import NegotiationsSlicer from "./slices/NegotiationsSlicer";

function Store({ children }) {
  return (
    <AuthenticationSlicer>
      <HotelsDashboardSlicer>
        <HotelsDashboardFilterSlicer>
        <HotelConfigurationSlicer>
          <HotelDetailsConfigurationSlicer>
            <HotelSearchParamSlicer>
              <SearchParamSlicer>
                <TagSlicer>
                  <HotelSetting>
                    <ContactSlicer>
                      <NegotiationsSlicer>{children}</NegotiationsSlicer>
                    </ContactSlicer>
                  </HotelSetting>
                </TagSlicer>
              </SearchParamSlicer>
            </HotelSearchParamSlicer>
          </HotelDetailsConfigurationSlicer>
        </HotelConfigurationSlicer>
        </HotelsDashboardFilterSlicer>
      </HotelsDashboardSlicer>
    </AuthenticationSlicer>
  );
}

export default Store;
