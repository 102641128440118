import { createContext, useContext, useReducer } from "react";
import HotelSearchParamReducer from "../reducers/HotelSearchParamsReducer";

export const HotelSearchParamContext = createContext({
    state: { ...HotelSearchParamReducer.intialState },
    dispatch: () => { },
});

export const useHotelSearchParamContext = () => useContext(HotelSearchParamContext);

function HotelSearchParamSlicer({ children }) {
    const [state, dispatch] = useReducer(
        HotelSearchParamReducer.reducer,
        HotelSearchParamReducer.intialState,
    );

    return (
        <HotelSearchParamContext.Provider value={{ state, dispatch }}>
            {children}
        </HotelSearchParamContext.Provider>
    );
}

export default HotelSearchParamSlicer;