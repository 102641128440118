import { Box } from "@mui/material";
import DashboardHeader from "Components/Header/DashboardHeader";
import Layout from "Components/Layout/Layout";
import React, { useEffect, useRef, useState } from "react";
import { Users } from "./components/Users";
import { Notification } from "./components/Notification";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import Loading from "Components/Loading/Loading";

export const Team = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const { dispatchFetchAllEmployees } = useAuthentication();
  const { state: employeeResponse } = useAuthenticationContext();
  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const fetchCalled = useRef(false);

  useEffect(() => {
    if (!fetchCalled.current) {
      dispatchFetchAllEmployees();
      fetchCalled.current = true;
    }
  }, [dispatchFetchAllEmployees]);

  return (
    <Layout
      handleToggle={handleToggle}
      isSidebarOpened={isSidebarOpened}
      backgroundColor="#F2F2F2"
    >
      <Box
        sx={{ marginLeft: "50px", marginRight: "50px", marginBottom: "20px" }}
      >
        <DashboardHeader title="Team" />

        <div className="mt-10">
          <div className="flex items-start space-x-[18px]">
            {/* <Task /> */}
            <Users
              employees={employeeResponse.allEmployees}
              isLoading={employeeResponse.loading}
            />
          </div>

          <Notification />
        </div>
        <Loading isLoading={employeeResponse.loading} />
      </Box>
    </Layout>
  );
};
