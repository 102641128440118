import { useCallback } from "react";
import LandingPageService from "../../services/API/landingPageService";

const useLandingPage = () => {
  const callFetchAllLandingPagesApi = useCallback(async () => {
    return await LandingPageService.fetchAllLandingPages().then((response) => {
      return response;
    });
  }, []);

  const callFetchLandingPageByIdApi = useCallback(async (landingPageId) => {
    return await LandingPageService.fetchLandingPageById(landingPageId).then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callCreateLandingPageApi = useCallback(async (body, file) => {
    return await LandingPageService.createLandingPage(body, file).then((response) => {
      return response;
    });
  }, []);

  const callUpdateLandingPageApi = useCallback(async (body, file) => {
    return await LandingPageService.updateLandingPage(body, file).then((response) => {
      return response;
    });
  }, []);

  const callDeleteLandingPageByIdApi = useCallback(async (landingPageId) => {
    return await LandingPageService.deleteLandingPageById(landingPageId).then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callLandingPageUrlAlreadyExistApi = useCallback(
    async (landingPageUrl) => {
      return await LandingPageService.fetchIsUrlAlreadyExist(
        landingPageUrl
      ).then((response) => {
        return response;
      });
    },
    []
  );

  const callEnableDisableLandingPageByIdApi = useCallback(
    async (landingPageId, isEnabled) => {
      return await LandingPageService.enableDisableLandingPageById(
        landingPageId,
        isEnabled
      ).then((response) => {
        return response;
      });
    },
    []
  );

  return {
    callFetchAllLandingPagesApi,
    callCreateLandingPageApi,
    callUpdateLandingPageApi,
    callDeleteLandingPageByIdApi,
    callLandingPageUrlAlreadyExistApi,
    callFetchLandingPageByIdApi,
    callEnableDisableLandingPageByIdApi,
  };
};

export default useLandingPage;
