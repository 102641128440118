import React from "react";
import { Box, Typography } from "@mui/material";

export default function EventPackageSidebar() {
  return (
    <div>
      <Box
        sx={{
          width: "306px",
          height: "82px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ height: "60px" }}>
          <Box
            sx={{
              display: "flex",
              borderLeft: "4px solid #26529C",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px"
            }}
          >
            <Box
              sx={{
                margin: "18px",
                backgroundColor: "#26529C",
                border: "1px solid #E7E7E7",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  color: "white",
                  marginTop: "5px",
                }}
              >
                0
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "500",
                marginTop: "15px",
              }}
            >
              Info
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
