import { useCallback } from "react";
import IdealVacationService from "../../services/API/IdealVacationService";

const useIdealVacation = () => {
  const callFetchAllIdealVacationsApi = useCallback(async () => {
    return await IdealVacationService.fetchAllIdealVacations().then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callFetchIdealVacationByIdApi = useCallback(async (idealVacationId) => {
    return await IdealVacationService.fetchIdealVacationById(
      idealVacationId
    ).then((response) => {
      return response;
    });
  }, []);

  const callCreateIdealVacationApi = useCallback(async (body, file) => {
    return await IdealVacationService.createIdealVacation(body, file).then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callUpdateIdealVacationApi = useCallback(async (body, file) => {
    return await IdealVacationService.updateIdealVacation(body, file).then(
      (response) => {
        return response;
      }
    );
  }, []);

  const callDeleteIdealVacationByIdApi = useCallback(
    async (idealVacationId) => {
      return await IdealVacationService.deleteIdealVacationById(
        idealVacationId
      ).then((response) => {
        return response;
      });
    },
    []
  );

  return {
    callFetchAllIdealVacationsApi,
    callFetchIdealVacationByIdApi,
    callCreateIdealVacationApi,
    callUpdateIdealVacationApi,
    callDeleteIdealVacationByIdApi,
  };
};

export default useIdealVacation;
