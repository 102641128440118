import { useCallback } from "react";
import { FetchSearchParamByText } from "../../../library/store/reducers/HotelSearchParamsReducer";
import { useHotelSearchParamContext } from "../../../library/store/slices/HotelSearchParamsSlicer";
import SearchHotelServices from "../../../library/services/API/searchHotel";

function useSearchHotels() {
  const { dispatch: searchHotelSearchParams } = useHotelSearchParamContext();

  const callSearchHotelParamsApi = useCallback(async (searchInputValue) => {
    const payload = { name: searchInputValue };
    return await SearchHotelServices.searchHotelsParams(payload);
  }, []);

  const dispatchForHotelSearchParamByText = useCallback(
    (searchData) => {
      callSearchHotelParamsApi(searchData).then((response) => {
        const newHotelList = response;
        searchHotelSearchParams(FetchSearchParamByText(newHotelList));
      });
    },
    [callSearchHotelParamsApi, searchHotelSearchParams]
  );

  const callSearchParamsApi = useCallback(async (searchInputValue) => {
    const payload = { name: searchInputValue };
    return await SearchHotelServices.searchParams(payload);
  }, []);

  const dispatchForSearchParamByText = useCallback(
    (searchData) => {
      callSearchParamsApi(searchData).then((response) => {
        const newHotelList = response;
        searchHotelSearchParams(FetchSearchParamByText(newHotelList));
      });
    },
    [callSearchParamsApi, searchHotelSearchParams]
  );

  const callSearchHotelsApi = useCallback(async (body) => {
    return await SearchHotelServices.searchHotels(body).then((response) => {
      return response;
    });
  }, []); 

  return {
    dispatchForHotelSearchParamByText,
    dispatchForSearchParamByText,
    callSearchHotelsApi
  };
}

export default useSearchHotels;
