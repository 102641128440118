import { Box, TextField } from '@mui/material'
import React from 'react'
import CheckboxGroup from '../CheckBoxGroup/CheckBoxGroup'
const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function FilterSearchEngine({item, index, handleFilterChange,type}) {
    return (
        <div>
            <Box sx={index > 0 ?{marginTop:"30px"} : ""}>
                <TextField
                    placeholder="titolo"
                    value={item?.groupTitle}
                    name={"groupTitle"}
                    onChange={(e) => handleFilterChange(e, index)}
                    sx={{
                        marginLeft: "0px",
                        borderColor: "#b3b3b3",
                        borderRadius: "5px",
                        width: "876px",
                    }}
                />
                <Box
                    sx={{ width: "100%", marginLeft: "30px", marginTop: "10px" }}
                >
                    <CheckboxGroup label={label} items={item?.items} type={type} groupTitle={item?.groupTitle}/>
                </Box>
            </Box>
        </div>
    )
}
