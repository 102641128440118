import { Box, Divider, TextField, Typography, Button } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function EventPackageInfo({
  propEventName,
  propEventOrder,
  propEventLandingPageId,
  sendValueToParent
}
) {
  const navigate = useNavigate();
  // const handleNext = () => {
  //   navigate('/landing-page')
  // }
  const [eventName, setEventName] = useState(null);
  const [eventNameError, setEventNameError] = useState(false);
  const [eventOrder, setEventOrder] = useState(null);
  const [eventLandingPageId, setEventLandingPageId] = useState(null);

   /**
   * Side effects
   */
   useEffect(() => {
    setEventName(propEventName);
    setEventOrder(propEventOrder === 0 ? null : propEventOrder);
    setEventLandingPageId(propEventLandingPageId === 0 ? null : propEventLandingPageId);
   },[ propEventName,
    propEventOrder,
    propEventLandingPageId]);


   const handleNext = () => {
    // check validation
    let error = false;
    if (eventName === "" || eventName === null) {
      setEventNameError(true);
      error = true;
    }

    // call back
    if (!error) {
      sendValueToParent(
        eventName,
        eventOrder,
        eventLandingPageId
      );
    }
  };
  return (
    <Box
      sx={{
        marginLeft: "34px",
        width: "1003px",
        minHeight: "276px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Event Name <span style={{ color: "red" }}>*</span>
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            Enter the name of the event package that will be shown on the Home
            page
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
            borderColor: eventNameError ? "red" : undefined,
          }}
          variant="outlined"
          placeholder="Name"
          value={eventName}
          error={eventNameError}
          onChange={(e) => {
            setEventName(e.target.value);
            setEventNameError(e.target.value === "");
          }}
        />
      </Box>

      <Divider sx={{ margin: "18px 0px" }} />

      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          height: "105px",
          padding: "0px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Order
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingTop: "6px",
                marginLeft: "10px",
              }}
            >
              (optional)
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            If the order is left blank it will be checked by the system
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="the lower the number, the higher priority it is"
          value={eventOrder ? eventOrder : null}
          onChange={(e) => {
            setEventOrder(e.target.value);
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", padding: "20px" }}>
        <Button
          sx={{
            width: "186px",
            height: "44px",
            backgroundColor: "#26529C",
            color: "white",
            "&:hover": { backgroundColor: "#26529C" },
          }}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}
