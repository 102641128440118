import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import { ReactComponent as EyeOff } from "assets/icons/eye-off.svg";

import PrimaryButton from "Components/Button/PrimaryButton";
import AuthCard from "Components/Card/AuthCard";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token, emailAdddress } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState(emailAdddress);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassowrd, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const { state: userState } = useAuthenticationContext();
  const { dispatchResetPassword } = useAuthentication();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPass = e.target.value;

    setConfirmPassword(confirmPass);
    if (confirmPass.length < 6) {
      setConfirmPasswordError("Password must be at least 6 characters");
    } else if (confirmPass !== password) {
      setConfirmPasswordError("Password do no match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleReset = async () => {
    let hasError = false;
    if (!email) {
      setEmailError("Please enter your email");
      hasError = true;
    }
    if (!password) {
      setPasswordError("Please enter your password");
      hasError = true;
    }
    if (!confirmPassowrd) {
      setConfirmPasswordError("Please enter your confirm password");
      hasError = true;
    }

    if (!emailError && !passwordError && !confirmPasswordError && !hasError) {
      const credentials = {
        token: token,
        email: email,
        password: password,
      };

      try {
        await dispatchResetPassword(credentials);
        navigate("/team", { replace: true });
      } catch (error) {
        console.log("Error while reseting password: ", error);
      }

      if (rememberMe) {
        localStorage.setItem("userInfo", JSON.stringify({ email, password }));
      } else {
        localStorage.removeItem("userInfo");
      }
    }
  };

  return (
    <div className="bg-white w-screen h-full min-h-screen grid grid-cols-2 p-[21px]">
      <div className="grid place-items-center">
        <div>
          <h1 className="text-[30px] font-medium left-[45px] text-black mb-[39px]">
            Reset Password
          </h1>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: 400,
              margin: "auto",
              mt: 5,
            }}
          >
            <TextField
              required
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              onBlur={handleEmailChange}
              helperText={emailError}
              label="Eamil"
              placeholder="Enter your email address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon width={17} height={17} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: { color: "#26529C", fontSize: 16, fontWeight: 400 },
                sx: {
                  "&::placeholder": {
                    opacity: 1,
                    color: "#26529C",
                  },
                },
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              value={password}
              onChange={handlePasswordChange}
              error={Boolean(passwordError)}
              onBlur={handlePasswordChange}
              helperText={passwordError}
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your New Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon width={17} height={17} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility sx={{ fontSize: 15, color: "#ABABAB" }} />
                      ) : (
                        <EyeOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  color: "#26529C",
                  fontSize: 16,
                  fontWeight: 400,
                },
                sx: {
                  "&::placeholder": {
                    opacity: 1,
                    color: "#26529C",
                  },
                },
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              value={confirmPassowrd}
              onChange={handleConfirmPasswordChange}
              error={Boolean(confirmPasswordError)}
              onBlur={handleConfirmPasswordChange}
              helperText={confirmPasswordError}
              label="Confirm your Password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm your New Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon width={17} height={17} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility sx={{ fontSize: 15, color: "#ABABAB" }} />
                      ) : (
                        <EyeOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  color: "#26529C",
                  fontSize: 16,
                  fontWeight: 400,
                },
                sx: {
                  "&::placeholder": {
                    opacity: 1,
                    color: "#26529C",
                  },
                },
              }}
              fullWidth
              variant="standard"
            />
          </Box>
          <div className="flex items-center justify-between mt-[17px] mb-[58px]">
            <div className="flex items-center space-x-[5px]">
              <Checkbox
                checked={rememberMe}
                sx={{ padding: 0, margin: 0 }}
                onChange={(e) => setRememberMe(e.target.checked)}
                size="small"
              />

              <p className="text-[12px] font-light text-black leading-[18px]">
                Rememer me
              </p>
            </div>
          </div>
          <PrimaryButton
            onClick={handleReset}
            buttonText={"Reset"}
            borderRadius="32px"
            height="53px"
            isLoading={userState.loading}
          />
        </div>
      </div>
      <AuthCard title="Reset Password" subtitle="Insert your new password" />
    </div>
  );
};

export default ResetPassword;
