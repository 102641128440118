import React from "react";
import visitsIcon from "assets/images/visits.png";
import negotiationIcon from "assets/images/negotiations.png";
import contactsIcon from "assets/images/contacts.png";
import revenuesIcon from "assets/images/revenues.png";

const StatisticsCard = () => {
  return (
    <div className="w-[40%] h-[271px] bg-white shadow-lg rounded-[20px]">
      <div className="flex items-start border-b-[1px] border-[#F2F2F2]">
        <div className="flex-1 pl-[22px] border-r-[1px] pb-[17px] pt-[16px] border-[#F2F2F2]">
          <img className="w-[28px] h-[28px]" src={visitsIcon} alt="" />
          <h2 className="text-[16px] font-medium leading-[24px] mt-[4px] text-[#171B1E]">
            Visite
          </h2>
          <h1 className="text-[24px] font-semibold text-[#3984F3] leading-[36px]">
            3.405
          </h1>
          <p className="text-[12px]  -mt-[5px] font-light text-[#9291A5]">
            Ultimi 7 Giorni
          </p>
        </div>
        <div className="flex-1 pl-[22px] pt-[16px] pb-[17px]">
          <img
            className="w-[28px] h-[28px]"
            src={contactsIcon}
            alt="contact-icon"
          />
          <h2 className="text-[16px] font-medium leading-[24px] mt-[4px] text-[#171B1E]">
            Contatti
          </h2>
          <h1 className="text-[24px] font-semibold text-[#C548FF] leading-[36px]">
            1.452{" "}
          </h1>
          <p className="text-[12px]  -mt-[5px] font-light text-[#9291A5]">
            Ultimi 7 Giorni
          </p>
        </div>
      </div>
      <div className="flex items-start ">
        <div className="flex-1 border-r-[1px] pl-[22px] pt-[16px] pb-5 border-[#F2F2F2]">
          <img className="w-[28px] h-[28px]" src={negotiationIcon} alt="" />
          <h2 className="text-[16px] font-medium leading-[24px] mt-[4px] text-[#171B1E]">
            Negoziazioni
          </h2>
          <h1 className="text-[24px] font-semibold text-[#2251F8] leading-[36px]">
            1.765{" "}
          </h1>
          <p className="text-[12px]  -mt-[5px] font-light text-[#9291A5]">
            Ultimi 7 Giorni
          </p>
        </div>
        <div className="flex-1 pt-[16px] pl-[22px] pb-5">
          <img className="w-[28px] h-[28px]" src={revenuesIcon} alt="" />
          <h2 className="text-[16px] font-medium leading-[24px] mt-[4px] text-[#171B1E]">
            Ricavi Lordi
          </h2>
          <h1 className="text-[24px] font-semibold text-[#54D969] leading-[36px]">
            356.400€
          </h1>
          <p className="text-[12px] -mt-[5px] font-light text-[#9291A5]">
            Ultimi 7 Giorni
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCard;
