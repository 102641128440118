export const initialMetadataState = {
    title: '',
    description: '',
}

export const initialImageState = {
    imageUrl: '',
    imageName: '',
    order: null,
    AltText: null,
    metaData: initialMetadataState,
    redirectLink: '',
}
export const serviceTagInitialState = {
    id: '',
    displayName: '',
    code: '',
    TagType: '',
    Status: '',
}

export const roomHandlingInitialState = {
    RoomDescription: [], 
    Rooms: [],
    images: [],
}

export const phoneClientInitialState = {
    Label: '',
    PhoneNumber: '',
    IsDisplayed: false,
}

export const circleAreaDetailsInitialState = {
    latitude: '',
    longitude: '',
    radius: '',
}

export const rectangleAreaDetailsInitialState = {
    latitudeVertex1: '',
    longitudeVertex1: '',
    latitudeVertex2: '',
    longitudeVertex2: '',
}

export const geoFenceDetailsInitialState = {
    id: '',
    circleArea: circleAreaDetailsInitialState,
    rectangleAreaDetailsInitialState: rectangleAreaDetailsInitialState,
}

export const localityDetailsInitialState = {
    id: '',
    geoFenceDetail: geoFenceDetailsInitialState,
    dstCode: '',
    dstName: '',
    iataCode: '',
    subLocality: '',
    locality: '',
    subSubIsland: '',
    subIsland: '',
    islandCounty: '',
    subArchipelago: '',
    archipelago: '',
    regionState: '',
    subSubNation: '',
    subNation: '',
    nationCode: '',
    nationName: '',
    subSubContinent: '',
    subContinent: '',
    continent: '',
}

export const transportGroupInitialState = {
    transportName: []
}

export const ImageCenterPointInitialState = {
    x:0,
    y:0,
}

export const serviceDataResponseInitialResponse = {
    fileUrl: '',
    fileName: '',
    fileType: '',
    order: 0,
    imageCenterPoint: ImageCenterPointInitialState,
    alt: '',
}

export  const featureDetailsInitialState = {
    valueCode : '',
    valueName : '',
    resourceID : '',
    code : '',
    name : '',
    fileUrl: '',
    fileName: '',
    fileType: '',
    order: 0,
    imageCenterPoint: ImageCenterPointInitialState,
    alt: '',
}

export const distancesInitialState = {
    value: 0,
    unitOfMeasurement: '',

}

export const distanceFromInitialState = {
    serviceName:  '',
    distance: [
        distancesInitialState,
    ]
}

export const initialState = {
    id: '',
    hotelCode: '',
    images: [
        initialImageState,
    ],
    videoUrl: null,
    serviceTags: [
    ],
    serviceTagHighlighted: [
        
    ],
    allServiceTags: [
        
    ],
    description: '',
    minDescription: '',
    RoomHandling: roomHandlingInitialState,
    phoneClient: phoneClientInitialState,
    localityDetails: [
        localityDetailsInitialState,
    ],
    transportGroups: [
        transportGroupInitialState,
    ],
    featureDetails: [
        featureDetailsInitialState,
    ],
    distanceFrom: [
        distanceFromInitialState,
    ],
    highlightedFeatures: [
        featureDetailsInitialState,
    ],
    features: [
        featureDetailsInitialState,
    ],
}

const FetchHotelImagesAction = {
    FetchHotelImages: "FetchHotelImages",
}



export function FetchHotelImages(hotelCode) {
    return {
        type: FetchHotelImagesAction.FetchHotelImages,
        payload: {
            value: hotelCode,
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case FetchHotelImagesAction.FetchHotelImages:
            const newState = action.payload.value;
            return  {
                images: newState?.images 
            }
        default:
            throw new Error('Error while updating Hotel Dashboard Request: HotelDashboardReducer.reducer');    
    }
}

const hotelDashboardConfigurationReducer = { initialState, reducer }

export default hotelDashboardConfigurationReducer;