import {React,useEffect} from "react";
import { Box, Button } from "@mui/material";
import addIcon from "../../assets/addIcon.svg";
import cameraIcon from "../../assets/camera.svg";
import deleteIcon from "../../assets/delete.svg";
import img from "../../assets/img1.png";
import IconSelect from "../IconSelect/IconSelect";

import icon from "../../assets/icon.svg";
import ImageSelect from "../ImageSelect/ImageSelect";

export default function TagsComponent({ tagName,hotelImages, selectedImages, setSelectedImages, selectedEmojis, setSelectedEmojis,deleteSelectedButton }) {
  
  const deleteItem = () =>{
    deleteSelectedButton();
  };
  
  return (
    <div>
      <Box sx={{ margin: "30px 0px 10px 40px", display: "flex" }}>
        <IconSelect icon={icon} selectedEmojis={selectedEmojis} isEditable={false}/>
        <Box
          sx={{
            margin: "10px 20px",
            fontSize: "15px",
            fontWeight: "400",
          }}
        >
          {tagName}
        </Box>
        <Button
            onClick={deleteItem}
            sx={{
                        marginLeft: "10px",
                        width: "40px",
                        height: "48px",
                        border: "1px dashed",
                        borderColor: "#b3b3b3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="icon"
                        style={{ height: "40px" }}
                      />
                    </Button>
      </Box>
      <ImageSelect hotelImages={hotelImages} selectedImages={selectedImages} setSelectedImages={setSelectedImages}/> 
    </div>
  );
}