import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;
const instance = axios.create({ baseURL: apiBaseUrl });

function responseHelper(response) {
  if (response?.status === 200 || response?.status === 201) {
    return response.data;
  }
  // return null;
  throw new Error(response?.data?.message || "Unknown error occurred");
}

// function errorHelper(error) {
//   if (error?.response?.data) {
//     const message = error.response.data;
//     if (message?.code !== undefined) {
//       const errorMessages = message.message;
//       if (Array.isArray(errorMessages)) {
//         errorMessages.forEach((errorMessage) => {
//           console.warn(errorMessage);
//         });
//       } else {
//         console.warn(errorMessages);
//       }
//     }
//   }
//   return null;
// }

function errorHelper(error) {
  let errorMessage = "Unknown error occurred";

  if (error?.response?.data) {
    const message = error.response.data;
    if (message?.code !== undefined) {
      const errorMessages = message.message;
      if (Array.isArray(errorMessages)) {
        errorMessages.forEach((errorMessage) => {
          console.warn(errorMessage);
        });
        errorMessage = errorMessages.join(", ");
      } else {
        console.warn(errorMessages);
        errorMessage = errorMessages;
      }
    } else {
      errorMessage = message;
    }
  }

  return Promise.reject(new Error(errorMessage));
}

instance.interceptors.request.use(
  function configHeader(config) {
    config.headers = { ...config.headers };
    return config;
  },
  function configError(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(responseHelper, errorHelper);

export default instance;
