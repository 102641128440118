import React from "react";

const HighlightedData = ({info}) => {

   // Calculate the total payment
   const totalPayment = info?.reservations?.reservationsList?.reduce((total, reservation) => {
    return total + reservation?.price;
  }, 0);

  const totalPaidAmount = info?.reservations?.reservationsList?.reduce((total, reservation) => {
    const paidAmount = reservation.accounting
      .filter(account => account.paymentStatus === "PAID")
      .reduce((sum, account) => sum + account.amount, 0);

    return total + paidAmount;
  }, 0);

  return (
    <div className="w-full px-5">
      <div className="w-full bg-primary bg-opacity-[0.07] rounded-[10px] border-[1.5px] border-primary px-[15px] pt-3 pb-2 mt-[25px]">
        <h2 className="text-primary text-[24px] font-semibold leading-[36px]">
          Dati in evidenza
        </h2>
        <div className="flex mt-[5px] items-start justify-between">
          <div>
            <p className="text-primary text-[12px] font-semibold">
              RICHIESTE DI PREVENTIVO
            </p>
            <h4 className="text-primary text-[24px] font-normal leading-[36px]">
              {info?.quotes?.totelQuotes}
            </h4>
          </div>
          <div>
            <h4 className="text-[12px] text-end font-semibold text-primary">
              RICAVO TOTALE
            </h4>
            <h4 className="text-primary text-end text-[24px] font-normal leading-[36px]">
              {totalPayment}€
            </h4>
          </div>
        </div>
        <div className="flex mt-[14px] items-start justify-between">
          <div className="">
            <h4 className="text-[12px] font-semibold text-primary">
              PRENOTAZIONI ESEGUITE
            </h4>
            <h4 className="text-primary text-[24px] font-normal leading-[36px]">
            {info?.reservations?.totelReservations}
            </h4>
          </div>
          <div>
            <h4 className="text-[12px] text-end font-semibold text-primary">
              RICAVO DA VERSARE
            </h4>
            <h4 className="text-primary text-end text-[24px] font-normal leading-[36px]">
             {totalPaidAmount}€
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlightedData;
