import { createContext, useContext, useReducer } from "react";
import  negotiationsReducer from "../reducers/NegotiationsReducer";

export const NegotiationsContext = createContext({
    state: {...negotiationsReducer.initialState },
    dispatch: () => { },
});

export const useNegotiationsContext = () => useContext(NegotiationsContext);

function NegotiationsSlicer({ children }) { 
    const [state, dispatch] = useReducer(
        negotiationsReducer.reducer,
        negotiationsReducer.initialState,
    );
   
    return (
        <NegotiationsContext.Provider value={{state, dispatch}}>
            {children}
        </NegotiationsContext.Provider>
    );
}

export default NegotiationsSlicer;