import { Button, CircularProgress } from "@mui/material";
import React from "react";

const PrimaryButton = ({
  buttonText,
  width = "100%",
  height = "36px",
  fontSize = "16px",
  onClick,
  borderRadius = "10px",
  bgColor = "#26529C",
  isLoading = false,
}) => {
  return (
    <Button
      type="submit"
      onClick={onClick}
      disabled={isLoading}
      sx={{
        border: "1.5px solid",
        borderColor: bgColor,
        borderRadius: borderRadius,
        color: "#FFFFFF",
        height: height,
        width: width,
        textTransform: "none",
        fontSize: fontSize,
        fontWeight: "600",
        background: bgColor,
        "&:hover": {
          color: "#26529C",
        },
      }}
    >
      {isLoading ? (
        <CircularProgress size={24} sx={{ color: "white" }} />
      ) : (
        buttonText
      )}
    </Button>
  );
};

export default PrimaryButton;
