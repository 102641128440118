import React, { useEffect,useState } from "react";
import { formatDateRange,calculateTimeLeft } from '../../../utils/formatDate';

const TimerComponent = ({ data,info }) => {
  const [timeLeft, setTimeLeft] = useState();

  useEffect(()=>{
      let leftTimeData = calculateTimeLeft(info?.startDate);
      setTimeLeft(leftTimeData);
  },[info]);
  
  
  return (
    <div className="border-[1px] mx-[19px] mt-[10px] border-[#DDDDDD] rounded-[10px] bg-[#FAFAFA] px-[21px] py-[14px]">
      <h3 className="text-[16px] font-normal leading-[30px] text-primary">
        Tempo mancante alla partenza!
      </h3>
      <div className="flex items-center pl-[27px] max-w-[500px] justify-center flex-col ">
        <div className="flex items-center w-full justify-between mt-[20px]">
          <div className="flex flex-col items-center">
            <h2 className="text-[30px] font-semibold text-black leading-[30px] ">
              {timeLeft?.days}
            </h2>
            <p className="text-[16px] font-normal text-primary mt-[14px]">
              GIORNI
            </p>
          </div>
          <div className="w-[37px] bg-[#E1E1E1] h-[1px] transform rotate-90" />
          <div className="flex flex-col items-center">
            <h2 className="text-[30px] font-semibold text-black leading-[30px] ">
              {timeLeft?.hours}
            </h2>
            <p className="text-[16px] font-normal text-primary mt-[14px]">
              ORE
            </p>
          </div>
          <div className="w-[37px] bg-[#E1E1E1] h-[1px] transform rotate-90" />

          <div className="flex flex-col items-center">
            <h2 className="text-[30px] font-semibold text-black leading-[30px] ">
              {timeLeft?.minutes}
            </h2>
            <p className="text-[16px] font-normal text-primary mt-[14px]">
              MINUTI
            </p>
          </div>
          <div className="w-[37px] bg-[#E1E1E1] h-[1px] transform rotate-90" />

          <div className="flex flex-col items-center">
            <h2 className="text-[30px] font-semibold text-black leading-[30px] ">
              {timeLeft?.seconds}
            </h2>
            <p className="text-[16px] font-normal text-primary mt-[14px]">
              SECONDI
            </p>
          </div>
        </div>
        <p className="text-[#ED0000] font-normal text-[14px] text-center mt-[15px]">
          {/* {data.dateRange} */}
          {info?.startDate && info?.endDate ? `${formatDateRange(info?.startDate)} - ${formatDateRange(info?.endDate)}` : ''}
        </p>
      </div>
    </div>
  );
};

export default TimerComponent;
