import axiosConfig from "../axiosConfig";
class TouristicDestinationService {
    fetchAllTouristicDestination = async () => {
        return await axiosConfig.get(`/TouristicDestination/getAll`);
      };

      createTouristicDestination = async (body, file) => {
        if (body) {
            const formData = new FormData();
      
            const appendFormData = (prefix, obj) => {
              Object.keys(obj).forEach((key) => {
                const newKey = prefix ? `${prefix}.${key}` : key;
                if (Array.isArray(obj[key])) {
                  obj[key].forEach((item, index) => {
                    appendFormData(`${newKey}[${index}]`, item);
                  });
                } else {
                  formData.append(newKey, obj[key]);
                }
              });
            };
      
            appendFormData("", body);
      
            // attach file
            if (file) {
              formData.append("destinationImageFile", file);
            }
      
            // Make the request with FormData
            return await axiosConfig.post(`/TouristicDestination/create`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
          console.warn("body not specified, TouristicDestinationService.createTouristicDestination");
      
          return null;


        // if (payload) {
        //   return await axiosConfig.post(`/TouristicDestination/create`, payload);
        // }
        // console.warn(
        //   "payload not provided, EventPackageServices.eventPackageParams"
        // );
        // return null;
      };

      updateTouristicDestination = async (payload,file) => {
        if (payload) {
          const formData = new FormData();
          const appendFormData = (prefix, obj) => {
            Object.keys(obj).forEach((key) => {
              const newKey = prefix ? `${prefix}.${key}` : key;
              if (Array.isArray(obj[key])) {
                obj[key].forEach((item, index) => {
                  appendFormData(`${newKey}[${index}]`, item);
                });
              } else {
                formData.append(newKey, obj[key]);
              }
            });
          };
    
          appendFormData("", payload);
    
          // attach file
          if (file) {
            formData.append("destinationImageFile", file);
          }

          return await axiosConfig.post(`/TouristicDestination/update`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        console.warn(
          "payload not provided, TouristicDestinationService.eventPackageParams"
        );
        return null;
      };

      fetchTouristicDestinationById = async (touristicDestinationById) => {
        if (touristicDestinationById) {
          return await axiosConfig.get(`/TouristicDestination/get/${touristicDestinationById}`);
        }
        console.warn(
          "landingPageId not specified, TouristicDestinationService.fetchTouristicDestinationById"
        );
    
        return null;
      };

      enableDisableTouristicDestinationById = async (touristicDestinationById, isEnabled) => {
        if (touristicDestinationById) {
          return await axiosConfig.get(
            `/TouristicDestination/enableDisable/${touristicDestinationById}/${isEnabled}`
          );
        }
        console.warn(
          "touristicDestinationById not specified, TouristicDestinationService.enableDisableTouristicDestinationById"
        );
    
        return null;
      };

      deleteEventTouristicDestinationById = async (touristicDestinationById) => {
        if (touristicDestinationById) {
          return await axiosConfig.delete(
            `/TouristicDestination/delete/${touristicDestinationById}`
          );
        }
        console.warn(
          "touristicDestinationById not specified, EventPackageIdService.deleteEventTouristicDestinationById"
        );
    
        return null;
      };
}
const instance = new TouristicDestinationService();

export default instance;
