import { Box, Button, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useState } from "react";
import CustomFilter from "../CustomFilter/CustomFilter";

import { useNavigate } from "react-router-dom/dist";
export default function Table({
  rows,
  enableDisableLandingPage,
  deleteLandingPage,
  onRowClicked,
  rightBtnTxt,
  rightBtnClick,
}) {
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      // filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const [columnVisibility, setColumnVisibility] = useState({
    PageTitle: false,
    PageURL: false,
    HotelsCount: false,
    CreatedBy: false,
    CreatedDate: false,
    Views: false,
    QuoteRequests: false,
    Bookings: false,
    TotalRevenue: false,
    NumeroRichiestePreventivi: false,
    NumeroDiPrenotazioni: false,
    RatioPrenotazioniSuPreventivi: false,
    NumeroDiContattiGenerati: false,
    NumeroDiNegoziazioniGenerate: false,
    TotaleRichiesto: false,
    TotaleGenerato: false,
    UltimaPrenotazione: false,
  });

  /**
   * functions
   */

  const enableDisableLandingPageLocal = (e, params) => {
    const confirmed = window.confirm(
      `Do you want to disable the ${params.node.data.pageTitle}?`
    );

    if (confirmed) {
      e.target.checked = !params.node.data.isActive;

      enableDisableLandingPage(params.node.data.id, !params.node.data.isActive);
    } else {
      e.target.checked = params.node.data.isActive;
    }
  };

  const deleteLandingPageLocal = (e, params) => {
    const confirmed = window.confirm(
      `Do you want to delete the ${params.node.data.pageTitle}?`
    );

    if (confirmed) {
      deleteLandingPage(params.node.data.id);
    }
  };

  const handleColumnVisibilityChange = (event) => {
    setColumnVisibility({
      ...columnVisibility,
      [event.target.name]: event.target.checked,
    });
  };

  const columns = [
    {
      // field: "Copertina",ssss
      headerName: "Attivo",
      maxWidth: 80,
      suppressMenu: true,
      cellRenderer: function (params) {
        return (
          <div style={{ display: "flex", marginTop: "10px" }}>
            <input
              type="checkbox"
              checked={params.data.isActive}
              onChange={(e) => enableDisableLandingPageLocal(e, params)}
            />
            <img
              src="/static/media/delete.5756e7189ef69e97a5d76639b98b91ee.svg"
              alt="img"
              onClick={(e) => deleteLandingPageLocal(e, params)}
            ></img>
          </div>
        );
      },
    },

    {
      field: "pageTitle",
      headerName: "Page Title",
      minWidth: 150,
      hide: columnVisibility.PageTitle,
    },
    {
      field: "pageURL",
      headerName: "URL",
      minWidth: 150,
      hide: columnVisibility.PageURL,
      filter: "agNumberColumnFilter",
    },
    {
      field: "hotelsCount",
      headerName: "Number Hotels",
      width: 150,
      hide: columnVisibility.HotelsCount,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      minWidth: 150,
      hide: columnVisibility.CreatedBy,
    },
    {
      field: "createdDate",
      headerName: "Created When",
      minWidth: 150,
      hide: columnVisibility.CreatedDate,
    },
    {
      field: "",
      headerName: "Views",
      minWidth: 200,
      hide: columnVisibility.Views,
    },
    {
      field: "",
      headerName: "Quote Requests",
      width: 100,
      hide: columnVisibility.QuoteRequests,
    },
    //-----------------------------------
    {
      field: "",
      headerName: "Bookings",
      width: 200,
      hide: columnVisibility.Bookings,
    },
    {
      field: "",
      headerName: "Total Revenue",
      width: 200,
      hide: columnVisibility.TotalRevenue,
    },
    {
      field: "",
      headerName: "Numero Richieste Preventivi",
      width: 200,
      hide: columnVisibility.NumeroRichiestePreventivi,
    },
    {
      field: "",
      headerName: "Numero di Prenotazioni",
      width: 250,
      hide: columnVisibility.NumeroDiPrenotazioni,
    },
    {
      field: "",
      headerName: "Ratio Prenotazioni su Preventivi",
      width: 250,
      hide: columnVisibility.RatioPrenotazioniSuPreventivi,
    },
    {
      field: "",
      headerName: "Numero di Contatti Generati",
      width: 300,
      hide: columnVisibility.NumeroDiContattiGenerati,
    },
    {
      field: "",
      headerName: "Numero di Negoziazioni Generate",
      width: 250,
      hide: columnVisibility.NumeroDiNegoziazioniGenerate,
    },
    {
      field: "",
      headerName: "Totale Richiesto",
      width: 300,
      hide: columnVisibility.TotaleRichiesto,
    },
    {
      field: "",
      headerName: "Totale Generato",
      width: 200,
      hide: columnVisibility.TotaleGenerato,
    },
    {
      field: "",
      headerName: "Ultima Prenotazione",
      width: 200,
      hide: columnVisibility.UltimaPrenotazione,
    },
  ];

  const hideColumns = [
    "EventName",
    "CreatedBy",
    "Views",
    "QuoteRequests",
    "Bookings",
    "TotalRevenue",
    "Order",
    "url",
    "numberHotels",
    "pageTitle",
    "numeroRichiestePreventivi",
    "numeroDiPrenotazioni",
    "ratioPrenotazioniSuPreventivi",
    "numeroDiContattiGenerati",
    "numeroDiNegoziazioniGenerate",
    "totaleRichiesto",
    "totaleGenerato",
    "ultimaPrenotazione",
  ];

  const navigate = useNavigate();

  return (
    <div className="ag-theme-quartz" style={{ height: 350 }}>
      <Box
        sx={{
          margin: "30px 0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomFilter
          hideColumns={hideColumns}
          columnVisibility={columnVisibility}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
        />
        <Box className="text-[20px] font-semibold text-primary placeholder:text-primary border-[1.5px] border-primary rounded-[5px] px-[10px] h-[42px] bg-primary bg-opacity-[0.13]">
          <Button onClick={rightBtnClick}>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#26529C",
                fontWeight: "600",
                textTransform: "none",
              }}
            >
              {rightBtnTxt}
            </Typography>
          </Button>
        </Box>
      </Box>
      <AgGridReact
        onCellClicked={onRowClicked}
        rowData={rows}
        columnDefs={columns}
        defaultColDef={defaultColDef}
        includeHiddenColumnsInQuickFilter={true}
        pagination={true}
        paginationPageSize={10}
      />
    </div>
  );
}
