import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import addIcon from "../../assets/addIcon.svg";
import cameraIcon from "../../assets/camera.svg";
import deleteIcon from "../../assets/delete.svg";
import img from "../../assets/img1.png";
import ImageModal from "../ImageModal/ImageModal";

export default function ImageSelect({
  hotelImages,
  selectedImages,
  setSelectedImages,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((image, index) => index !== indexToRemove)
    );
  };

  return (
    <div>
      <Box sx={{ margin: "10px 0px 10px 40px", display: "flex" }}>
        <Button
          onClick={handleButtonClick}
          sx={{
            width: "63px",
            height: "48px",
            border: "1px dashed",
            borderColor: "#b3b3b3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <img src={cameraIcon} alt="icon" />
          <img src={addIcon} alt="icon" style={{ marginLeft: "2px" }} />
        </Button>
        <Box sx={{ marginLeft: "20px", display: "flex" }}>
          {selectedImages?.map((image, index) => {
            const newRoot = "https://hoescapeapi2.azurewebsites.net";

            const newImageUrl = image?.imageUrl?.replace(
              /https:\/\/localhost:\d+/,
              newRoot
            );
            return (
              <Box key={index} sx={{}}>
                <img
                  src={newImageUrl}
                  alt={`selected-image-${index}`}
                  style={{
                    width: "63px",
                    height: "48px",
                    borderRadius: "10px",
                    margin: "0px -10px",
                    padding: "0px",
                  }}
                />
                <img
                  src={deleteIcon}
                  alt="delete icon"
                  style={{
                    position: "relative",
                    top: "-18px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveImage(index)}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <ImageModal
        hotelImages={hotelImages}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
      />
    </div>
  );
}
