import React, { useEffect, useRef, useState } from "react";
import ArrowTextButtonHeader from "../../../Components/Header/ArrowTextButtonHeader";
import PrimaryButton from "../../../Components/Button/PrimaryButton";
import InfoBox from "../../../Components/Box/InfoBox";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress
} from "@mui/material";
import Room from "../../../Components/Room/Room";
import SecondaryButton from  "../../../Components/Button/SecondaryButton";
import { requestQuotesData } from "../../ContactDetails/dummyData/dummyData";

const ReservationsMadeSection = ({info,onChangeCenterData,onLoadMoreData,totelReservations,isResLoading}) => {
  const [expand, setExpand] = useState(false);
  const [expandReservation, setExpandReservation] = useState(false);
  const [quoteReservationModalOpen, setReservationModalOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [guestText, setGuestText] = useState("");
  const [expandBox, setExpandBox] = useState(
    Array(info?.length).fill(false)
  );
  const wrapper = useRef();
  const dropdownRef = useRef();

  const handleCloseReservationtModel = () => {
    setReservationModalOpen(false);
  };

  const createReservation = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const {
      email,
      subject,
      description,
      hotelLink,
      guest,
      checkIn,
      checkOut,
      amountPaid,
      amountToPay,
      transportName,
      transportPrice,
    } = formJson;

    handleCloseReservationtModel();
  };

  const [guestForm, setGuestForm] = useState({
    where: "",
    rooms: [
      {
        id: "1",
        adults: 2,
        children: 0,
        children_age: [],
      },
    ],
    startDate: null,
    endDate: null,
  });

  useEffect(()=>{
    if (onChangeCenterData) {
    onChangeCenterData(expandBox,true);
    }
  },[expandBox])

  const [displayedItems, setDisplayedItems] = useState(5);

  const loadMoreItems = () => {
    setDisplayedItems((prev) => prev + 5);
    onLoadMoreData(displayedItems);
  };

  const updateGuestForm = (data) => {
    setGuestForm((guestForm) => ({ ...guestForm, ...data }));
  };

  const clickHandler = (e) => {
    if (!dropdownRef?.current?.contains(e.target)) {
      setActive(true);
    }
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) {
        setActive(false);
      }
    };
    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  useEffect(() => {
    let totalAdults = 0;
    let totalChildren = 0;
    guestForm?.rooms.forEach((element) => {
      totalAdults = totalAdults + element.adults;
      totalChildren = totalChildren + element.children;
    });

    let text =
      (guestForm?.rooms.length > 0
        ? guestForm?.rooms.length === 1
          ? `${guestForm?.rooms.length} Stanza`
          : `${guestForm?.rooms.length} Stanze`
        : ``) +
      (totalAdults > 0 ? `, ${totalAdults} Ad.` : ``) +
      (totalChildren > 0
        ? totalChildren === 1
          ? `, ${totalChildren} bambino`
          : `, ${totalChildren} bambini`
        : ``);

    setGuestText(text);
  }, [guestForm?.rooms]);

  return (
    <div className="mt-[30px] w-full">
      <div className=" border-[1.3px] border-primary w-full" />
      <div className="px-[37px]">
        <ArrowTextButtonHeader
          title={`Prenotazioni eseguite  (${totelReservations ? totelReservations: 'Nessuna Prenotazione'})`}
          buttonText="Crea"
          isButton={true}
          marginTop={15}
          onClick={() => setExpandReservation(!expandReservation)}
          isExpand={expandReservation}
          buttonClick={() => setReservationModalOpen(true)}
        />
        <Dialog
          open={quoteReservationModalOpen}
          onClose={handleCloseReservationtModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            component: "form",
            onSubmit: createReservation,
            sx: {
              width: "500px",
              maxHeight: "700px",
              paddingBottom: "15px",
              paddingRight: "15px",
            },
          }}
        >
          <DialogTitle
            sx={{ color: "#26529C", fontWeight: "600" }}
            id="alert-dialog-title"
          >
            New Quote Request
          </DialogTitle>
          <DialogContent sx={{ paddingRight: "8px" }}>
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Email
            </label>
            <input
              required
              placeholder="Email Address"
              name="email"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Subject
            </label>
            <input
              required
              name="subject"
              placeholder="Enter Subject"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Mini Description
            </label>
            <textarea
              required
              rows={2}
              name="description"
              placeholder="Enter Your Description"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] max-h-[100px] w-full rounded-[5px] text-primary text-[16px] font-semibold p-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Hotel Link
            </label>
            <input
              required
              name="hotelLink"
              placeholder="Enter Hotel Link"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Check In
            </label>
            <input
              required
              type="date"
              name="checkin"
              placeholder="Enter Checkin Date"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Check Out
            </label>
            <input
              required
              type="date"
              name="checkout"
              placeholder="Enter Checkout Date"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Guests
            </label>
            <div
              ref={wrapper}
              className={"searchItem " + (active ? "active" : "")}
            >
              <input
                required
                onFocus={clickHandler}
                readOnly
                value={guestText}
                name="guest"
                placeholder="Enter Guests"
                className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
              />
              <Room
                guestForm={guestForm}
                updateGuestForm={updateGuestForm}
                active={active}
                dropdownRef={dropdownRef}
              />
            </div>
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Amount Paid
            </label>
            <input
              required
              name="amountPaid"
              placeholder="Enter Amount Paid"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Amount To Pay
            </label>
            <input
              required
              name="amountToPay"
              placeholder="Enter Amount To Pay"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Trasnport Name{" "}
            </label>
            <input
              name="transportName"
              placeholder="Enter Transport Name"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
            <label className="text-primary text-[16px] leading-5 font-semibold">
              Transport Price
            </label>
            <input
              name="transportPrice"
              placeholder="Enter Transport Price"
              className="mt-[4px] placeholder:text-primary placeholder:text-opacity-50 mb-[8px] bg-primary bg-opacity-[0.07] h-[52px] w-full rounded-[5px] text-primary text-[16px] font-semibold px-[16px]"
            />
          </DialogContent>
          <DialogActions>
            <SecondaryButton
              onClick={handleCloseReservationtModel}
              backgroundColor="white"
              buttonText="Close"
              width="100px"
              height="36px"
            />
            <PrimaryButton width="100px" buttonText="Create" />
          </DialogActions>
        </Dialog>
        <div
          className={`mt-[12px] transition-[height] duration-200 ease-in-out ${
            expandReservation ? "h-auto" : "h-0 overflow-hidden"
          }`}
        >
          {info && info.slice(0, displayedItems).map((item, ind) => (
            <InfoBox
              key={ind}
              item={item}
              marginTop="8px"
              onClick={() => {
                const newExpandBox = Array(info?.length).fill(
                  false
                );
                newExpandBox[ind] = true;
                setExpandBox(newExpandBox);
                
              }}
              setExpand={(value) => {
                const newExpandBox = [...expandBox];
                newExpandBox[ind] = value;
                setExpandBox(newExpandBox);
              }}
              expand={expandBox[ind]}
              isBooking = {true}
            />
          ))}
          {info?.length < totelReservations  && (
            <button
              onClick={loadMoreItems}
              className="border-[1.5px] border-dashed font-semibold text-center w-full border-primary mt-[8px] rounded-[10px]  px-[9px] py-[6px]"
            >
             {isResLoading ?  <CircularProgress/> : "Carica altre 5 Richieste di Preventivo" } 
            </button>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default ReservationsMadeSection;
