import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import deleteIcon from "../../assets/Vector.svg";
import useHotelDashboard from "../../library/hooks/UserHotelDashboard/useHotelDashboard";
import HotelDashboardService from "../../library/services/API/hotelDashboard";

export default function ModificaBanner({
  onClose,
  updateDataArr,
  dataArr,
  imageDetails,
  isEdit,
  setDataArr,
}) {
  const { dispatchBanner, dispatchUpdateBanner } = useHotelDashboard();
  const [error, setError] = useState({
    imageUrl: false,
    imageName: false,
  });
  const [values, setValues] = useState({
    imageUrl: imageDetails?.imageUrl ? imageDetails?.imageUrl : null,
    imageName: imageDetails?.imageName ? imageDetails?.imageName : "",
    order: imageDetails?.order ? imageDetails?.order : null,
    altText: imageDetails?.altText ? imageDetails?.altText : "",
    titleMetaTag: imageDetails?.titleMetaTag ? imageDetails?.titleMetaTag : "",
    tagMetaTag: imageDetails?.tagMetaTag ? imageDetails?.tagMetaTag : "",
    authorMetaTag: imageDetails?.authorMetaTag
      ? imageDetails?.authorMetaTag
      : "",
    copyRightMetaTag: imageDetails?.copyRightMetaTag
      ? imageDetails?.copyRightMetaTag
      : "",
    type: "REGULAR",
    redirectUrl: imageDetails?.redirectUrl ? imageDetails?.redirectUrl : "",
  });

  const validateImageDetails = () => {
    let isError = false;

    if (!values?.imageUrl) {
      setError((prevState) => ({ ...prevState, imageUrl: true }));
      isError = true;
    }

    if (!values?.imageName) {
      setError((prevState) => ({ ...prevState, imageName: true }));
      isError = true;
    }

    return !isError;
  };

  const handleSave = async () => {
    if (!validateImageDetails()) return;
    if (isEdit) {
      const updateBannerImage = {
        ...values,
        id: imageDetails?.id,
        imageUrl: values?.imageUrl,
      };
      await dispatchUpdateBanner({ updateBannerImage });
      updateDataArr(6, updateBannerImage);
      onClose();
    } else {
      const bannerImage = {
        ...values,
        imageUrl: values?.imageUrl,
      };
      await dispatchBanner({ bannerImage });

      updateDataArr(6, bannerImage);

      onClose();
    }
    try {
      const response = await HotelDashboardService.getBannerImages({
        type: "REGULAR",
      });
      setDataArr(response);
    } catch (error) {
      console.error("Error fetching hotel banner:", error);
    }
  };
  const handleImageChange = (event) => {
    const fileList = event.target.files[0];
    if (fileList) {
      const fileType = fileList.type;
      if (fileType !== "image/webp") {
        alert("Please select a webp image.");
        event.target.value = null;
        return;
      }
      setValues((prevState) => ({
        ...prevState,
        imageUrl: fileList,
      }));
    }
  };

  const removeImage = () => {
    setValues((prevState) => ({
      ...prevState,
      imageUrl: null,
    }));
  };

  const handleChange = (event, field) => {
    setValues((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));

    if (event.target.value) {
      setError((prevState) => ({
        ...prevState,
        [field]: false,
      }));
    }
  };

  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging) setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleImageChange({ target: { files: e.dataTransfer.files } });
        e.dataTransfer.clearData();
      }
    };

    const dropArea = document.getElementById("drop-area");

    dropArea.addEventListener("dragover", handleDragOver);
    dropArea.addEventListener("dragleave", handleDragLeave);
    dropArea.addEventListener("drop", handleDrop);

    return () => {
      dropArea.removeEventListener("dragover", handleDragOver);
      dropArea.removeEventListener("dragleave", handleDragLeave);
      dropArea.removeEventListener("drop", handleDrop);
    };
  }, [isDragging, handleImageChange]);

  function checkImgURL(imageUrl) {
    return typeof imageUrl === "string"
      ? imageUrl
      : URL.createObjectURL(imageUrl);
  }
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "712px",
          height: "700px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "80px",
            backgroundColor: "#26529c",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <div
            style={{
              fontSize: "32px",
              fontWeight: "600",
              marginLeft: "20px",
              paddingTop: "12px",
              color: "white",
            }}
          >
            {isEdit ? " Modifica Banner" : " Creare Banner"}
          </div>
          <div
            style={{
              cursor: "pointer",
              marginRight: "20px",
              paddingTop: "20px",
            }}
          >
            <CloseIcon
              fontSize="large"
              onClick={onClose}
              sx={{ color: "white" }}
            />
          </div>
        </div>
        <div style={{ padding: "20px", height: "640px", overflowY: "auto" }}>
          <div
            id="drop-area"
            style={{
              display: "contents",
              overflowX: "auto",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            {values.imageUrl ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <div style={{ width: "24px" }}>
                  <Button
                    onClick={() => removeImage()}
                    sx={{
                      position: "relative",
                      top: "3px",
                      right: "-225px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete"
                      style={{
                        backgroundColor: "white",
                        width: "24px",
                        height: "24px",
                        padding: "4px",
                        borderRadius: "10px",
                      }}
                    />
                  </Button>
                </div>
                <img
                  src={checkImgURL(values.imageUrl)}
                  // src={values.imageUrl}
                  alt={`Image-${values.imageUrl.name}`}
                  style={{
                    width: "250px",
                    height: "147px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "712px",
                    flexDirection: "column",
                  }}
                >
                  <button
                    style={{
                      width: "489px",
                      height: "147px",
                      border: "1px dashed black",
                      borderRadius: "10px",
                      textDecoration: "underline",
                      fontSize: "32px",
                      fontWeight: "600",
                      cursor: "pointer",
                      backgroundColor: isDragging ? "#f0f0f0" : "transparent",
                    }}
                  >
                    <label
                      htmlFor="image-upload"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Add an Image
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => handleImageChange(e)}
                      // onChange={(e) => handleFileChange(e)}
                    />
                  </button>
                  {error.imageUrl && (
                    <div className="error-message">
                      {"Image is required field."}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>Nome File</div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.imageName}
              onChange={(event) => handleChange(event, "imageName")}
              placeholder="Enter name"
            />
            {error.imageName && (
              <div className="error-message">{"Name is required field."}</div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Alt Text (Max 100 Caratteri)
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.altText}
              onChange={(event) => handleChange(event, "altText")}
              placeholder="Alt"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Titolo MetaTag
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.titleMetaTag}
              onChange={(event) => handleChange(event, "titleMetaTag")}
              placeholder="Title"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Tag MetaTag (Divided by Commas)
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.tagMetaTag}
              onChange={(event) => handleChange(event, "tagMetaTag")}
              placeholder="Tag"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Autore MetaTag
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.authorMetaTag}
              onChange={(event) => handleChange(event, "authorMetaTag")}
              placeholder="Author"
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Copyright MetaTag
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.copyRightMetaTag}
              onChange={(event) => handleChange(event, "copyRightMetaTag")}
              placeholder="Copyright"
            />
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Redirect Url
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.redirectUrl}
              onChange={(event) => handleChange(event, "redirectUrl")}
              placeholder="Redirect Url"
            />
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "black",
              marginTop: "6px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: 600 }}>
              Redirect Link
            </div>
            <input
              style={{
                height: "52px",
                backgroundColor: "#dce3f2",
                fontSize: "20px",
                border: "none",
                borderRadius: "5px",
                padding: "4px 10px",
              }}
              type="text"
              value={values.redirectUrl}
              onChange={(event) => handleChange(event, "redirectUrl")}
              placeholder="Url"
            />
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              borderTop: "1px solid #ccc",
            }}
          >
            <Button
              variant="contained"
              sx={{
                height: "53px",
                width: "100%",
                backgroundColor: "#26529C",
                "&:hover": {
                  backgroundColor: "#26529C",
                },
              }}
              onClick={handleSave}
            >
              Salva
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
