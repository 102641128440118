const employeeRightInitialState = {
  id: "",
  name: "",
};

const employeeRoleInitialState = {
  id: "",
  name: "",
  rights: [employeeRightInitialState],
};

const employeeInitalState = {
  id: "",
  name: "",
  surname: "",
  email: "",
  imageUrl: "",
  isActive: false,
  roles: [employeeRoleInitialState],
  createdBy: "",
  createdDate: "",
};

const initialState = {
  employeeDetails: employeeInitalState,
  allEmployees: [],
  loading: false,
  error: null,
  userData: null,
  message: null,
};

const AuthenticationAction = {
  LoginUserRequest: "LoginUserRequest",
  LoginUserSuccess: "LoginUserSuccess",
  LoginUserError: "LoginUserError",
  NewUserRequest: "NewUserRequest",
  NewUserSuccess: "NewUserSuccess",
  NewUserError: "NewUserError",
  ForgotPasswordRequest: "ForgotPasswordRequest",
  ForgotPasswordSuccess: "ForgotPasswordSuccess",
  ForgotPasswordError: "ForgotPasswordError",
  ResetPasswordRequest: "ResetPasswordRequest",
  ResetPasswordSuccess: "ResetPasswordSuccess",
  ResetPasswordError: "ResetPasswordError",
  FetchAllEmployeesRequest: "FetchAllEmployeesRequest",
  FetchAllEmployeesSuccess: "FetchAllEmployeesSuccess",
  FetchAllEmployeesError: "FetchAllEmployeesError",
  FetchEmployeeDetailsRequest: "FetchEmployeeDetailsRequest",
  FetchEmployeeDetailsSuccess: "FetchEmployeeDetailsSuccess",
  FetchEmployeeDetailsError: "FetchEmployeeDetailsError",
  CreateEmployeeRequest: "CreateEmployeeRequest",
  CreateEmployeeSuccess: "CreateEmployeeSuccess",
  CreateEmployeeError: "CreateEmployeeError",
  UpdateEmployeeRequest: "UpdateEmployeeRequest",
  UpdateEmployeeSuccess: "UpdateEmployeeSuccess",
  UpdateEmployeeError: "UpdateEmployeeError",
  DeleteEmployeeRequest: "DeleteEmployeeRequest",
  DeleteEmployeeSuccess: "DeleteEmployeeSuccess",
  DeleteEmployeeError: "DeleteEmployeeError",
  Logout: "Logout",
};

export function LoginUserRequest() {
  return { type: AuthenticationAction.LoginUserRequest };
}

export function LoginUserSuccess(response) {
  return {
    type: AuthenticationAction.LoginUserSuccess,
    payload: response,
  };
}

export function LoginUserError(error) {
  return {
    type: AuthenticationAction.LoginUserError,
    payload: error,
  };
}

export function NewUserRequest() {
  return { type: AuthenticationAction.NewUserRequest };
}

export function NewUserSuccess(response) {
  return {
    type: AuthenticationAction.NewUserSuccess,
    payload: response,
  };
}

export function NewUserError(error) {
  return {
    type: AuthenticationAction.NewUserError,
    payload: error,
  };
}

export function ForgotPasswordRequest() {
  return { type: AuthenticationAction.ForgotPasswordRequest };
}

export function ForgotPasswordSuccess(response) {
  return {
    type: AuthenticationAction.ForgotPasswordSuccess,
    payload: response,
  };
}

export function ForgotPasswordError(error) {
  return {
    type: AuthenticationAction.ForgotPasswordError,
    payload: error,
  };
}

export function ResetPasswordRequest() {
  return { type: AuthenticationAction.ResetPasswordRequest };
}

export function ResetPasswordSuccess(response) {
  return {
    type: AuthenticationAction.ResetPasswordSuccess,
    payload: response,
  };
}

export function ResetPasswordError(error) {
  return {
    type: AuthenticationAction.ResetPasswordError,
    payload: error,
  };
}

export function FetchEmployeeDetailsRequest() {
  return {
    type: AuthenticationAction.FetchEmployeeDetailsRequest,
  };
}

export function FetchEmployeeDetailsSuccess(response) {
  return {
    type: AuthenticationAction.FetchEmployeeDetailsSuccess,
    payload: response,
  };
}

export function FetchEmployeeDetailsError(error) {
  return {
    type: AuthenticationAction.FetchEmployeeDetailsError,
    payload: error,
  };
}

export function FetchAllEmployeesRequest() {
  return {
    type: AuthenticationAction.FetchAllEmployeesRequest,
  };
}

export function FetchAllEmployeesSuccess(response) {
  return {
    type: AuthenticationAction.FetchAllEmployeesSuccess,
    payload: response,
  };
}

export function FetchAllEmployeesError(error) {
  return {
    type: AuthenticationAction.FetchAllEmployeesError,
    payload: error,
  };
}

export function CreateEmployeeRequest() {
  return { type: AuthenticationAction.CreateEmployeeRequest };
}

export function CreateEmployeeSuccess(response) {
  return {
    type: AuthenticationAction.CreateEmployeeSuccess,
    payload: response,
  };
}

export function CreateEmployeeError(error) {
  return {
    type: AuthenticationAction.CreateEmployeeError,
    payload: error,
  };
}
export function UpdateEmployeeRequest() {
  return { type: AuthenticationAction.UpdateEmployeeRequest };
}

export function UpdateEmployeeSuccess(response) {
  return {
    type: AuthenticationAction.UpdateEmployeeSuccess,
    payload: response,
  };
}

export function UpdateEmployeeError(error) {
  return {
    type: AuthenticationAction.UpdateEmployeeError,
    payload: error,
  };
}
export function DeleteEmployeeRequest() {
  return { type: AuthenticationAction.DeleteEmployeeRequest };
}

export function DeleteEmployeeSuccess(response) {
  return {
    type: AuthenticationAction.DeleteEmployeeSuccess,
    payload: response,
  };
}

export function DeleteEmployeeError(error) {
  return {
    type: AuthenticationAction.DeleteEmployeeError,
    payload: error,
  };
}

export function Logout() {
  return { type: AuthenticationAction.Logout };
}

const reducer = (state, action) => {
  switch (action.type) {
    case AuthenticationAction.LoginUserRequest:
    case AuthenticationAction.NewUserRequest:
    case AuthenticationAction.ForgotPasswordRequest:
    case AuthenticationAction.ResetPasswordRequest:
    case AuthenticationAction.FetchAllEmployeesRequest:
    case AuthenticationAction.FetchEmployeeDetailsRequest:
    case AuthenticationAction.CreateEmployeeRequest:
    case AuthenticationAction.UpdateEmployeeRequest:
    case AuthenticationAction.DeleteEmployeeRequest:
      return { ...state, loading: true, error: null };

    case AuthenticationAction.LoginUserSuccess:
      const newState = { ...state, userData: action.payload, loading: false };
      localStorage.setItem("userData", JSON.stringify(action.payload));
      return newState;

    case AuthenticationAction.NewUserSuccess:
      return { ...state, message: action.payload, loading: false };

    case AuthenticationAction.ForgotPasswordSuccess:
      return { ...state, message: action.payload, loading: false };

    case AuthenticationAction.ResetPasswordSuccess:
      return { ...state, message: action.payload, loading: false };

    case AuthenticationAction.FetchAllEmployeesSuccess:
      return { ...state, allEmployees: action.payload, loading: false };

    case AuthenticationAction.FetchEmployeeDetailsSuccess:
      return { ...state, employeeDetails: action.payload, loading: false };

    case AuthenticationAction.CreateEmployeeSuccess:
      return { ...state, message: action.payload, loading: false };

    case AuthenticationAction.UpdateEmployeeSuccess:
      return { ...state, message: action.payload, loading: false };

    case AuthenticationAction.DeleteEmployeeSuccess:
      return { ...state, message: action.payload, loading: false };

    case AuthenticationAction.Logout:
      localStorage.removeItem("userData");
      return { ...state, userData: null };

    case AuthenticationAction.LoginUserError:
    case AuthenticationAction.NewUserError:
    case AuthenticationAction.ForgotPasswordError:
    case AuthenticationAction.ResetPasswordError:
    case AuthenticationAction.FetchAllEmployeesError:
    case AuthenticationAction.FetchEmployeeDetailsError:
    case AuthenticationAction.CreateEmployeeError:
    case AuthenticationAction.UpdateEmployeeError:
    case AuthenticationAction.DeleteEmployeeError:
      return { ...state, error: action.payload, loading: false };

    default:
      throw new Error(
        "Error while processing action: authenticationReducer.reducer"
      );
  }
};

const authenticationReducer = { initialState, reducer };

export default authenticationReducer;

// TODO: Message has to be clear other wise it will repeat
