import { createContext, useContext, useReducer } from "react";
import hotelsDashboardFilterReducer from "../reducers/HotelsDashboarFilterReducer";

export const HotelsDashboardFilterContext = createContext({
    state: {...hotelsDashboardFilterReducer.initialState },
    dispatch: () => {},
});

export const useHotelsDashboardFilterContext = () => useContext(HotelsDashboardFilterContext);

function HotelsDashboardFilterSlicer({children}) {
    const [state, dispatch] = useReducer(
        hotelsDashboardFilterReducer.reducer,
        hotelsDashboardFilterReducer.initialState,
    );

    return (
        <HotelsDashboardFilterContext.Provider value={{state, dispatch}}>
            {children}
        </HotelsDashboardFilterContext.Provider>
    );
}

export default HotelsDashboardFilterSlicer;