import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const minusIcon = (
  <svg viewBox="0 0 14 2" fill="none">
    <path
      d="M1 1H13"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

const plusIcon = (
  <svg viewBox="0 0 14 14" fill="none">
    <path
      d="M1 7H13M7 1V13"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

const cancel = (
  <svg viewBox="0 0 8 8" fill="none">
    <path
      d="M0.219754 0.219754C0.289319 0.15009 0.371936 0.094824 0.462878 0.0571165C0.553821 0.019409 0.651305 0 0.749754 0C0.848204 0 0.945688 0.019409 1.03663 0.0571165C1.12757 0.094824 1.21019 0.15009 1.27975 0.219754L3.99975 2.93875L6.71975 0.219754C6.78936 0.150154 6.87198 0.0949436 6.96292 0.057276C7.05386 0.0196084 7.15132 0.000221254 7.24975 0.000221252C7.34818 0.000221251 7.44565 0.0196084 7.53659 0.057276C7.62753 0.0949436 7.71015 0.150154 7.77975 0.219754C7.84935 0.289355 7.90456 0.371983 7.94223 0.46292C7.9799 0.553858 7.99929 0.651324 7.99929 0.749754C7.99929 0.848185 7.9799 0.945651 7.94223 1.03659C7.90456 1.12753 7.84935 1.21015 7.77975 1.27975L5.06075 3.99975L7.77975 6.71975C7.92032 6.86032 7.99929 7.05097 7.99929 7.24975C7.99929 7.44854 7.92032 7.63919 7.77975 7.77975C7.63919 7.92032 7.44854 7.99929 7.24975 7.99929C7.05097 7.99929 6.86032 7.92032 6.71975 7.77975L3.99975 5.06075L1.27975 7.77975C1.13919 7.92032 0.948543 7.99929 0.749754 7.99929C0.550966 7.99929 0.360319 7.92032 0.219754 7.77975C0.0791897 7.63919 0.000221252 7.44854 0.000221252 7.24975C0.000221252 7.05097 0.0791897 6.86032 0.219754 6.71975L2.93875 3.99975L0.219754 1.27975C0.15009 1.21019 0.094824 1.12757 0.0571165 1.03663C0.019409 0.945688 0 0.848204 0 0.749754C0 0.651305 0.019409 0.553821 0.0571165 0.462878C0.094824 0.371936 0.15009 0.289319 0.219754 0.219754Z"
      fill="currentColor"
    />
  </svg>
);

export default function Room({
  guestForm,
  updateGuestForm,
  active,
  dropdownRef,
}) {
  return (
    // <div
    //   className={"searchItem " + (active ? "active" : "")}
    //   ref={wrapper}
    //   onClick={clickHandler}
    // >
    // <h2>sadfs</h2>
    <AnimatePresence>
      {active === true && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, y: 10 }}
          className={`searchItem__room ` + (active ? "active" : "")}
          ref={dropdownRef}
        >
          <div className="searchItem__room-head">
            <button
              type="button"
              className="searchItem__room-head-reset"
              onClick={() => {
                updateGuestForm({
                  rooms: [
                    {
                      id: "1",
                      adults: 2,
                      children: 0,
                      children_age: [],
                    },
                  ],
                });
              }}
            >
              Reset
            </button>
            <button
              type="button"
              className="searchItem__room-head-add"
              onClick={() => {
                if (guestForm?.rooms?.length + 1 <= 3) {
                  updateGuestForm({
                    rooms: [
                      ...guestForm.rooms,
                      {
                        id: new Date().toISOString(),
                        adults: 2,
                        children: 0,
                        children_age: [],
                      },
                    ],
                  });
                }
              }}
            >
              Add Room
            </button>
          </div>
          <div className="searchItem__room-inner">
            {guestForm?.rooms?.map((item, index) => {
              return (
                <div className="searchItem__room-item" key={index}>
                  <div className="searchItem__room-item-title">
                    Room {index + 1}
                    <button
                      type="button"
                      style={{
                        display:
                          guestForm?.rooms?.length > 1 ? "block" : "none",
                      }}
                      onClick={() => {
                        setTimeout(() => {
                          let arr = [
                            ...guestForm?.rooms.filter(
                              (filterItem) => filterItem?.id !== item?.id
                            ),
                          ];
                          updateGuestForm({
                            rooms: arr,
                          });
                        }, 50);
                      }}
                    >
                      {cancel}
                    </button>
                  </div>
                  <div className="searchItem__room-item-content">
                    <div className="searchItem__room-item-row">
                      <h6>Adults</h6>
                      <div className="searchItem__room-spinbox">
                        <div
                          className={
                            "searchItem__room-spinbox-opr " +
                            (item?.adults - 1 === 0 ? "disabled" : "")
                          }
                          onClick={() => {
                            if (item.adults - 1 !== 0) {
                              let arr = [...guestForm?.rooms];
                              const index = arr
                                ?.map((item2) => item2.id)
                                .indexOf(item.id);
                              arr[index] = {
                                ...arr[index],
                                adults: item?.adults - 1,
                              };
                              updateGuestForm({
                                rooms: arr,
                              });
                            }
                          }}
                        >
                          {minusIcon}
                        </div>
                        <div className="searchItem__room-spinbox-num">
                          {item?.adults}
                        </div>
                        <div
                          className={
                            "searchItem__room-spinbox-opr " +
                            (item?.adults + 1 <= 9 ? "" : "disabled")
                          }
                          onClick={() => {
                            if (item?.adults + 1 <= 9) {
                              let arr = [...guestForm?.rooms];
                              const index = arr
                                ?.map((item2) => item2.id)
                                .indexOf(item.id);
                              arr[index] = {
                                ...arr[index],
                                adults: item?.adults + 1,
                              };
                              updateGuestForm({
                                rooms: arr,
                              });
                            }
                          }}
                        >
                          {plusIcon}
                        </div>
                      </div>
                    </div>
                    <div className="searchItem__room-item-row">
                      <h6>Children</h6>
                      <div className="searchItem__room-spinbox">
                        <div
                          className={
                            "searchItem__room-spinbox-opr " +
                            (item?.children - 1 === -1 ? "disabled" : "")
                          }
                          onClick={() => {
                            if (item.children - 1 !== -1) {
                              let arr = [...guestForm?.rooms];
                              const index = arr
                                ?.map((item2) => item2.id)
                                .indexOf(item.id);
                              let arr2 = arr[index].children_age;
                              arr2.pop();
                              arr[index] = {
                                ...arr[index],
                                children: item?.children - 1,
                                children_age: arr2,
                              };
                              updateGuestForm({
                                rooms: arr,
                              });
                            }
                          }}
                        >
                          {minusIcon}
                        </div>
                        <div className="searchItem__room-spinbox-num">
                          {item?.children}
                        </div>
                        <div
                          className={
                            "searchItem__room-spinbox-opr " +
                            (item.children + 1 <= 3 ? "" : "disabled")
                          }
                          onClick={() => {
                            if (item.children + 1 <= 3) {
                              let arr = [...guestForm?.rooms];
                              const index = arr
                                ?.map((item2) => item2.id)
                                .indexOf(item.id);
                              arr[index] = {
                                ...arr[index],
                                children: item?.children + 1,
                                children_age: [...item.children_age, 0],
                              };
                              updateGuestForm({
                                rooms: arr,
                              });
                            }
                          }}
                        >
                          {plusIcon}
                        </div>
                      </div>
                    </div>
                    {item?.children > 0 && (
                      <div className="searchItem__room-item-age">
                        <p>Age of children on date of travel:</p>
                        <div className="searchItem__room-item-age-items">
                          {item?.children_age.map((item2, index2) => {
                            return (
                              <select
                                key={index2}
                                value={item2}
                                onChange={(e) => {
                                  let arr = [...guestForm?.rooms];
                                  const indexx = arr
                                    ?.map((item2) => item2.id)
                                    .indexOf(item.id);
                                  let arr2 = arr[indexx].children_age;
                                  arr2[index2] = e.target.value;
                                  arr[indexx] = {
                                    ...arr[indexx],
                                    children_age: arr2,
                                  };
                                  updateGuestForm({
                                    rooms: arr,
                                  });
                                }}
                              >
                                {[
                                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                  14, 15, 16, 17,
                                ].map((item3, index3) => {
                                  return (
                                    <option key={index3} value={item3}>
                                      {item3}
                                    </option>
                                  );
                                })}
                              </select>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
    // </div>
  );
}
