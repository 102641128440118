export const initialState = [];

const SettingAction = {
  FetchSetting: "FetchSetting",
};

export function FetchSetting(data) {
  return {
    type: SettingAction.FetchSetting,
    payload: {
      value: data,
    },
  };
}

const reducer = (state, action) => {
  switch (action.type) {
    case SettingAction.FetchSetting:
      const newState = action.payload.value;
      // if (!Array.isArray(newState)) {
      //   return state;
      // }
      return { ...newState };
    default:
      throw new Error("Error while Get settings");
  }
};

const HotelSettingReducer = { initialState, reducer };

export default HotelSettingReducer;
