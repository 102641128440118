import { Box, Button } from "@mui/material";

const TableNavMenu = ({
  navBtnTxt1,
  navBtnTxt2,
  activeButton,
  setActiveButton,
  onSearchTypeChange 
}) => {
  const handleTuttiClick = () => {
    setActiveButton(navBtnTxt1);
    onSearchTypeChange("ALL");
  };

  const handleNuoviClick = () => {
    setActiveButton(navBtnTxt2);
    onSearchTypeChange("NEW");
  };


  return (
    <Box sx={{ display: "flex", marginTop: "50px" }}>
      <Box sx={{ borderBottom: "2px solid #26529c", width: "80px" }}></Box>
      <Box>
        <Button
          variant="outlined"
          sx={{
            height: "63px",
            width: "243px",
            fontWeight: "600",
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "30px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            backgroundColor: activeButton === navBtnTxt1 ? "white" : "#e9eef6",
            borderWidth:
              activeButton === navBtnTxt1
                ? "2px 1px 0px 2px"
                : "2px 1px 2px 2px",
            borderColor: "#26529c",
            "&:hover": {
              borderWidth:
                activeButton === navBtnTxt1
                  ? "2px 1px 0px 2px"
                  : "2px 1px 2px 2px",
              borderColor: "#26529c",
            },
          }}
          onClick={handleTuttiClick}
        >
          {navBtnTxt1}
        </Button>
        <Button
          variant="outlined"
          sx={{
            height: "63px",
            width: "243px",
            fontWeight: "600",
            borderRadius: "0px",
            borderTopRightRadius: "30px",
            borderTopLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderColor: "#26529c",
            backgroundColor: activeButton === navBtnTxt2 ? "white" : "#e9eef6",
            borderWidth:
              activeButton === navBtnTxt2 ? "2px 1px 0px 1px" : "2px 1px",
            borderColor: "#26529c",
            "&:hover": {
              borderWidth:
                activeButton === navBtnTxt2 ? "2px 1px 0px 1px" : "2px 1px",
              borderColor: "#26529c",
            },
          }}
          onClick={handleNuoviClick}
        >
          {navBtnTxt2}
        </Button>
      </Box>

      <Box sx={{ borderBottom: "2px solid #26529c", flex: 1 }}></Box>
    </Box>
  );
};

export default TableNavMenu;
