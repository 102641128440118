import React, { useEffect, useState } from "react";
import calendarIcon from "../../../assets/icon/calendar.svg";
import { formatDateRange } from '../../../utils/formatDate';
import {formatAmount} from '../../../utils/formatAmount';

const RemainingDepositBox = ({ data, info }) => {
  const [unpaidInvoice, setUnpaidInvoice] = useState();
  const [paymentDetails, setPaymentDetails] = useState();

  const getFirstUnpaidInvoice = (paymentDetails) => {
    if (paymentDetails != null && paymentDetails.length > 0) {

      // Select the first object from paymentDetails array
      const paymentDetail = paymentDetails[0];

      if (!paymentDetail) return null; // Return null if no paymentDetail exists

      // Filter invoices where status = 'Unpaid'
      const unpaidInvoices = paymentDetail.invoices.filter(
        (invoice) => invoice.status === "Unpaid"
      );
      if (unpaidInvoices.length === 0) return null; // Return null if no unpaid invoices found

      // Sort the filtered invoices by invoiceDueDateTime
      unpaidInvoices.sort((a, b) =>
        new Date(a.invoiceDueDateTime) - new Date(b.invoiceDueDateTime)
      );
      // Return the first invoice after sorting
      return unpaidInvoices[0];
    }
  };

  useEffect(() => {
    const firstUnpaidInvoice = getFirstUnpaidInvoice(info?.paymentDetails);
    setUnpaidInvoice(firstUnpaidInvoice)
    setPaymentDetails(info?.paymentDetails[0]);
  }, [info?.paymentDetails])



  return (
    <div className="border-[1px] mx-[19px] mt-[10px] border-[#DDDDDD] rounded-[10px] bg-[#FAFAFA] px-[21px] py-[14px]">
      <div className="flex items-center justify-between">
        <p className="font-bold leading-[30px]">Deposito Rimanente</p>
        {/* <button className="w-[170px] h-[37px] bg-[#DFDFDF] text-[#ABABAB] rounded-[60px] font-bold text-[14px]">
          Cancella prenotazione
        </button> */}
      </div>
      <h1 className="leading-[30px] font-bold text-black text-[22px]">
        {
            paymentDetails?.amountPaid
            ? `${formatAmount(paymentDetails?.amountPaid / 100)} su ${formatAmount(paymentDetails?.amountRemaining / 100)}`
            : "Amount not available"
        }
      </h1>
      {unpaidInvoice && (  
      <div className=" h-[49px] w-[350px] px-[17px] text-primary items-center  rounded-[24.5px] bg-primary bg-opacity-[0.17] mt-[10px]">
        <div className="flex items-center justify-between h-full">
          <div className="flex items-center space-x-[10px]">
            <img src={calendarIcon} alt="calender" />
            <div className="">
              <p className="text-[12px] font-bold text-[#8B8B8B]">
                Data Prossimo Pagamento
              </p>
              <p className="-mt-[3px] text-[15px] font-bold">
                {formatDateRange(unpaidInvoice.invoiceDueDateTime)}
              </p>
            </div>
          </div>
          <p className="text-[22px] font-bold">{formatAmount(unpaidInvoice.invoiceAmount)}</p>
        </div>
      </div>
      )}
    </div>
  );
};

export default RemainingDepositBox;
