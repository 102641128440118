import axiosConfig from "../axiosConfig";
class ContactService{

    fetchAllContacts = async(body) => {
        return await axiosConfig.get(`/Dashboard/Hotels/Contacts`, {
            params: body,
          });
        // return await axiosConfig.post(`/Dashboard/Hotels/Contacts/${payload.pageNumber}/${payload.pageSize}`);
    };

    fetchStatistic = async (isContact) => {
          return await axiosConfig.get(`/Dashboard/ContactNegotiationsStatistic/${isContact}`);
    };

    fetchContactNegotiations = async (body) => {
        return await axiosConfig.get(`/Dashboard/Hotels/ContactsNegotiationsSearch`, {
            params: body,
          });
    };

    updateContactNegotiationsAssignedTo = async (body) => {
        return await axiosConfig.post(`/Dashboard/Hotels/UpdateContactsNegotiationsAssignedTo`,  body);
      };
      onRequestSendEmail = async (body) => {
        return await axiosConfig.post(`/Dashboard/OnRequest/EmailSend`, body);
      };
}

const instance = new ContactService();

export default instance;