import React from "react";
import ActionButtons from "./ActionButtons";

const LeftSideContactInformation = ({ info }) => {
  return (
    <div>
      <div className="mt-[25px] px-[38px]">
        <h2 className="text-[24px] text-primary font-semibold leading-[20px]">
          {info?.firstName}
        </h2>
        <h3 className="text-[14px] mt-[16px] text-primary font-normal leading-[20px]">
          {info?.phoneNumber}
        </h3>
        <p className="text-[16px] text-primary mt-[5px] font-normal leading-[20px]">
          {info?.email}
        </p>
      </div>
      <ActionButtons />
    </div>
  );
};

export default LeftSideContactInformation;
