import PrimaryButton from "Components/Button/PrimaryButton";
import React, { useState } from "react";
import moreIcon from "assets/icons/more.svg";
import UserModal from "./UserModal";
import { Avatar } from "@mui/material";

export const Users = ({ isLoading, employees }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const textAvatar = (name, surname) => {
    if (name && surname) {
      return `${name[0]}${surname[0]}`;
    } else {
      return "";
    }
  };

  return (
    <div className="w-full bg-white shadow-lg rounded-[20px] pt-[17px] h-[500px]">
      <div className="flex items-center pl-[24px] justify-between pr-[23px]">
        <h2 className="text-[20px] text-[#171B1E] font-bold">Utenti</h2>
        <PrimaryButton
          buttonText="Nuovo Utente"
          height="50px"
          width="165px"
          borderRadius="100px"
          onClick={() => setOpenDialog(true)}
        />
      </div>

      <div className="mt-[21px] h-[412px] overflow-y-auto">
        {employees && employees?.length > 0 ? (
          employees.map((employee) => (
            <div
              key={employee.id}
              className="flex px-[22px] h-[94px] border-b-[1px] border-b-[#C4C4C4]  items-center justify-between"
            >
              <div className="flex items-center space-x-[23.77px]">
                {employee?.imageUrl ? (
                  <img
                    className="w-[50px] rounded-full h-[50px] object-cover"
                    src={employee.imageUrl}
                    alt="profile_pic"
                  />
                ) : (
                  <Avatar
                    sx={{
                      width: "50px",
                      height: "50px",
                      bgcolor: "#CBD329",
                      fontSize: "18px",
                    }}
                  >
                    {textAvatar(employee?.name, employee.surname)}
                  </Avatar>
                )}
                <div>
                  <h3 className="text-[18px] font-semibold text-[#171B1E]">
                    {employee.name} {employee.surname}
                  </h3>
                  <p className="text-[14px] font-normal text-[#171B1E] mt-[7px]">
                    {employee.email}
                  </p>
                </div>
              </div>
              <img
                onClick={() => {
                  setSelectedUser(employee);
                  setOpenDialog(true);
                  setEditMode(true);
                }}
                className="w-[24px] h-[24px] cursor-pointer"
                src={moreIcon}
                alt="moreIcon"
              />
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center h-full">
            <h2 className="text-xl text-gray-600 font-medium">
              No Employee Found!
            </h2>
          </div>
        )}
      </div>

      {/* Popup  */}
      <UserModal
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        editMode={editMode}
        setEditMode={setEditMode}
        userData={selectedUser}
        setUserData={setSelectedUser}
      />
    </div>
  );
};
