import React from "react";
import { Box, Autocomplete, TextField, Chip } from "@mui/material";

export default function DropdownTags({ tags, structureTags, setTags, value }) {
  const handleTagsChange = (event, newValue) => {
    const newTags = newValue.map((selectedTag) =>
      structureTags.find((tag) => tag.displayName == selectedTag)
    );
    setTags(newTags);
  };

  // Extract display names from tags objects for filteredOptions
  const filteredOptions =
    structureTags?.map((option) => option.displayName) ?? [];

  return (
    <Box>
      <Autocomplete
        multiple
        value={tags.map((tag) => tag.displayName)}
        onChange={handleTagsChange}
        options={filteredOptions}
        getOptionLabel={(option) => option}
        renderTags={(value, getTagProps) =>
          value?.map((tagId, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={tagId}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            placeholder="Seleziona i servizi della struttura"
          />
        )}
      />
    </Box>
  );
}
