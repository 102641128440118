import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Layout from "../../Components/Layout/Layout";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Table from "../../Components/TableApp/TableApp";
import useNegotiationsSetting from "../../library/hooks/Negotiations/Negotiation";
import { useNegotiationsContext } from "../../library/store/slices/NegotiationsSlicer";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";
import StatisticsBox from "../../Components/StatisticsBox/StatisticsBox";
import DashboardHeader from "../../Components/Header/DashboardHeader";
import TableNavMenu from "../../Components/Table/TableNavMenu";
import useContactSetting from "../../library/hooks/Contact/Contact";
import { useContactContext } from "../../library/store/slices/ContactSlicer";
import { AgGridReact } from "ag-grid-react";
import logo from "../../assets/images/logo.png";
import TableApp from "../../Components/TableApp/TableApp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@mui/styles";
import CustomFilter from "../../Components/CustomFilter/CustomFilter";
import Loading from "Components/Loading/Loading";
import {formatAmount} from "../../utils/formatAmount";
import SettingService from "../../library/services/API/setting";


const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover": {
      color: "#26529C",
    },
  },
  icon: {
    fill: "#26529C",
  },
  menuItem: {
    color: "#26529C",
    "&:hover": {
      backgroundColor: "#e3e9f2",
    },
  },
}));

export default function NegotiationPage() {
  const classes = useStyles();
  const { callFetchAllNegotiationsApi } = useNegotiationsSetting();

  const { state: negotiationResponse } = useNegotiationsContext();

  const { dispatchForStatisticParamByText } = useContactSetting();
  const { state: contactResponse } = useContactContext();

  const [activeButton, setActiveButton] = useState("Tutte le Negoziazioni");

  const [reqParams, setReqParams] = useState();
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [negotiationRows, setNegotiationRows] = useState([]);

  const [totalNegotiation, setTotalNegotiation] = useState(0);
  const [isLoading ,setIsLoading] = useState(false);
  const [newTotal, setNewTotal] = useState(0);
  const [revenueQuote, setRevenueQuote] = useState(0);
  const [revenueQuoteContact, setRevenueQuoteContact] = useState(0);
  const [negotiationDetail, setNegotiationDetail] = useState("negotiation");
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [searchType, setSearchType] = useState("ALL");
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortedColumn,setSortedColumn] = useState("");
  const [sortedValue,setSortedValue] = useState("");
  const [negotiationConfiguration,setNegotiationConfiguration] = useState(null);
  const [userDataLocal,setUserDataLocal] = useState(null);

  const [pageSize] = useState(10);
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  const hideColumns = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "numberOfBookingsDone",
    "numberOfQuotesRequested",
    "sourceFirstTouchpoint",
    "fidelityStatus",
    "mostFrequentTag",
    "mostFrequentNumberOfStars",
    "lastAccess",
    "lastInteractionWithSupportTeam",
    "averageSessionTime",
    "status"
  ];

  const handleColumnVisibilityChange = (event) => {
    const columnName = event.target.value;
    const isChecked = event.target.checked;

    setColumnVisibility({
      ...columnVisibility,
      [columnName]: isChecked,
    });
  };

  const [columnVisibility, setColumnVisibility] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    numberOfBookingsDone: false,
    numberOfQuotesRequested: false,
    sourceFirstTouchpoint: false,
    fidelityStatus: false,
    totalPrice : false,
    pricePaid :false,
    typeOfPayment :false,
    nextPayment: false,
    fiscalCode: false,
    negotiationId: false,
    status : false
  });

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 100
    },
    {
      field: "numberOfBookingsDone",
      headerName: "Number Of Bookings Done",
      minWidth: 50,
      filter: "agNumberColumnFilter",
    },
    {
      field: "numberOfQuotesRequested",
      headerName: "Number Of Quotes Requested",
      minWidth: 100,
      filter: "agNumberColumnFilter",
    },
    {
      field: "sourceFirstTouchpoint",
      headerName: "Source First Touchpoint",
      minWidth: 50
    },
    {
      field: "fidelityStatus",
      headerName: "Fidelity Status",
      minWidth: 150
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      minWidth: 150,
      valueFormatter: (params) => {
        return formatAmount(params.value / 100)
        //return params.value / 100
      },
    },
    {
      field: "pricePaid",
      headerName: "Price Paid",
      minWidth: 150,
      valueFormatter: (params) => {
        return formatAmount(params.value / 100)
        //return params.value / 100
      },
    },
    {
      field: "typeOfPayment",
      headerName: "Type of Payment",
      minWidth: 150
    },
    {
      field: "nextPayment",
      headerName: "Next Payment",
      minWidth: 150
    },
    {
      field: "fiscalCode",
      headerName: "Fiscal Code",
      minWidth: 150
    },
    {
      field: "negotiationId",
      headerName: "Negotiation ID",
      minWidth: 150
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 100,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), " dd MMM yyyy HH:mm:ss")
          : "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      cellClassRules: "status-confirmed",
      cellClassRules: {
        "status-confirmed": (params) => params.value === "Confermata su Aves",
        "status-waiting": (params) => params.value === "In Lavorazione su Aves",
        "status-cancelled": (params) => params.value === "Cancellata",
        "status-quote" : (params) => params.value === "Richiesta Preventivo",
        "status-cancelled":(params)=> params.value === "No Status",
      },
      valueGetter: (params) => {
        console.log(params);
        switch (params.data.status) {
          case "Confirmed and Paid":
            return "Confermata su Aves";
          case "Waiting for Payment":
            return "In Lavorazione su Aves";
          case "Cancelled":
            return "Cancellata";
          case "Quote Requested":
            return "Richiesta Preventivo";
          default:
            return "No Status" ; // Return the original status text for other statuses
        }
      },
    }
  ];
  const negotiationStatisticsData = [
    {
      title: "TOTALE NEGOZIAZIONI",
      number: totalNegotiation,
      subtitle: "Tutti i Contatti",
    },
    {
      title: "NUOVE NEGOZIAZIONI",
      number: newTotal,
      subtitle: "Ultimi 7 giorni",
    },
    {
      title: "TOTALE RICHIESTO €",
      number: revenueQuote,
      subtitle: "Ultimi 7 giorni",
    },
    {
      title: "TOTALE CONFERMATO",
      number: revenueQuoteContact,
      subtitle: "Ultimi 7 giorni",
    },
  ];

  useEffect(() => {
    dispatchForStatisticParamByText(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const body = { skip, take ,searchType, sortedColumn, sortedValue };
    callFetchAllNegotiationsApi(body);
  }, [skip, take,searchType]);

  

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  // const formatAmount = (amount) => {
  //   const formattedAmount = new Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: "EUR",
  //     currencyDisplay: "code",
  //   }).format(amount);
  //   return formattedAmount.replace("EUR", "").trim() + "€";
  // };

  useMemo(() => {
    if (contactResponse.contactNegotiationStatisticRS != null) {
     
      setTotalNegotiation(contactResponse.contactNegotiationStatisticRS.total);
      setNewTotal(contactResponse.contactNegotiationStatisticRS.newTotal);
      setRevenueQuote(
        formatAmount(
          contactResponse.contactNegotiationStatisticRS.totalRevenueQuote / 100
        )
      );
      setRevenueQuoteContact(
        formatAmount(
          contactResponse.contactNegotiationStatisticRS.totalRevenueQuoteContact / 100
        )
      );
    }
  }, [contactResponse]);


  useMemo(() => {
      setNegotiationRows(negotiationResponse.negotiationsRS);
      setTotalRecords(negotiationResponse.totalData);
      reqParams?.successCallback(
        negotiationResponse.negotiationsRS,
        totalNegotiation
      );
      if (gridApi.current != null) {
        gridApi.current.hideOverlay();
      }
      setIsLoading(false);
  }, [negotiationResponse]);

  const onPageChange = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    const newSkip = (pageNumber - 1) * take;
    setSkip(newSkip);
  };

  const handleSort = (sortedColumn, sortedValue) => {
    setSortedColumn(sortedColumn);
    setSortedValue(sortedValue);
    const body = { skip, take, searchType, sortedColumn, sortedValue };
    setIsLoading(true);
    callFetchAllNegotiationsApi(body);
  };

  const GetNegotiationConfiguration = async (userData) => {
    try {
      const response = await SettingService.GetNegotiationConfiguration(userData?.data?.id);
      setNegotiationConfiguration(response);
      return response;
    } catch (error) {
      console.error("Error fetching Negotiation configuration:", error);
    }
  };


  const saveNegotiationConfiguration = async (body) => {
    try {
      const response = await SettingService.saveNegotiationConfiguration(body);
    } catch (error) {
      console.error("Error fetching Negotiation configuration:", error);
    }
  };

  const onGridReadyOut = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    //handleGridReady();
  };

  const handleGridReady = (savedColumnState) => {
    if (columnApi.current) {
      if (savedColumnState) {
        try {
          const parsedState = savedColumnState;
          columnApi.current.applyColumnState({
            state: parsedState,
            applyOrder: true,
          });
        } catch (error) {
          console.error("Failed to apply column state:", error);
        }
      }
    }
  };

  const onColumnStateChangedOut = () => {
    if (columnApi.current) {
      const columnState = columnApi.current.getColumnState();
      if(userDataLocal){
          let body = {
            email : userDataLocal.data.email,
            user_id : userDataLocal.data.id,
            type :'NEGOTIATION',
            configurations : columnState
          }
          saveNegotiationConfiguration(body);
      }
      //localStorage.setItem('columnState' + 'Contact', JSON.stringify(columnState));
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    let userData = JSON.parse(storedUserData);
    setUserDataLocal(userData);
    GetNegotiationConfiguration(userData);
  }, []);

  useEffect(() => {
    if(negotiationConfiguration){
      if(negotiationConfiguration.configurations)
      handleGridReady(negotiationConfiguration.configurations);
    }
  }, [negotiationConfiguration,columns]);


  const toPage = skip + take;
  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Loading isLoading={isLoading} />
      <Box sx={{ marginLeft: "50px", marginRight: "50px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}></Box>

        <Box>
          <DashboardHeader
            title="Negoziazioni"
            subtitle={totalNegotiation + " Negoziazioni"}
            isExportButton={true}
          />
          <div className="box-level flex items-center mt-[28px] space-x-[48px]">
            {negotiationStatisticsData.map((item, ind) => (
              <StatisticsBox item={item} key={ind} ind={ind} />
            ))}
          </div>
          <TableNavMenu
            navBtnTxt1="Tutte le Negoziazioni"
            navBtnTxt2="Nuove Negoziazioni"
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            onSearchTypeChange={(type) => {
              setIsLoading(true);
              setSearchType(type); // set the search type state
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "26px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <CustomFilter
              hideColumns={hideColumns}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              columnVisibility={columnVisibility}
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            marginBottom: "50px",
            minWidth: "100%",
            overflowX: "auto",
          }}
        >
          <div
            className="ag-theme-quartz" // applying the grid theme
            //style={{ height: 500 }} // the grid will fill the size of the parent container
          >
            {/* <AgGridReact
              columnDefs={columns}
              rowModelType="infinite"
              cacheBlockSize={10}
              paginationPageSize={10}
              pagination={true}
              onGridReady={onGridReady}
              overlayLoadingTemplate='<span class="ag-overlay-loading-center">Loading...</span>'
              overlayNoRowsTemplate='<span class="ag-overlay-loading-center">No data to display</span>'
            /> */}

            <TableApp
              rows={negotiationRows}
              columns={columns}
              take={take}
              skip={skip}
              contactDetail={negotiationDetail}
              setSkip={setSkip}
              setTake={setTake}
              currentPageNumber={negotiationResponse?.currentPageNumber}
              recordsPerPage={negotiationResponse?.recordsPerPage}
              totalPages={negotiationResponse?.totalPages}
              totalRecords={negotiationResponse?.totalRecords}
              handleSort={handleSort}
              type={"Negotiation"}
              onGridReady={onGridReadyOut}
              onColumnStateChanged={onColumnStateChangedOut}
            />
            <div className="pagination_menu">
              <div className="page_size_part">
                <span style={{ fontSize: "14px" }}>Page Size:</span>
                <Select
                  value={take}
                  onChange={(e) => {
                    setTake(e.target.value);
                    setCurrentPageNumber(1);
                    setSkip(0);
                  }}
                  inputProps={{
                    "aria-label": "Select Assegnatario Contatti",
                  }}
                  classes={{ select: classes.select, icon: classes.icon }}
                  sx={{
                    marginLeft: "10px",
                    height: "25px",
                    width: "70px",
                    "&.MuiSelect-select .makeStyles-select-16 .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input ":
                      { padding: "10px" },
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </div>
              <div className="pagination_count pagination_font">
                <span style={{ fontSize: "14px" }}>
                  <span style={{ fontWeight: "600" }}>{skip + 1}</span>
                  {` to `}
                  <span style={{ fontWeight: "600" }}>
                    {toPage > totalRecords ? totalRecords : toPage}
                  </span>
                  {` of `}
                  <span style={{ fontWeight: "600" }}>{totalRecords}</span>
                  {` `}
                </span>
              </div>
              <div className="pagination_count pagination_font">
                <button
                  disabled={currentPageNumber === 1}
                  onClick={() => onPageChange(currentPageNumber - 1)}
                >
                  <ChevronLeftIcon />
                </button>
                <span style={{ fontSize: "14px" }}>
                  {` Page `}
                  <span style={{ fontWeight: "600" }}>{currentPageNumber}</span>
                  {` of `}
                  <span style={{ fontWeight: "600" }}>
                    {Math.ceil(totalRecords / take)}
                  </span>
                  {` `}
                </span>
                <button
                  disabled={
                    currentPageNumber === Math.ceil(totalRecords / take)
                  }
                  onClick={() => onPageChange(currentPageNumber + 1)}
                >
                  <ChevronRightIcon />
                </button>
              </div>
            </div>
          </div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <img className="-mr-[28px]" src={logo} alt="logo" />
        </Box>
      </Box>
    </Layout>
  );
}
