import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";

import PrimaryButton from "Components/Button/PrimaryButton";
import AuthCard from "Components/Card/AuthCard";
import { Link } from "react-router-dom";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const { dispatchForgotPassword } = useAuthentication();
  const { state: userState } = useAuthenticationContext();

  const handleForgot = async () => {
    let hasError = false;
    if (!email) {
      setEmailError("Please enter your email");
      hasError = true;
    }

    if (!emailError && !hasError) {
      try {
        await dispatchForgotPassword(email);
      } catch (error) {
        console.log("Error while sending email: ", error);
      }
    }
  };
  return (
    <div className="bg-white w-screen h-full min-h-screen grid grid-cols-2 p-[21px]">
      <div>
        <Link to={"/login"}>
          <p className="text-[12px] cursor-pointer font-light text-[#4D4D4D] leading-[18px]">
            Go back to Login
          </p>
        </Link>
        <div className="grid place-items-center h-full">
          <div>
            <h1 className="text-[30px] font-medium left-[45px] text-black mb-[39px]">
              Forgot Password
            </h1>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                width: 400,
                margin: "auto",
                mt: 5,
              }}
            >
              <TextField
                required
                value={email}
                onChange={handleEmailChange}
                error={Boolean(emailError)}
                onBlur={handleEmailChange}
                helperText={emailError}
                label="Eamil"
                placeholder="Enter your email address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon width={17} height={17} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: { color: "#26529C", fontSize: 16, fontWeight: 400 },
                  sx: {
                    "&::placeholder": {
                      opacity: 1,
                      color: "#26529C",
                    },
                  },
                }}
                fullWidth
                variant="standard"
              />
            </Box>
            <div className="mt-[58px]" />
            <PrimaryButton
              onClick={handleForgot}
              buttonText={"Send Recovery Link"}
              borderRadius="32px"
              height="53px"
              isLoading={userState.loading}
            />
          </div>
        </div>
      </div>
      <AuthCard
        title="Forgot Password"
        subtitle="Insert the email in order to receive the link"
      />
    </div>
  );
};

export default ForgotPassword;
