export const PERMISSIONS = {
  ADMIN: "ADMIN",
  STATISTICS: "STATISTICS",
  CONTACTS_NEGOTIATIONS: "CONTACTS_NEGOTIATIONS",
  HOESCAPE: "HOESCAPE",
  HOTEL: "HOTEL",
  CUSTOM: "CUSTOM",
  CREATE_ADMIN_USERS: "CREATE_ADMIN_USERS",
  CREATE_STATISTA_USERS: "CREATE_STATISTA_USERS",
  CREATE_CONTACT_NEGOTIATION_USERS: "CREATE_CONTACT_NEGOTIATION_USERS",
  CREATE_HOESCAPE_USERS: "CREATE_HOESCAPE_USERS",
  CREATE_HOTEL_USERS: "CREATE_HOTEL_USERS",
  CREATE_CUSTOM_USERS: "CREATE_CUSTOM_USERS",
  VIEW_HOESCAPE_STATS: "VIEW_HOESCAPE_STATS",
  VIEW_HOTEL_STATS: "VIEW_HOTEL_STATS",
  VIEW_WP_STATS: "VIEW_WP_STATS",
  VIEW_CONTACT_NEGOTIATION_STATS: "VIEW_CONTACT_NEGOTIATION_STATS",
  EDIT_CONTACT_NEGOTIATION: "EDIT_CONTACT_NEGOTIATION",
  EDIT_WP_PLUGINS: "EDIT_WP_PLUGINS",
  EDIT_HOTEL: "EDIT_HOTEL",
  EDIT_HOESCAPE: "EDIT_HOESCAPE",
};

export const hasPermission = (requiredPermissions, userState) => {
  const userRoles =
    userState?.userData?.data?.roles?.flat().map((role) => role.name) || [];
  const userRights =
    userState?.userData?.data?.roles?.flat().flatMap((role) => role.rights) ||
    [];

  return (
    requiredPermissions.length === 0 ||
    requiredPermissions?.some((permission) => {
      return (
        userRoles.includes(permission) ||
        userRights.some((right) => right.name === permission)
      );
    })
  );
};
