import hotelDashboardConfigurationReducer from './HotelDashboardConfigurationReducer'

const initialState = [
    hotelDashboardConfigurationReducer.initialState,
];

const FetchHotelDashboardAction = {
    FetchAllHotels: "FetchAllHotels",
}

export function FetchAllHotels(response) {
    return {
        type:FetchHotelDashboardAction.FetchAllHotels,
        payload: {
            value: response
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case FetchHotelDashboardAction.FetchAllHotels:
            const newState = action.payload.value;
            return newState;
            default:
                throw new Error('Error while updating Hotel Dashboard Request: HotelDashboardReducer.reducer');    
    }
}


const hotelsDashboardReducer = { initialState, reducer }

export default hotelsDashboardReducer;