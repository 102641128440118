import React, { useEffect, useState } from "react";
import { notifications } from "../dummy/dummyData";

export const Notification = () => {
  const groupNotificationsByDate = (notifications) => {
    return notifications.reduce((acc, notification) => {
      if (!acc[notification.date]) {
        acc[notification.date] = [];
      }
      acc[notification.date].push(notification);
      return acc;
    }, {});
  };

  const [notificationGroups, setNotificationGroups] = useState(
    groupNotificationsByDate(notifications)
  );

  return (
    <div className="mt-[18px] rounded-[20px] h-[526px] overflow-y-auto bg-white shadow-lg  pb-[27px] ">
      <div className="h-[12px] bg-white z-10 sticky top-0" />
      {Object.entries(notificationGroups).map(([date, notifications]) => (
        <div key={date}>
          {/* Header  */}
          <div className="flex items-center h-[70px] px-[33px] space-x-[28px] sticky top-[12px] z-10 bg-white">
            <h2 className="text-[20px] font-bold text-[#171B1E]">{date}</h2>
            <div className="border-[1px] flex-1 border-[#D7D7D7] border-dashed" />
          </div>
          {/* notification list  */}
          {notifications.map((notification, ind) => (
            <div
              key={notification.id}
              className={`flex py-[12px] hover:bg-gray-100 px-[33px] space-x-[87px] transition-all duration-200 items-center ${
                ind === 0 ? "" : "mt-[16px]"
              } justify-between`}
            >
              <div className="flex items-center space-x-[35px]">
                <img
                  className="w-[51px] h-[51px] rounded-full"
                  src={notification.icon}
                  alt="icon"
                />
                <div>
                  <h2 className="text-[18px] font-medium line-clamp-1 text-[#171B1E]">
                    {notification.title}{" "}
                    <strong> {notification.highlightedContent}</strong>
                  </h2>
                  <p className="text-[16px] font-normal text-[#717579] mt-[8px]">
                    {notification.time}
                  </p>
                </div>
              </div>
              <button className="h-[50px] hover:bg-primary transition-all duration-300 hover:text-white w-[169px] rounded-full border-[1px] border-[#26529C] text-[#26529C] text-[18px] font-medium">
                Vedi Menzione
              </button>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
// import React, { useState, useEffect, useRef } from "react";
// import { notifications } from "../dummy/dummyData";

// export const Notification = () => {
//   const groupNotificationsByDate = (notifications) => {
//     return notifications.reduce((acc, notification) => {
//       if (!acc[notification.date]) {
//         acc[notification.date] = [];
//       }
//       acc[notification.date].push(notification);
//       return acc;
//     }, {});
//   };

//   const [notificationGroups, setNotificationGroups] = useState(
//     groupNotificationsByDate(notifications)
//   );

//   // Ref for the header element
//   const headerRef = useRef(null);

//   // Ref for the notification container
//   const containerRef = useRef(null);

//   // Track the currently visible group title
//   const [currentGroupTitle, setCurrentGroupTitle] = useState("");

//   useEffect(() => {
//     const observerOptions = {
//       root: containerRef.current,
//       rootMargin: `-${headerRef.current.clientHeight}px 0px 0px 0px`,
//       threshold: 0,
//     };

//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           setCurrentGroupTitle(entry.target.getAttribute("data-date"));
//         }
//       });
//     }, observerOptions);

//     const groupElements = document.querySelectorAll("[data-date]");
//     groupElements.forEach((element) => {
//       observer.observe(element);
//     });

//     return () => {
//       observer.disconnect();
//     };
//   }, [notificationGroups]);

//   return (
//     <div
//       className="mt-[18px] bg-white shadow-lg rounded-[20px] py-[27px] sticky top-0 z-[10]"
//       ref={containerRef}
//     >
//       {/* Sticky header */}
//       <div className="px-[33px]">
//         <h2 className="text-[20px] font-bold text-[#171B1E]" ref={headerRef}>
//           {currentGroupTitle}
//         </h2>
//         <div className="border-[1px] border-[#D7D7D7] border-dashed my-[15px]" />
//       </div>
//       {/* Notification list */}
//       <div className="overflow-y-auto max-h-[526px]">
//         {Object.entries(notificationGroups).map(([date, notifications]) => (
//           <div key={date} data-date={date}>
//             {notifications.map((notification) => (
//               <div
//                 key={notification.id}
//                 className="px-[33px] py-[12px] hover:bg-gray-100 flex items-center justify-between transition-all duration-200"
//               >
//                 <div className="flex items-center space-x-[20px]">
//                   <img
//                     className="w-[51px] h-[51px] rounded-full"
//                     src={notification.icon}
//                     alt="icon"
//                   />
//                   <div>
//                     <h2 className="text-[18px] font-medium line-clamp-1 text-[#171B1E]">
//                       {notification.text}
//                     </h2>
//                     <p className="text-[16px] font-normal text-[#717579] mt-[8px]">
//                       {notification.time}
//                     </p>
//                   </div>
//                 </div>
//                 <button className="h-[50px] hover:bg-primary transition-all duration-300 hover:text-white w-[169px] rounded-full border-[1px] border-[#26529C] text-[#26529C] text-[18px] font-medium">
//                   Vedi Menzione
//                 </button>
//               </div>
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
