import axiosConfig from "../axiosConfig";
class IdealVacationService {
  fetchAllIdealVacations = async () => {
    return await axiosConfig.get(`/IdealVacation/getAll`);
  };

  fetchIdealVacationById = async (idealVacationId) => {
    if (idealVacationId) {
      return await axiosConfig.get(`/IdealVacation/get/${idealVacationId}`);
    }
    console.warn(
      "idealVacationId not specified, IdealVacationService.fetchIdealVacationById"
    );

    return null;
  };

  createIdealVacation = async (body, file) => {
    if (body) {
      const formData = new FormData();

      const appendFormData = (prefix, obj) => {
        Object.keys(obj).forEach((key) => {
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
              appendFormData(`${newKey}[${index}]`, item);
            });
          } else {
            formData.append(newKey, obj[key]);
          }
        });
      };

      appendFormData("", body);

      // attach file
      if (file) {
        formData.append("imageFile", file);
      }

      // Make the request with FormData
      return await axiosConfig.post(`/IdealVacation/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    console.warn(
      "body not specified, IdealVacationService.createIdealVacation"
    );

    return null;
  };

  updateIdealVacation = async (body, file) => {
    if (body) {
      const formData = new FormData();

      const appendFormData = (prefix, obj) => {
        Object.keys(obj).forEach((key) => {
          const newKey = prefix ? `${prefix}.${key}` : key;
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
              appendFormData(`${newKey}[${index}]`, item);
            });
          } else {
            formData.append(newKey, obj[key]);
          }
        });
      };

      appendFormData("", body);

      // attach file
      if (file) {
        formData.append("imageFile", file);
      }

      // Make the request with FormData
      return await axiosConfig.post(`/IdealVacation/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    console.warn(
      "body not specified, IdealVacationService.updateIdealVacation"
    );

    return null;
  };

  deleteIdealVacationById = async (idealVacationId) => {
    if (idealVacationId) {
      return await axiosConfig.delete(
        `/IdealVacation/delete/${idealVacationId}`
      );
    }
    console.warn(
      "idealVacationId not specified, IdealVacationService.deleteIdealVacationById"
    );

    return null;
  };
}

const instance = new IdealVacationService();

export default instance;
