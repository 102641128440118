import { useCallback } from "react";
import AuthenticationService from "../../services/API/authenticationService";
import {
  CreateEmployeeError,
  CreateEmployeeRequest,
  CreateEmployeeSuccess,
  DeleteEmployeeError,
  DeleteEmployeeRequest,
  DeleteEmployeeSuccess,
  FetchAllEmployeesError,
  FetchAllEmployeesRequest,
  FetchAllEmployeesSuccess,
  FetchEmployeeDetailsError,
  FetchEmployeeDetailsRequest,
  FetchEmployeeDetailsSuccess,
  ForgotPasswordError,
  ForgotPasswordRequest,
  ForgotPasswordSuccess,
  LoginUserError,
  LoginUserRequest,
  LoginUserSuccess,
  NewUserError,
  NewUserRequest,
  NewUserSuccess,
  ResetPasswordError,
  ResetPasswordRequest,
  ResetPasswordSuccess,
  UpdateEmployeeError,
  UpdateEmployeeRequest,
  UpdateEmployeeSuccess,
} from "../../store/reducers/AuthenticationReducer";
import { useAuthenticationContext } from "../../store/slices/AuthenticationSlicer";
import { toast } from "react-toastify";

const useAuthentication = () => {
  const { dispatch } = useAuthenticationContext();

  const callLoginUser = useCallback(
    async (credentials) => {
      dispatch(LoginUserRequest());

      try {
        const response = await AuthenticationService.loginUser(credentials);
        dispatch(LoginUserSuccess(response));
        if (!response?.isPasswordChanged) {
          toast.success(
            "Accesso effettuato con successo. Si prega di cambiare la password per continuare."
          );
        } else {
          toast.success("Accesso effettuato con successo!");
        }
        return response;
      } catch (error) {
        dispatch(LoginUserError(error.message));
        toast.error(`Errore di accesso: ${error.message}`);
        throw error.message;
      }
    },
    [dispatch]
  );

  const callNewUser = useCallback(
    async (credentials, accessToken) => {
      dispatch(NewUserRequest());

      try {
        const response = await AuthenticationService.newUser(
          credentials,
          accessToken
        );
        dispatch(NewUserSuccess(response));

        toast.success("Password cambiata con successo!");

        return response;
      } catch (error) {
        dispatch(NewUserError(error.message));
        toast.error(`Errore nel cambio della password: ${error.message}`);
        throw error.message;
      }
    },
    [dispatch]
  );

  const callForgotPassword = useCallback(
    async (email) => {
      dispatch(ForgotPasswordRequest());

      try {
        const response = await AuthenticationService.forgotPassword(email);
        dispatch(ForgotPasswordSuccess(response));
        toast.success("Email inviata con successo!");
        return response;
      } catch (error) {
        dispatch(ForgotPasswordError(error.message));
        toast.error(`Errore nel recupero della password: ${error.message}`);
        throw error.message;
      }
    },
    [dispatch]
  );

  const callResetPassword = useCallback(
    async (credentials) => {
      dispatch(ResetPasswordRequest());

      try {
        const response = await AuthenticationService.resetPassword(credentials);
        dispatch(ResetPasswordSuccess(response));

        toast.success("Reset della password avvenuto con successo!");

        return response;
      } catch (error) {
        dispatch(ResetPasswordError(error.message));
        toast.error(`Errore nel reset della password: ${error.message}`);
        throw error.message;
      }
    },
    [dispatch]
  );

  const callFetchAllEmployeeApi = useCallback(async () => {
    dispatch(FetchAllEmployeesRequest());
    try {
      const response = await AuthenticationService.fetchAllEmployees();
      dispatch(FetchAllEmployeesSuccess(response?.data));
    } catch (error) {
      dispatch(FetchAllEmployeesError(error.message));
      toast.error(
        `Errore nel recupero di tutti i dipendenti: ${error.message}`
      );
    }
  }, [dispatch]);

  const callFetchEmployeeDetailsApi = useCallback(
    async (employeeId) => {
      dispatch(FetchEmployeeDetailsRequest());
      try {
        const response = await AuthenticationService.fetchEmployeeById(
          employeeId
        );
        dispatch(FetchEmployeeDetailsSuccess(response));
      } catch (error) {
        dispatch(FetchEmployeeDetailsError(error.message));
        toast.error(
          `Errore nel recupero dei dettagli del dipendente: ${error.message}`
        );
      }
    },
    [dispatch]
  );

  const callCreateEmployee = useCallback(
    async (employeeData) => {
      dispatch(CreateEmployeeRequest());

      try {
        const response = await AuthenticationService.createEmployee(
          employeeData
        );
        dispatch(CreateEmployeeSuccess(response));
        toast.success("Creazione del dipendente avvenuta con successo!");
        return response;
      } catch (error) {
        dispatch(CreateEmployeeError(error.message));
        toast.error(`Errore nella creazione del dipendente: ${error.message}`);
        throw error.message;
      }
    },
    [dispatch]
  );

  const callUpdateEmployee = useCallback(
    async (employeeData) => {
      dispatch(UpdateEmployeeRequest());

      try {
        const response = await AuthenticationService.updateEmployee(
          employeeData
        );
        dispatch(UpdateEmployeeSuccess(response));
        toast.success("Aggiornamento del dipendente avvenuto con successo!");
        return response;
      } catch (error) {
        dispatch(UpdateEmployeeError(error.message));
        toast.error(
          `Errore nell'aggiornamento del dipendente: ${error.message}`
        );
        throw error.message;
      }
    },
    [dispatch]
  );

  const callDeleteEmployee = useCallback(
    async (employeeId) => {
      dispatch(DeleteEmployeeRequest());

      try {
        const response = await AuthenticationService.deleteEmployee(employeeId);
        dispatch(DeleteEmployeeSuccess(response));
        toast.success("Eliminazione del dipendente avvenuta con successo!");
        return response;
      } catch (error) {
        dispatch(DeleteEmployeeError(error.message));
        toast.error(
          `Errore nell'eliminazione del dipendente: ${error.message}`
        );
        throw error.message;
      }
    },
    [dispatch]
  );

  return {
    dispatchLoginUser: callLoginUser,
    dispatchNewUser: callNewUser,
    dispatchForgotPassword: callForgotPassword,
    dispatchResetPassword: callResetPassword,
    dispatchFetchAllEmployees: callFetchAllEmployeeApi,
    dispatchFetchEmployeeDetails: callFetchEmployeeDetailsApi,
    dispatchCreateEmployee: callCreateEmployee,
    dispatchUpdateEmployee: callUpdateEmployee,
    dispatchDeleteEmployee: callDeleteEmployee,
  };
};

export default useAuthentication;
