import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import IdealVacationSidebar from "../../Components/IdealVacationSidebar/IdealVacationSidebar";
import IdealVacationInfo from "../../Components/IdealVacationInfo/IdealVacationInfo";
import useIdealVacation from "../../library/hooks/useIdealVacation/useIdealVacation";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#26529c",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      color: "#26529c",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  separator: {
    fontSize: "20px",
    color: "#26529c",
  },
}));

export default function IdealVacationPage() {
  const navigate = useNavigate();
  let { idealVacationId } = useParams();

  /**
   * Api Context
   */
  const {
    callFetchIdealVacationByIdApi,
    callCreateIdealVacationApi,
    callUpdateIdealVacationApi,
  } = useIdealVacation();

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);

  const [form, setForm] = useState({
    id: "-",
    name: "",
    order: null,
    packageType: null,
    externalQuery: null,
    imageUrl: null,
    isActive: false,
    createdDate: new Date().toISOString(),
    createdBy: "-",
    tempImage: null,
    imageFileChanged: false,
  });

  const classes = useStyles();

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const CustomSeparator = () => (
    <Typography variant="body1" className={classes.separator}>
      ›
    </Typography>
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/"
      className={classes.link}
    >
      HoEscape
    </Link>,
    <Link underline="hover" key="2" color="inherit" className={classes.link}>
      Create Ideal Vacation
    </Link>,
  ];

  /**
   * Handler Functions
   */
  const saveInformation = async (
    name,
    order,
    packageType,
    externalQuery,
    imageUrl,
    imageFileChanged
  ) => {
    let tempObject = {
      ...form,
      name: name,
      order: order == null ? 0 : order,
      packageType: packageType,
      externalQuery: externalQuery,
      imageUrl: imageFileChanged ? imageUrl : form.imageUrl,
      imageFileChanged: imageFileChanged,
    };

    await save(tempObject);
  };

  const save = async (saveObject) => {
    //call Api
    if (saveObject.id === "-") {
      await callCreateIdealVacationApi(
        {
          ...saveObject,
          imageUrl: "",
        },
        saveObject.imageUrl // send image file
      )
        .then((res) => {
          if (res.isSuccess) {
            if (saveObject.packageType === 3)
              navigate(`/landing-page/0/${res.data.id}/0`);
            else navigate(`/hoescape`);
          } else {
            console.error("Error: Create Ideal Vacation", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Create Ideal Vacation", err);
        });
    } else {
      await callUpdateIdealVacationApi(
        {
          ...saveObject,
          imageUrl: saveObject.imageFileChanged ? "" : saveObject.imageUrl,
        },
        saveObject.imageFileChanged ? saveObject.imageUrl : null // send image file
      )
        .then((res) => {
          if (res.isSuccess) {
            if (saveObject.packageType === 3)
              navigate(
                `/landing-page/${
                  res.data.landingPageId !== null &&
                  res.data.landingPageId !== undefined &&
                  res.data.landingPageId !== ""
                    ? res.data.landingPageId
                    : "0"
                }/${res.data.id}/0`
              );
            else navigate(`/hoescape`);
          } else {
            console.error("Error: Update Ideal Vacation", res.message);
          }
        })
        .catch((err) => {
          console.error("Error: Update Ideal Vacation", err);
        });
    }
  };

  const setupForm = async (data) => {
    if (!data) return;

    //download Image
    let image = null;
    if (data.imageUrl) {
      image = await fetch(data.imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          return blob;
        })
        .catch((error) => console.error("Error downloading image:", error));
    }

    // assign values to form
    setForm({
      ...data,
      tempImage: image,
    });
  };

  /**
   * side Effects
   */

  useEffect(() => {
    if (idealVacationId) {
      callFetchIdealVacationByIdApi(idealVacationId)
        .then((res) => {
          if (res && res.data) {
            setupForm(res.data);
          } else {
            alert("No record Found!");
            navigate("/hoescape");
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    }
  }, [idealVacationId]);

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ height: "48px", padding: "10px" }}>
        <Stack spacing={2} sx={{ marginLeft: "20px" }}>
          <Breadcrumbs separator={<CustomSeparator />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        sx={{ backgroundColor: "#f2f2f2", minHeight: "90vh", padding: "40px" }}
      >
        <Box sx={{ display: "flex" }}>
          <IdealVacationSidebar />
          <IdealVacationInfo
            propVacationName={form.name}
            propVacationOrder={form.order}
            propVacationPackageType={form.packageType}
            propVacationExternalRedirect={form.externalQuery}
            propImage={form.imageUrl}
            propImageEditCase={form.tempImage}
            sendValueToParent={saveInformation}
          />
        </Box>
      </Box>
    </Layout>
  );
}
