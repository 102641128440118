import { createContext, useContext, useReducer } from "react";
import  HotelDetailsConfigurationReducer from "../reducers/HotelDetailsConfigurationReducer";

export const HotelDetailsDashboardConfigurationContext = createContext({
    state: {...HotelDetailsConfigurationReducer.initialState },
    dispatch: () => { },
});

export const useHotelDetailsConfigurationContext = () => useContext(HotelDetailsDashboardConfigurationContext);

function HotelDetailsConfigurationSlicer({ children }) {
    const [state, dispatch] = useReducer(
        HotelDetailsConfigurationReducer.reducer,
        HotelDetailsConfigurationReducer.initialState,
    );

    return (
        <HotelDetailsDashboardConfigurationContext.Provider value={{state, dispatch}}>
            {children}
        </HotelDetailsDashboardConfigurationContext.Provider>
    );
}

export default HotelDetailsConfigurationSlicer;