import { useCallback } from "react";
import { useTagContext } from "../../store/slices/TagSlicer";
import { useSettingContext } from "../../store/slices/HotelSetting";
import SettingService from "../../services/API/setting";
import { FetchTags } from "../../store/reducers/TagReducer";
import { FetchSetting } from "../../store/reducers/HotelSettingReducer";

const useSetting = () => {
  const { dispatch: fetchDashboardSettingDispatch } = useTagContext();
  const { dispatch: fetchSettingDispatch } = useSettingContext();

  const callFetchAllTagsApi = useCallback(async () => {
    await SettingService.fetchAllTags().then((response) => {
      fetchDashboardSettingDispatch(FetchTags(response));
    });
  }, [fetchDashboardSettingDispatch]);

  const dispatchFetchAllTags = useCallback(async () => {
    await callFetchAllTagsApi();
  }, [callFetchAllTagsApi]);

  const callAddTagAPI = useCallback(async (body, dynamicComponents,settings) => {
    await SettingService.saveAllTag({
      tags: body,
      linkedLocations: dynamicComponents,
      settings:settings,
    }).then((response) => {
      return response;
    });
  }, []);

  // const callSettingAPI = useCallback(async () => {
  //   await SettingService.fetchSetting().then((response) => {
  //     fetchSettingDispatch(FetchSetting(response));
  //   });
  // }, [fetchSettingDispatch]);

  // const dispatchSetting = useCallback(async () => {
  //   await callSettingAPI();
  // }, [callSettingAPI]);

  const callSettingAPI = useCallback(async () => {
    return await SettingService.fetchSetting();
  }, []);

  const dispatchSetting = useCallback(() => {
    callSettingAPI().then((response) => {
      fetchSettingDispatch(FetchSetting(response));
    });
  }, [callSettingAPI, fetchSettingDispatch]);

  return {
    dispatchFetchAllTags,
    callAddTagAPI,
    dispatchSetting,
  };
};
export default useSetting;
