import React, { useEffect, useState } from "react";
import { sliderData } from "../../NegotiationDetailPage/dummyData/dummyData";
import rightArrow from "../../../assets/icons/right-arrow.svg";
import RemainingDepositBox from "./RemainingDepositBox";
import TimerComponent from "./TimerComponent";
import PrimaryButton from "../../../Components/Button/PrimaryButton";
import SecondaryButton from "../../../Components/Button/SecondaryButton";
import {
  remaningDeposit,
  termsOfPayment,
  tileLeftUntilDeparture,
} from "../../ContactDetails/dummyData/dummyData";
import { formatDate, formatToDDMMYYYY, formatDateRange } from '../../../utils/formatDate';
import { formatAmount } from '../../../utils/formatAmount';
import ArrowTextButtonHeader from "../../../Components/Header/ArrowTextButtonHeader";

const Slider = ({ info, isNegotiationsPage, isBooking }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [infoData, setInfoData] = useState();
  const [expandAccounting, setExpandAccounting] = useState(false);
  const [unpaidInvoice, setUnpaidInvoice] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [paidAmount, setPaidAmount] = useState();

  useEffect(() => {
    setInfoData(info);
    if (info?.paymentDetails) {
      const firstUnpaidInvoice = getFirstUnpaidInvoice(info?.paymentDetails);
      setUnpaidInvoice(firstUnpaidInvoice)
      const firstPaidInvoice = getFirstPaidInvoice(info?.paymentDetails);
      setPaidAmount(firstPaidInvoice)
    }

  }, [info])

  useEffect(() => {
    setPaymentDetails(info?.paymentDetails[0]);
  }, [info?.paymentDetails])

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1
    );
  };

  const getFirstUnpaidInvoice = (paymentDetails) => {
    if (paymentDetails != null && paymentDetails.length > 0) {

      // Select the first object from paymentDetails array
      const paymentDetail = paymentDetails[0];

      if (!paymentDetail) return null; // Return null if no paymentDetail exists

      // Filter invoices where status = 'Unpaid'
      const unpaidInvoices = paymentDetail.invoices.filter(
        (invoice) => invoice.status === "Unpaid"
      );
      if (unpaidInvoices.length === 0) return null; // Return null if no unpaid invoices found

      // Sort the filtered invoices by invoiceDueDateTime
      unpaidInvoices.sort((a, b) =>
        new Date(a.invoiceDueDateTime) - new Date(b.invoiceDueDateTime)
      );
      // Return the first invoice after sorting
      return unpaidInvoices[0];
    }
  };

  const getFirstPaidInvoice = (paymentDetails) => {
    if (paymentDetails != null && paymentDetails.length > 0) {

      // Select the first object from paymentDetails array
      const paymentDetail = paymentDetails[0];

      if (!paymentDetail) return null; // Return null if no paymentDetail exists

      // Filter invoices where status = 'Unpaid'
      const unpaidInvoices = paymentDetail.invoices.filter(
        (invoice) => invoice.status === "Paid"
      );
      if (unpaidInvoices.length === 0) return null; // Return null if no unpaid invoices found

      // Sort the filtered invoices by invoiceDueDateTime
      unpaidInvoices.sort((a, b) =>
        new Date(a.invoiceDueDateTime) - new Date(b.invoiceDueDateTime)
      );
      // Return the first invoice after sorting
      return unpaidInvoices[0];
    }
  };


  const getPaymentStatusColor = (account) => {
    const currentDate = new Date();
    if (account?.paymentStatus === "PAID") {
      return "bg-green-500"; // Paid
    }
    else if (new Date(account?.paymentDate) < currentDate && account?.paymentStatus !== "PAID") {
      return "bg-red-500"; // Deadline missed, and not paid
    }
    else if (account?.paymentStatus === "UNPAID") {
      return "bg-orange-500"; // Will be paid next
    } else {
      return "bg-white"; // Still to be paid
    }
  };

  const currentData = sliderData[currentIndex];

  return (
    <div>
      <div className="border-[1.5px] mx-[19px] text-primary text-[16px] mt-[20px] border-primary rounded-[10px] bg-primary bg-opacity-[0.07] px-[9px] py-[10px]">
        <div className="flex mb-[8px] items-center justify-between">
          <h2 className="font-semibold text-[24px]">
            {infoData?.hotelName ? infoData.hotelName : ''}
          </h2>
          {/* <div className="flex space-x-[13px] items-center">
            <img
              onClick={goToPrevSlide}
              className={`transform w-[12px] cursor-pointer opacity-40 rotate-180 ${
                currentIndex === 0 ? "opacity-10 pointer-events-none" : ""
              }`}
              src={rightArrow}
              alt="arrow"
            />
            <p className="text-[14px]">{currentIndex + 1}</p>
            <img
              onClick={goToNextSlide}
              className={`w-[12px] cursor-pointer  opacity-40 ${
                currentIndex === sliderData.length - 1
                  ? "opacity-10 pointer-events-none"
                  : ""
              }`}
              src={rightArrow}
              alt="arrow"
            />
          </div> */}
        </div>
        <div className="relative">
          <div>
            <p>{infoData?.hotelLocation?.dstName || infoData?.hotelLocation?.nationName
              ? `${infoData?.hotelLocation?.dstName || ''}${infoData?.hotelLocation?.dstName && infoData?.hotelLocation?.nationName ? ', ' : ''}${infoData?.hotelLocation?.nationName || ''}`
              : ''}</p>
            {/* <p>{infoData?.hotelLocation?.dstName + ", " + infoData?.hotelLocation?.nationName}</p> */}

            {/* <p>{infoData?.startDate +" - "+ infoData?.endDate}</p> */}
            <p>
              {infoData?.startDate && infoData?.endDate ? `${formatToDDMMYYYY(infoData?.startDate)} - ${formatToDDMMYYYY(infoData?.endDate)}` : ''}
            </p>
            <div className="mt-2">
              {infoData?.reservationsList?.map((room, roomInd) => (
                <p key={roomInd}>Room {roomInd + 1} - {room?.adults} Adults, {room?.children} Child</p>
              ))}
            </div>

            <ul className={`list-disc mt-[7px] ml-[30px]`}>
              {infoData?.selectedRooms?.map((extra, extraIndex) => (
                <li key={extraIndex}>
                  <span>{extra.roomDescription} - </span> <span>{extra.categories}{extra.boardCode} - </span>
                  <strong>{formatAmount(extra.total)}</strong>{" "}
                </li>
              ))}
            </ul>

            {isBooking ? (<div>
              <p className="mt-[12px]">Booked on {formatDate(infoData?.createdDate)}</p>
              <p className="mt-[12px]">Status - {infoData?.bookingFileStatus?.status}</p>
              <p className="mt-[12px]">Booking Id - {infoData?.bookingId}</p>
            </div>) : (<div>
              <p className="mt-[12px]">Quote Id - {infoData?.quoteId}</p>
            </div>)}

            <p className="mt-[12px]">Booked on {formatDate(infoData?.createdDate)}</p>
            <p className="mt-[12px]">Status - {infoData?.bookingFileStatus?.status}</p>
            <p className="mt-[12px]">Booking Id - {infoData?.bookingId}</p>
            {isBooking && (
              <div className="mt-[11px]" >
                <ArrowTextButtonHeader
                  title={`Contabilità ${infoData?.bookingFileStatus.status === 'Waiting for Payment' ? " - In Lavorazione su Aves" : ""}`}
                  isExpand={expandAccounting}
                  onClick={() => setExpandAccounting(!expandAccounting)}
                />
                <div
                  className={`mt-[7px] ml-[4px] space-y-[4px] transition-[height] duration-200 ease-in-out ${expandAccounting ? "h-auto" : "h-0 overflow-hidden"
                    }`}
                >
                  {infoData?.accounting?.map((account, accountIndex) => (
                    <div key={accountIndex} className="flex items-center">
                      <span
                        className={`w-[10px] h-[10px] mr-[8px] rounded-full ${getPaymentStatusColor(account)}`}
                      ></span>
                      <p>Versamento {formatAmount(account?.amount)} entro {formatToDDMMYYYY(account?.paymentDate)}</p>
                    </div>
                  ))}

                </div>
              </div>
            )}

          </div>
        </div>
      </div>

      <RemainingDepositBox data={remaningDeposit[currentIndex]} info={info} />
      <TimerComponent data={tileLeftUntilDeparture[currentIndex]} info={info} />
     
        {infoData?.bookingFileStatus?.status != "cancelled" &&
           <div className="flex mt-[14px] px-[19px] items-center space-x-[9px] w-full">
            <PrimaryButton
              height="36px"
              buttonText="“salda” l’intera vacanza ora"
              borderRadius="60px"
              width="100%"
              onClick={() => alert("if all the amount remained must be paid and the entire booking must be automatically paid.")}
            />
            <SecondaryButton
              height="36px"
              buttonText="“paga” il prossimo step"
              borderRadius="60px"
              width="100%"
              onClick={() => alert("if the next step must be paid.")}
            />
             </div>
        }
     
      <div className="bg-[#D9D9D9] mx-[19px] mt-[15px] py-[26px] pl-[13px] pr-[30px]  rounded-[10px] bg-opacity-[0.28]">
        <h2 className="text-[20px] font-medium uppercase text-black leading-[20px]">
          condizioni di pagamento
        </h2>
        <div className="mt-[12px]">
          <span className="text-[16px]">
            {paidAmount?.invoiceAmount ? (
              <>
                Hai pagato{" "}
                <strong>{formatAmount(paidAmount.invoiceAmount / 100)}</strong> il{" "}
                <strong>{formatDateRange(paidAmount.paymentDateTime)}</strong>,
                {unpaidInvoice?.invoiceDueDateTime ? (
                  <>
                    dovrai pagare il{" "}
                    <strong>{formatDateRange(unpaidInvoice.invoiceDueDateTime)}</strong>{" "}
                    altri{" "}
                    <strong>{formatAmount(unpaidInvoice.invoiceAmount)}</strong> e
                    avrai saldato completamente la tua vacanza.
                  </>
                ) : (
                  "e hai saldato completamente la tua vacanza."
                )}
              </>
            ) : (
              "No data available"
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slider;
