import { Button } from "@mui/material";
import React from "react";

const SecondaryButton = ({
  buttonText,
  height = "24px",
  width = "85px",
  fontSize = "16px",
  backgroundColor = "#26529C22",
  onClick,
  borderRadius = "5px",
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        border: "1.5px solid #26529C",
        borderRadius: borderRadius,
        color: "#26529C",
        height: height,
        width: width,
        textTransform: "none",
        fontSize: fontSize,
        fontWeight: "600",
        background: backgroundColor,
        "&:hover": {
          backgroundColor: "#1976d20a",
        },
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SecondaryButton;
