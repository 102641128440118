import { createContext, useContext, useReducer } from "react";
import settingReducer from "../reducers/HotelSettingReducer";

export const SettingContext = createContext({
  state: { ...settingReducer.initialState },
  dispatch: () => {},
});

export const useSettingContext = () => useContext(SettingContext);

function HotelSetting({ children }) {
  const [state, dispatch] = useReducer(
    settingReducer.reducer,
    settingReducer.initialState
  );

  return (
    <SettingContext.Provider value={{ state, dispatch }}>
      {children}
    </SettingContext.Provider>
  );
}

export default HotelSetting;
