const intialState = [
    {
        key: '',
        type: '',
        description: '',
        subDescription: '',
    }
];

const FetchSearchParamByTextAction = {
    FetchSearchParamByText: "FetchSearchParamByText",
}

export function FetchSearchParamByText(searchParamResponse) {
    var searchParams = [];
    searchParamResponse?.map(des => searchParams.push(des))
    return {
        type: FetchSearchParamByTextAction.FetchSearchParamByText,
        payload: {
            value: searchParams,
        }
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case FetchSearchParamByTextAction.FetchSearchParamByText:
            const newState = action.payload.value;
            return {
                ...newState,
            }
        default:
            throw new Error('Fail to fetch Hotel Data');
    }
};

const HotelSearchParamReducer = { intialState, reducer }

export default HotelSearchParamReducer;