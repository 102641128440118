import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export default function LandingPageSidebar({ index }) {
  return (
    <Box
      sx={{
        width: "306px",
        height: "245px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ height: "60px" }}>
        <Box
          sx={{
            display: "flex",
            borderLeft: index >= 1 ? "4px solid #26529C" : "4px solid #E7E7E7",
            borderTopLeftRadius: "5px",
          }}
        >
          <Box
            sx={{
              margin: "18px",
              backgroundColor: index >= 1 ? "#26529C" : "",
              border: index >= 1 ? "" : "1px solid #E7E7E7",
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                color: index >= 1 ? "white" : "#26529C",
                marginTop: "5px",
              }}
            >
              1
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "500",
              marginTop: "15px",
            }}
          >
            Info
          </Typography>
        </Box>

        {/* <Divider />

        <Box
          sx={{
            display: "flex",
            borderLeft: index >= 2 ? "4px solid #26529C" : "4px solid #E7E7E7",
          }}
        >
          <Box
            sx={{
              margin: "18px",
              backgroundColor: index >= 2 ? "#26529C" : "",
              border: index >= 1 ? "" : "1px solid #E7E7E7",
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                color: index >= 2 ? "white" : "#26529C",
                marginTop: "5px",
              }}
            >
              2
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "500",
              marginTop: "15px",
            }}
          >
            Extra
          </Typography>
        </Box> */}

        <Divider />

        <Box
          sx={{
            display: "flex",
            borderLeft: index >= 2 ? "4px solid #26529C" : "4px solid #E7E7E7",
            borderBottomLeftRadius: "5px",
          }}
        >
          <Box
            sx={{
              margin: "18px",
              backgroundColor: index >= 2 ? "#26529C" : "",
              border: index >= 2 ? "" : "1px solid #E7E7E7",
              width: "45px",
              height: "45px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                color: index >= 2 ? "white" : "#26529C",
                marginTop: "5px",
              }}
            >
              2
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: "32px",
              fontWeight: "500",
              marginTop: "15px",
            }}
          >
            Hotel
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
