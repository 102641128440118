import { useCallback } from "react";
import TouristicDestinationService from "../../services/API/touristicDestination";

const useTouristicDestination = () =>{
    const callFetchAllTouristicDestinationApi = useCallback(async () => {
        return await TouristicDestinationService.fetchAllTouristicDestination().then(
          (response) => {
            return response;
          }
        );
      }, []);


      const callCreateTouristicDestinationApi = useCallback(async (body, file) => {
        return await TouristicDestinationService.createTouristicDestination(body, file).then(
          (response) => {
            return response;
          }
        );
      }, []);
    
      const callUpdateTouristicDestinationApi  = useCallback(async (payload, file) => {
        return await TouristicDestinationService.updateTouristicDestination(payload, file).then(
          (response) => {
            return response;
          }
        );
      }, []);

      const callFetchTouristicDestinationByIdApi = useCallback(async (touristicDestinationId) => {
        return await TouristicDestinationService.fetchTouristicDestinationById(touristicDestinationId).then(
          (response) => {
            return response;
          }
        );
      }, []);

      const callEnableDisableTouristicDestinationByIdApi = useCallback(
        async (touristicDestinationId, isEnabled) => {
          return await TouristicDestinationService.enableDisableTouristicDestinationById(
            touristicDestinationId,
            isEnabled
          ).then((response) => {
            return response;
          });
        },
        []
      );

      const callDeleteTouristicDestinationByIdApi = useCallback(async (touristicDestinationId) => {
        return await TouristicDestinationService.deleteEventTouristicDestinationById(touristicDestinationId).then(
          (response) => {
            return response;
          }
        );
      }, []);

      return {
        callFetchAllTouristicDestinationApi,
        callCreateTouristicDestinationApi,
        callUpdateTouristicDestinationApi,
        callFetchTouristicDestinationByIdApi,
        callEnableDisableTouristicDestinationByIdApi,
        callDeleteTouristicDestinationByIdApi,
      };
}

export default useTouristicDestination;