import PrimaryButton from "../../Components/Button/PrimaryButton";
import React, { useState,useEffect } from "react";
import ArrowTextButtonHeader from "../../Components/Header/ArrowTextButtonHeader";
import { formatDate,formatToDDMMYYYY } from '../../utils/formatDate';
import { createURLFromAllHotelData } from "../../utils/helper/urlSlug";
import {formatAmount} from '../../utils/formatAmount';


const InfoBox = ({ expand, setExpand, onClick, marginTop = 8, item,isBooking }) => {
  const [expandInfo, setExpandInfo] = useState(false);
  const [expandAccounting, setExpandAccounting] = useState(false);
  const [redirectHotelUrl, setRedirectHotelUrl] = useState("");

  // Generate the redirect URL when the component loads or when item changes
  // useEffect(() => {
  //   if (item && !isBooking) {
  //     // Extract necessary data from item
  //     const data = {
  //       StartDate: item?.startDate,
  //       EndDate: item?.endDate,
  //     };
  //     // Create the URL
  //     const url = createURLFromAllHotelData(data,item?.hotelName,
  //       item?.hotelDetails?.hotelCode);
  //     setRedirectHotelUrl(url);
  //   }
  // }, [item]);

   // Determine border color based on the booking status
   const getBorderColor = () => {
    if (!isBooking) {
      return "border-primary";
    }
    switch (item?.bookingFileStatus.status) {
      case "Confirmed and Paid":
        return "border-[#53D969]";
      case "Waiting for Payment":
        return "border-[#3984F3]";
      case "Cancelled":
        return "border-[#FE4A55]";
      case "Quote Requested":
        return "border-[#2151F8]";
      default:
        return "border-primary"; // Default color
    }
  };

  const getPaymentStatusColor = (account) => {
    const currentDate = new Date();
    if (account?.paymentStatus === "PAID") {
      return "bg-green-500"; // Paid
    }
    else if (new Date(account?.paymentDate) < currentDate && account?.paymentStatus !== "PAID") {
      return "bg-red-500"; // Deadline missed, and not paid
    }
    else if (account?.paymentStatus === "UNPAID") {
      return "bg-orange-500"; // Will be paid next
    } else {
      return "bg-white"; // Still to be paid
    }
  };
  
  return (
    <div
      style={{ marginTop: marginTop }}
       className={`border-[1.5px] ${getBorderColor()} rounded-[10px] bg-white bg-opacity-[0.6] px-[9px] py-[10px]`}
    >
      <div className="flex mb-[8px] items-center justify-between">
        <h2 className="font-semibold">{item?.hotelName}</h2>
        <p>{formatDate(!isBooking ? item?.createQuotesdDate : item?.createdDate)}</p>
      </div>
      {expand ? (
        <div>
          <p>{item?.hotelLocation?.regionState + " "+item?.hotelLocation?.nationName}</p>

          <p>{formatDate(item?.createdDate)}</p>

          {item?.selectedRooms?.map((room, roomIndex) => (
            <p key={roomIndex} className="mt-[10px]">
              {room.roomDescription}
            </p>
          ))}

          <div>
            <ArrowTextButtonHeader
              onClick={() => setExpandInfo(!expandInfo)}
              title="Info Extra"
              isExpand={expandInfo}
            />
            <ul
              className={`list-disc mt-[7px] ml-[30px] transition-[height] duration-200 ease-in-out ${
                expandInfo ? "h-auto" : "h-0 overflow-hidden"
              }`}
            >
              {item?.selectedRooms?.map((extra, extraIndex) => (
                <li key={extraIndex}>
                  <span>{extra.roomDescription} - </span> <span>{extra.categories}{extra.boardCode} - </span>
                  <strong>{formatAmount(extra.total)}</strong>{" "}
                </li>
              ))}
            </ul>
          </div>
          { isBooking && (
            <div className="mt-[11px]" >
            <ArrowTextButtonHeader
              title={`Contabilità ${item?.bookingFileStatus.status === 'Waiting for Payment' ? " - In Lavorazione su Aves" : ""}`}
              isExpand={expandAccounting}
              onClick={() => setExpandAccounting(!expandAccounting)}
            />
            <div
              className={`mt-[7px] ml-[4px] space-y-[4px] transition-[height] duration-200 ease-in-out ${
                expandAccounting ? "h-auto" : "h-0 overflow-hidden"
              }`}
            >
              {item?.accounting?.map((account, accountIndex) => (
                 <div key={accountIndex} className="flex items-center">
                 <span
                  className={`w-[10px] h-[10px] mr-[8px] rounded-full ${getPaymentStatusColor(account)}`}
                 ></span>
                 <p>Versamento {formatAmount(account?.amount)} entro {formatToDDMMYYYY(account?.paymentDate)}</p>
               </div>
              ))}
               

            </div>
          </div>
        )}
          
          <div className="mt-[16px] flex items-end justify-between">
            <div>
              <div>
                <span>Prezzo: </span>
                <strong>{formatAmount(item.price)}</strong>
              </div>
              <span>ID: </span>
              <span>{item.quoteId}</span>
              {!isBooking && (<p
                onClick={() => window.open(item.quotationLink)}
                className="underline cursor-pointer"
              >
                Vedi Pagina Preventivo
              </p>)}
              
            </div>
            <PrimaryButton
              onClick={() => setExpand(false)}
              buttonText="Nascondi"
              width="104px"
            />
          </div>
        </div>
      ) : (
        <PrimaryButton onClick={onClick} buttonText="Mostra" />
      )}
    </div>
  );
};

export default InfoBox;
