import React from "react";
import downShortArrowIcon from "../../../assets/icon/down-short-arrow.svg";
import leftArrowIcon from "../../../assets/icon/arrow-left.svg";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LeftSideHeader = ({info}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="px-[38px] pt-[9px] flex items-center justify-between w-full">
      <div
        onClick={() => navigate(info == "negotiation" ? "/negotiation" :"/contacts")}
        className="flex cursor-pointer items-center space-x-[8px]"
      >
        <img src={leftArrowIcon} alt="left-arrow" className="" />
        <h4 className="text-primary font-semibold text-[16px]">{info == "negotiation" ? "Negoziazioni" :"Contatti"}</h4>
      </div>
      <div
        onClick={handleClick}
        className="flex items-center space-x-[7px] cursor-pointer"
      >
        <h4 className="text-primary font-semibold text-[16px]">Azioni</h4>
        <img src={downShortArrowIcon} alt="down-short-arrow" className="" />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "scroll",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            maxHeight: "600px",
            width: "20ch",
          },
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Action 1</MenuItem>
        <MenuItem onClick={handleClose}>Action 2</MenuItem>
        <MenuItem onClick={handleClose}>Action 3</MenuItem>
      </Menu>
    </div>
  );
};

export default LeftSideHeader;
