import { createContext, useContext, useReducer } from "react";
import SearchParamReducer from "../reducers/SearchParamsReducer";

export const SearchParamContext = createContext({
  state: { ...SearchParamReducer.intialState },
  dispatch: () => {},
});

export const useHotelSearchParamContext = () => useContext(SearchParamContext);

function SearchParamSlicer({ children }) {
  const [state, dispatch] = useReducer(
    SearchParamReducer.reducer,
    SearchParamReducer.intialState
  );

  return (
    <SearchParamContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchParamContext.Provider>
  );
}

export default SearchParamSlicer;
