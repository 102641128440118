import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  Popover,
  TextField,
} from "@mui/material";
import { ReactComponent as CrossIcon } from "assets/icons/Cross.svg";
import PrimaryButton from "Components/Button/PrimaryButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import {
  checkUserPermissions,
  reverseRightsOptionMapping,
  reverseSecurityLevelToRoleMapping,
  rightsOptionMapping,
  securityLevelToPermissionMap,
  securityLevelToRoleMapping,
} from "helper/teamHelper";
import { toast } from "react-toastify";
import Loading from "Components/Loading/Loading";

const buttons = [
  { id: 1, btnTxt: "Admin" },
  { id: 2, btnTxt: "Statista" },
  {
    id: 3,
    btnTxt: "Contatti e Negoziazioni",
  },
  { id: 4, btnTxt: "HoEscape" },
  { id: 5, btnTxt: "Hotel" },
  { id: 6, btnTxt: "Custom" },
];

const options = [
  "L'utente può creare Utenti Admin",
  "L'utente può creare Utenti Statista",
  "L'utente può creare Utenti Contatti e Negoziazioni",
  "L'utente può creare Utenti HoEscape",
  "L'utente può creare Utenti Hotel",
  "L'utente può creare Utenti Custom",
  "L'utente può vedere le statistiche del sito HoEscape",
  "L'utente può vedere le statistiche degli Hotel",
  "L'utente può vedere le statistiche dei Plugin WP",
  "L'utente può vedere i Contatti e Negoziazioni",
  "L'utente può modificare i Contatti e Negoziazioni",
  "L'utente può creare, eliminare o modificare i Plugin WP",
  "L'utente può modificare gli Hotel",
  "L'utente può modificare il sito HoEscape",
];

const UserModal = ({
  openDialog,
  setOpenDialog,
  editMode,
  setEditMode,
  userData,
  setUserData,
}) => {
  const [securityLevel, setSecurityLevel] = useState("Admin");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [employeeImage, setEmployeeImage] = useState("");
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fileInputRef = useRef(null);
  const {
    dispatchCreateEmployee,
    dispatchFetchAllEmployees,
    dispatchDeleteEmployee,
    dispatchUpdateEmployee,
  } = useAuthentication();
  const { state: employeeState } = useAuthenticationContext();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (userData) {
      const fetchedSecurityLevel = userData?.roles[0]?.name;
      setSecurityLevel(
        reverseSecurityLevelToRoleMapping[fetchedSecurityLevel] ||
          fetchedSecurityLevel
      );

      if (fetchedSecurityLevel === "CUSTOM") {
        setSelectedOptions(
          userData?.roles[0]?.rights.map(
            ({ name }) => reverseRightsOptionMapping[name]
          )
        );
      }
    }
  }, [userData]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditMode(false);
    setUserData(null);
    setName("");
    setSurname("");
    setEmail("");
    setEmployeeImage("");
    setSecurityLevel("Admin");
    setSelectedOptions([]);
  };

  const handleOptionChange = (option) => {
    const selectedIndex = selectedOptions.indexOf(option);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedOptions, option];
    } else if (selectedIndex === 0) {
      newSelected = selectedOptions.slice(1);
    } else if (selectedIndex === selectedOptions.length - 1) {
      newSelected = selectedOptions.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selectedOptions.slice(0, selectedIndex),
        ...selectedOptions.slice(selectedIndex + 1),
      ];
    }

    setSelectedOptions(newSelected);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSecurityLevel = (btnTxt) => {
    setSecurityLevel(btnTxt);
  };

  const handleCreate = async () => {
    const requiredPermission = securityLevelToPermissionMap[securityLevel];
    if (
      !checkUserPermissions(requiredPermission, employeeState?.userData?.data)
    ) {
      toast.error("Non hai il permesso di creare questo tipo di utente.");
      return;
    }

    const formData = new FormData();

    const appendFormData = (key, value) => formData.append(key, value);

    const employeeData = {
      Id: "-",
      Name: name,
      Surname: surname,
      Email: email,
      IsActive: true,
      Roles: [
        {
          id: "-",
          name: securityLevelToRoleMapping[securityLevel],

          rights:
            securityLevel === "Custom"
              ? selectedOptions.map((option) => ({
                  id: "-",
                  name: rightsOptionMapping[option],
                }))
              : [],
        },
      ],
      CreatedBy: employeeState.userData?.data?.name,
    };

    Object.keys(employeeData).forEach((key) => {
      if (key === "Roles") {
        appendFormData(key, JSON.stringify(employeeData[key]));
      } else {
        appendFormData(key, employeeData[key]);
      }
    });

    try {
      await dispatchCreateEmployee(formData);
      handleDialogClose();
      dispatchFetchAllEmployees();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleEdit = async () => {
    const requiredPermission = securityLevelToPermissionMap[securityLevel];
    if (
      !checkUserPermissions(requiredPermission, employeeState?.userData?.data)
    ) {
      toast.error("Non hai il permesso di modificare questo tipo di utente.");
      return;
    }

    const formData = new FormData();
    const appendFormData = (key, value) => formData.append(key, value);

    const employeeData = {
      Id: userData?.id,
      Name: name || userData?.name,
      Surname: surname || userData?.surname,
      Email: email || userData?.email,
      ImageFile: employeeImage,
      ImageUrl: employeeImage ? "" : userData.imageUrl,
      CreatedBy: userData?.createdBy,
      UpdatedBy: employeeState.userData?.data?.name,
      Roles: [
        {
          id: "-" || userData?.roles[0]?.id,
          name:
            securityLevelToRoleMapping[securityLevel] ||
            userData?.roles[0]?.name,
          rights:
            securityLevel === "Custom"
              ? selectedOptions.map((option) => ({
                  id: "-",
                  name: rightsOptionMapping[option],
                }))
              : [],
        },
      ],
    };
    Object.keys(employeeData).forEach((key) => {
      if (key === "Roles") {
        appendFormData(key, JSON.stringify(employeeData[key]));
      } else {
        appendFormData(key, employeeData[key]);
      }
    });

    try {
      const updatedData = await dispatchUpdateEmployee(formData);
      if (updatedData.data != null) {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData != null) {
          let userData = JSON.parse(storedUserData);
          userData.data.imageUrl = updatedData.data.imageUrl;
          let updatedUserData = JSON.stringify(userData);
          localStorage.setItem("userData", updatedUserData);
        }
      }
      handleDialogClose();
      dispatchFetchAllEmployees();
    } catch (error) {
      console.log("Edit Employee Error", error);
    }
  };

  const handleDeleteEmployee = async () => {
    const requiredPermission = securityLevelToPermissionMap[securityLevel];
    if (
      !checkUserPermissions(requiredPermission, employeeState?.userData?.data)
    ) {
      toast.error("Non hai il permesso di modificare questo tipo di utente.");
      return;
    }

    try {
      const confirmDelete = window.confirm(
        "Sei sicuro di voler eliminare questo utente?"
      );
      if (confirmDelete) {
        await dispatchDeleteEmployee(userData?.id);
        handleDialogClose();
        dispatchFetchAllEmployees();
      }
    } catch (error) {
      console.log("Employee Delete Error", error);
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const dropAreaRef = useRef(null);
  const [modalContentRendered, setModalContentRendered] = useState(false);

  useEffect(() => {
    if (!openDialog) return;

    setModalContentRendered(true);

    return () => {
      setModalContentRendered(false);
    };
  }, [openDialog]);
  useEffect(() => {
    if (!modalContentRendered) return;

    const dropArea = dropAreaRef.current;

    const handleDragOver = (event) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (event) => {
      event.preventDefault();
      setIsDragging(false);
    };

    const handleDrop = (event) => {
      event.preventDefault();
      setIsDragging(false);

      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        setEmployeeImage(URL.createObjectURL(files[0]));
        event.dataTransfer.clearData();
      }
    };

    dropArea.addEventListener("dragover", handleDragOver);
    dropArea.addEventListener("dragleave", handleDragLeave);
    dropArea.addEventListener("drop", handleDrop);

    return () => {
      dropArea.removeEventListener("dragover", handleDragOver);
      dropArea.removeEventListener("dragleave", handleDragLeave);
      dropArea.removeEventListener("drop", handleDrop);
    };
  }, [modalContentRendered]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "714px",
            maxWidth: "714px",
            borderRadius: "8px",
            paddingTop: "22px",
            paddingBottom: "25px",
          },
        },
      }}
      open={openDialog}
      onClose={handleDialogClose}
    >
      <div className="flex items-center justify-between pl-[33px] pr-[25px]">
        <h2 className="text-[24px] font-semibold text-[#2E2C34]">
          {editMode ? "Modifica Utente" : "Crea Nuovo Utente"}
        </h2>
        <CrossIcon onClick={handleDialogClose} style={{ cursor: "pointer" }} />
      </div>
      <Divider sx={{ color: "#E3E3E3", marginTop: "23px" }} />

      <div ref={dropAreaRef} className="mt-[28px] pl-[33px] pr-[28px]">
        <h2 className="text-[20px] font-semibold text-[#2E2C34]">
          Dettagli Utente
        </h2>
        <p className="text-[12px] mt-[2px] font-medium text-[#84818A]">
          Inserisci i dettagli dell’utente
        </p>

        <div className="mt-[18px] flex items-center justify-between space-x-[13px]">
          <div className="flex-1">
            <label className="text-[14px] font-medium text-[#1E1E1E]">
              Nome
            </label>
            <TextField
              onChange={(e) => setName(e.target.value)}
              defaultValue={userData?.name}
              sx={{
                marginTop: "8px",
                width: "100%",
                height: "48px",
                borderRadius: "8px",
              }}
              variant="outlined"
              placeholder="Nome"
            />
          </div>{" "}
          <div className="flex-1">
            <label className="text-[14px] font-medium text-[#1E1E1E]">
              Cognome
            </label>
            <TextField
              onChange={(e) => setSurname(e.target.value)}
              defaultValue={userData?.surname}
              sx={{
                marginTop: "8px",
                width: "100%",
                height: "48px",
                borderRadius: "8px",
              }}
              variant="outlined"
              placeholder="Cognome"
            />
          </div>
        </div>
        <div className="mt-[18px] flex items-starts justify-between space-x-[13px]">
          <div className={`${editMode ? "flex-1" : "w-1/2 pr-[7.5px]"}`}>
            <label className="text-[14px] font-medium text-[#1E1E1E]">
              Email
            </label>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={userData?.email}
              sx={{
                marginTop: "8px",
                width: "100%",
                height: "48px",
                borderRadius: "8px",
              }}
              variant="outlined"
              placeholder="email@gmail.com"
            />
          </div>
          {editMode && (
            <div className="flex-1">
              <label className="text-[14px] font-medium text-[#1E1E1E]">
                Immagine Utente
              </label>
              <div
                className={`flex items-center hover:border-black px-[14px] justify-between mt-[8px] w-full h-[56px] rounded-[8px] border-[1px] ${
                  isDragging ? "border-black" : "border-[#0000003b]"
                }`}
                style={{
                  backgroundColor: isDragging ? "#f0f0f0" : "transparent",
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setEmployeeImage(e.target.files[0]);
                    }
                  }}
                />
                <span>{employeeImage ? "Caricata" : "Carica"}</span>
                <IconButton onClick={handleButtonClick}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      <Divider sx={{ color: "#E3E3E3", marginTop: "28px" }} />
      <div className="mt-[24px] pl-[33px] pr-[28px]">
        <h2 className="text-[20px] font-semibold text-[#2E2C34]">
          Livello di Sicurezza
        </h2>
        <div className="mt-[22px] flex items-center space-x-3">
          {buttons.map(({ id, btnTxt }) => (
            <button
              key={id}
              onClick={(e) => {
                handleSecurityLevel(btnTxt);
                if (btnTxt === "Custom") {
                  setAnchorEl(e.currentTarget);
                }
              }}
              className={`${
                securityLevel === btnTxt
                  ? "bg-[#005CAB] text-white"
                  : "bg-[#E5E5E5] text-[#2E2C34]"
              } py-[6px] px-[8px] rounded-[4px]  outline-none border-none`}
            >
              {btnTxt}{" "}
              {btnTxt === "Custom" &&
                selectedOptions.length > 0 &&
                `(${selectedOptions.length})`}
            </button>
          ))}
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              maxWidth: "300px",
              maxHeight: "400px",
              overflow: "auto",
              padding: "20px",
              borderRadius: "10px",
            },
          }}
        >
          {options.map((option, index) => (
            <FormControlLabel
              sx={{ paddingBottom: "8px", width: "100%" }}
              key={index}
              control={
                <Checkbox
                  checked={selectedOptions.indexOf(option) !== -1}
                  onChange={() => handleOptionChange(option)}
                />
              }
              label={option}
            />
          ))}
        </Popover>
      </div>

      <Divider sx={{ color: "#E3E3E3", marginTop: "24px" }} />
      <div className="mt-[26px] flex items-center justify-between pl-[34px] pr-[28px]">
        <Button
          variant="outlined"
          sx={{ width: "153px", height: "43px", textTransform: "capitalize" }}
          onClick={editMode ? handleDeleteEmployee : handleDialogClose}
          color="error"
        >
          {editMode ? "Elimina Utente" : "Annulla"}
        </Button>
        <PrimaryButton
          buttonText={editMode ? "Salva Modifiche" : "Crea"}
          width={editMode ? 170 : 128}
          height="43px"
          onClick={editMode ? handleEdit : handleCreate}
        />
      </div>
      <Loading isLoading={employeeState.loading} />
    </Dialog>
  );
};

export default UserModal;
