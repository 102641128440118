import { Box } from "@mui/material";
import notificationIcon from "assets/icons/notification.svg";
import messageIcon from "assets/icons/message.svg";

import PersistentDrawerLeft from "../Sidebar/Sidebar";

export default function Layout({
  children,
  handleToggle,
  isSidebarOpened,
  isMessageIconVisable = false,
  backgroundColor = "white",
}) {
  return (
    <Box
      sx={{
        overflowY: "hidden",
        overflow: "hidden",
        display: "flex",
        maxHeight: "100vh",
        height: "100vh",
      }}
    >
      <PersistentDrawerLeft handleToggle={handleToggle}>
        <Box
          sx={{
            backgroundColor: "#26529C",
            maxWidth: "1440px",
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              // maxHeight: "90vh",
              position: "fixed",
              top: 18,
              left: isSidebarOpened ? 330 : 15,
              right: 10,
              bottom: 20,
            }}
          >
            <Box
              sx={{
                // margin: "-62px 20px 20px 10px",
                marginRight: "10px",
                marginLeft: "10px",
                backgroundColor: backgroundColor,
                // minHeight: "90vh",
                borderRadius: "20px",
                flex: 1,
                width: isSidebarOpened ? "calc(100% - 330px)" : "1350px",
                overflowX: "hidden",
              }}
            >
              {children}
            </Box>
            <Box
              sx={{
                marginBottom: "64px",
                marginTop: "60px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* <img
                src={notificationIcon}
                style={{ height: "30px", width: "25px", marginTop: "0px" }}
                alt="notification"
              /> */}
              {isMessageIconVisable && (
                <img
                  src={messageIcon}
                  style={{ height: "26px", width: "31px", marginTop: "0px" }}
                  alt="message"
                />
              )}
            </Box>
          </Box>
        </Box>
      </PersistentDrawerLeft>
    </Box>
  );
}
