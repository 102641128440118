import Layout from "../../Components/Layout/Layout";
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import AboutContactForm from "../ContactNegotiationDetailPages/components/AboutContactForm";
import InformationAboutActivityForm from "../ContactNegotiationDetailPages/components/InformationAboutActivityForm";
import SearchField from "../../Components/Search/SearchField"; //"../../components/Search/SearchField";
import HighlightedData from "../ContactNegotiationDetailPages/components/HighlightedData";
import SearchInput from "../../Components/Search/SearchInput";
import LeftSideHeader from "../ContactNegotiationDetailPages/components/LeftSideHeader";
import LeftSideContactInformation from "../ContactNegotiationDetailPages/components/LeftSideContactInformation";
import ReservationsMadeSection from "../ContactNegotiationDetailPages/components/ReservationsMadeSection";
import RequestQuotesSection from "../ContactNegotiationDetailPages/components/RequestQuotesSection";
import FavoriteHotelList from "../ContactNegotiationDetailPages/components/FavoriteHotelList";
import ClicksLogComponent from "../ContactNegotiationDetailPages/components/ClicksLogComponent";
import ConfigurationsComponent from "../ContactNegotiationDetailPages/components/ConfigurationsComponent";
import TabBar from "../../Components/Tab/TabBar";
import useContactSetting from "../../library/hooks/Contact/Contact";
import { useContactContext } from "../../library/store/slices/ContactSlicer";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import Loading from "Components/Loading/Loading";
import ContactService from "../../library/services/API/contact";

export default function ContactDetails() {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [contactNegoInfo, setContactNegoInfo] = React.useState();
  const [employeeList, setEmployeeList] = React.useState([]);
  const [isLoading ,setIsLoading] = useState(false);
  const [reservationList,setReservationsList] = useState([]);
  const [isResLoading,setIsResLoading] = useState(false);

  const { dispatchForContactNegotiationsSearch } = useContactSetting();
  const { state: contactNegotiationsResponse } = useContactContext();

  const { dispatchFetchAllEmployees } = useAuthentication();
  const { state: employeeResponse } = useAuthenticationContext();

  let { id } = useParams();

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  useEffect(() => {
    setIsLoading(true);
    const body = { search: id };
    dispatchForContactNegotiationsSearch(body);
    dispatchFetchAllEmployees();
  }, []);

  useMemo(() => {
    if (contactNegotiationsResponse != null) {
      setContactNegoInfo(contactNegotiationsResponse);
    }
    setIsLoading(false);
  }, [contactNegotiationsResponse]);

  useMemo(()=>{
    setReservationsList(contactNegotiationsResponse?.reservations?.reservationsList);
  },[contactNegotiationsResponse])

  useMemo(() => {
    const empDataList = employeeResponse;
    if (
      empDataList.allEmployees != null &&
      empDataList.allEmployees.length > 0
    ) {
      setEmployeeList(empDataList.allEmployees);
    }
  }, [employeeResponse]);

  const onLoadMoreData = async (pageCount)=>{
    setIsResLoading(true);
    const request = {search: id,skip:pageCount,take:5};
    const response = await ContactService.fetchContactNegotiations(request);
    if (response) {
      if(response?.reservations?.totelReservations > 0){
        setReservationsList((prevRes)=>{
          const newRoomSelected = [
            ...prevRes,
            ...response?.reservations?.reservationsList
          ];
          return newRoomSelected;
        })
        setIsResLoading(false);
      }
    }
  }

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
       <Loading isLoading={isLoading} />
      <div className="flex items-start w-full h-full overflow-hidden">
        {/* Left Component  */}
        <div className="bg-primary bg-opacity-10 h-full overflow-auto w-[30%] pb-[50px]">
          <LeftSideHeader />
          <LeftSideContactInformation
            info={contactNegoInfo?.contactNegotiation}
          />
          <div className="mt-[22px] border-[1.3px] border-primary w-full" />
          <AboutContactForm
            employees={employeeList}
            info={contactNegoInfo?.contactNegotiation}
            type="contact"
          />
          {/* <InformationAboutActivityForm
            info={contactNegoInfo?.contactNegotiation}
          /> */}
        </div>
        {/* Middle Component  */}
        <div className="bg-white pb-[50px] h-full overflow-auto w-[40%]">
          <div className="flex flex-col mt-[40px] items-center w-full">
            {/* <SearchField /> */}
          </div>
          <HighlightedData info={contactNegotiationsResponse} />
        </div>
        {/* Right Component */}
        <div className="bg-primary bg-opacity-10 h-full overflow-auto w-[30%] pb-[50px] text-primary text-[16px] ">
          <RequestQuotesSection info={contactNegotiationsResponse} onChangeCenterData={null}/>
          {/* <ReservationsMadeSection info={reservationList} onChangeCenterData={null}/> */}
          <ReservationsMadeSection info={reservationList} onChangeCenterData={null} onLoadMoreData={onLoadMoreData} totelReservations={contactNegotiationsResponse?.reservations?.totelReservations} isResLoading={isResLoading}/>
        </div>
      </div>
    </Layout>
  );
}
