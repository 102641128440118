import axiosConfig from "../axiosConfig";

class SearchHotelServices {
  searchHotels = async (payload) => {
    if (payload) {
      return await axiosConfig.post(`/Search/Hotels`, payload);
    }
    console.warn("payload not provided, SearchHotelServices.searchHotels");
    return null;
  };

  searchHotelsParams = async (payload) => {
    if (payload) {
      return await axiosConfig.post(`/Search/Hotels/params`, payload);
    }
    console.warn(
      "payload not provided, SearchHotelServices.searchHotelsParams"
    );
    return null;
  };

  searchParams = async (payload) => {
    if (payload) {
      return await axiosConfig.post(`/Search/params`, payload);
    }
    console.warn(
      "payload not provided, SearchHotelServices.searchHotelsParams"
    );
    return null;
  };
}

const instance = new SearchHotelServices();

export default instance;
