import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar2.svg";

const DashboardHeader = ({ title, subtitle, isDatePicker = false }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
      }}
    >
      <Box>
        <Typography
          variant="body1"
          fontWeight="bold"
          fontSize="40px"
          lineHeight="50px"
          color="#26529C"
          sx={{ paddingTop: "30px" }}
        >
          {title}
        </Typography>
        <Typography
          fontSize="16px"
          letterSpacing="0.48px"
          color="#26529C80"
          sx={{ paddingTop: "7px" }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "34px",
          gap: "10px",
        }}
      >
        {isDatePicker && (
          <DateRangePicker
            style={{
              height: "53px",
              background: "#FFFFFF",
              borderColor: "#26529C",
              borderWidth: "1px",
              borderRadius: "24px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            // menuStyle={{ right: 0 }}
            placement="bottomEnd"
            caretAs={CalendarIcon}
            appearance="subtle"
            placeholder="Select Date Range"
            onChange={(value) => console.log("Date Range Value:", value)}
          />
        )}
      </Box>
    </Box>
  );
};

export default DashboardHeader;
