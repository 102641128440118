import { useCallback } from "react";
import { useContactContext } from "../../store/slices/ContactSlicer";
import ContactService from "../../services/API/contact";
import { FetchContact } from "../../store/reducers/ContactReducer";

const useContactSetting = () => {
  const { dispatch: fetchContactDispatch } = useContactContext();

  const callFetchAllContactsApi = useCallback(
    async (body) => {
      await ContactService.fetchAllContacts(body).then((response) => {
        fetchContactDispatch(FetchContact(response));
      });
    },
    [fetchContactDispatch]
  );

  const callContactNegotiationsStatisticApi = useCallback(async (isContact) => {
    return await ContactService.fetchStatistic(isContact);
  }, []);

  const dispatchForStatisticParamByText = useCallback(
    (searchData) => {
      callContactNegotiationsStatisticApi(searchData).then((response) => {
        const newStatistic = response;
        fetchContactDispatch(FetchContact(newStatistic));
      });
    },
    [callContactNegotiationsStatisticApi, fetchContactDispatch]
  );

  const callContactNegotiationsSearchAPI = useCallback(async (body) => {
    return await ContactService.fetchContactNegotiations(body);
  }, []);

  const dispatchForContactNegotiationsSearch = useCallback(
    (body) => {
      callContactNegotiationsSearchAPI(body).then((response) => {
        const newContactNegotiations = response;
        fetchContactDispatch(FetchContact(newContactNegotiations));
      });
    },
    [callContactNegotiationsSearchAPI, fetchContactDispatch]
  );

  const callContactNegotiationsAssignedToUpdateAPI = useCallback(
    async (body) => {
      return await ContactService.updateContactNegotiationsAssignedTo(body);
    },
    []
  );

  const dispatchForContactNegotiationsAssignedToUpdate = useCallback(
    (body) => {
      callContactNegotiationsAssignedToUpdateAPI(body).then((response) => {
        const newContactNegotiations = response;
        fetchContactDispatch(FetchContact(newContactNegotiations));
      });
    },
    [callContactNegotiationsAssignedToUpdateAPI, fetchContactDispatch]
  );

  const callOnRequestEmailSendAPI = useCallback(
    async (body) => {
      return await ContactService.onRequestSendEmail(body);
    },
    []
  );

  const dispatchForOnRequestEmailSend = useCallback(
    (body) => {
      callOnRequestEmailSendAPI(body).then((response) => {
        const newContactNegotiations = response;
        fetchContactDispatch(FetchContact(newContactNegotiations));
      });
    },
    [callOnRequestEmailSendAPI, fetchContactDispatch]
  );


  return {
    callFetchAllContactsApi,
    dispatchForStatisticParamByText,
    dispatchForContactNegotiationsSearch,
    dispatchForContactNegotiationsAssignedToUpdate,
    dispatchForOnRequestEmailSend,

  };
};
export default useContactSetting;
