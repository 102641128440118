import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import { hasPermission } from "./permisson";

const ProtectedRoute = ({ children, requiredPermissions = [] }) => {
  const location = useLocation();
  const { state: userState } = useAuthenticationContext();
  const navigate = useNavigate();

  const isAuthenticated = userState.userData !== null;

  const hasAccess =
    isAuthenticated && hasPermission(requiredPermissions, userState);

  useEffect(() => {
    if (!hasAccess && isAuthenticated) {
      const toastId = "role-error-toast";
      if (!toast.isActive(toastId)) {
        toast.error(
          "Non hai i permessi necessari per accedere a questa pagina.",
          {
            toastId,
          }
        );
      }
    }
  }, [isAuthenticated, hasAccess]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // if (!hasAccess) {
  //   return <Navigate to="/team" replace state={{ from: location }} />;
  // }
  if (!hasAccess) {
    // navigate(-1);
    return null;
  }

  return children;
};

export default ProtectedRoute;
