import React from "react";
import logo from "assets/images/Starweb.png";

const BrandCard = () => {
  return (
    <div className="bg-white mt-[18px] py-[33px] h-[171px] rounded-[20px] flex items-center justify-center shadow-lg">
      <img className="w-[211px] h-[85px]" src={logo} alt="logo" />
    </div>
  );
};

export default BrandCard;
