import React from "react";

const StatisticsBox = ({ item, ind }) => {
  const { title, subtitle, number } = item;
  return (
    <div className="w-[300px] min-h-[116px] rounded-[30px] py-[16px] pl-[26px] bg-primary">
      <h3
        className={`text-[16px] font-medium text-white ${
          ind < 2 ? "tracking-[0.48px]" : "tracking-tight"
        }`}
      >
        {title}
      </h3>
      <h3 className="text-[32px] font-semibold text-white tracking-[0.48px]">
        {number}
      </h3>
      <h3 className="text-[16px] -mt-[10px] font-medium text-white tracking-[0.48px]">
        {subtitle}
      </h3>
    </div>
  );
};

export default StatisticsBox;
