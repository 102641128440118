// import React from "react";
// import { Autocomplete, Checkbox } from "@mui/material";

// const CustomFilter = ({ handleColumnVisibilityChange }) => {
//   const columns = [
//     "Copertina",
//     "Nome",
//     "Priorità",
//     "CodiceUnivoco",
//     "CodiceIATA",
//     "Città",
//     "Provincia",
//     "Regione",
//     "NumeroVisitatori",
//     "NumeroRichiestePreventivi",
//     "NumerodiPrenotazioni",
//     "RatioPreventivisuVisitatori",
//     "RatioPrenotazionisuVisitatori",
//     "RatioPrenotazionisuPreventivi",
//   ];

//   return (
//     <Autocomplete
//       multiple
//       id="checkboxes-in-select"
//       options={columns}
//       disableCloseOnSelect
//       //   onClick={handleColumnVisibilityChange}
//       renderOption={(props, option, { selected }) => (
//         <li {...props}>
//           <Checkbox
//             type="checkbox"
//             checked={selected}
//             style={{ marginRight: 8 }}
//             name={option}
//             onClick={handleColumnVisibilityChange}
//           />
//           {option}
//         </li>
//       )}
//       style={{ width: 300 }}
//       renderInput={(params) => (
//         <div ref={params.InputProps.ref}>
//           <input
//             placeholder="select columns to hide"
//             type="text"
//             {...params.inputProps}
//             style={{
//               backgroundColor: "#f1f4f9",
//               width: 255,
//               height: "40px",
//               padding: "10px",
//               border: "1px solid #b9bcbf",
//               borderRadius: "5px",
//               fontSize: "16px",
//             }}
//           />
//         </div>
//       )}
//     />
//   );
// };

// export default CustomFilter;
import React, { useEffect, useRef, useState } from "react";

const CustomFilter = ({
  handleColumnVisibilityChange,
  hideColumns,
  columnVisibility,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState(hideColumns);

  const inputRef = useRef(null);

  useEffect(() => {
    const filteredResults = hideColumns.filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  }, [searchTerm, hideColumns]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      }
    };

    if (showSearchDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSearchDropdown]);

  const handleLiClick = (event, option) => {
    if (event.target.tagName !== "INPUT") {
      const newValue = !columnVisibility[option];
      handleColumnVisibilityChange({
        target: {
          value: option,
          checked: newValue,
        },
      });
    }
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setShowSearchDropdown(true);
  };
  return (
    <div className="mr-[20px] relative" ref={inputRef}>
      <input
        ref={inputRef}
        onChange={handleSearchChange}
        onFocus={() => setShowSearchDropdown(true)}
        className="text-[20px] font-semibold text-primary placeholder:text-primary border-[1.5px] border-primary rounded-[5px] w-[217px] h-[42px] bg-primary bg-opacity-[0.13] pl-[17px] pr-[10px]"
        type="text"
        placeholder="Nascondi Colonne"
      />
      {showSearchDropdown && (
        <ul className="bg-white absolute min-w-[250px] z-[400] left-0 top-[45px] max-h-[300px] overflow-auto shadow-lg rounded-[10px]">
          {searchResults.length > 0 ? (
            searchResults.map((option) => (
              <li
                className="py-[10px] cursor-pointer hover:bg-slate-100 transition-all duration-100 px-[16px] flex items-center"
                onClick={(event) => handleLiClick(event, option)}
              >
                <input
                  value={option}
                  checked={columnVisibility[option]}
                  onChange={handleColumnVisibilityChange}
                  className="transform scale-[1.6] mr-[16px]"
                  type="checkbox"
                />
                <label
                  style={{
                    MozUserSelect: "none",
                    KhtmlUserSelect: "none",
                    WebkitUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                  className="text-[20px] font-medium "
                >
                  {option}
                </label>
              </li>
            ))
          ) : (
            <p className="text-[20px] px-[16px] py-[10px] text-opacity-50 text-gray-500 font-medium">
              No options
            </p>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomFilter;
