import { Box, Button, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useState } from "react";
import CustomFilter from "../CustomFilter/CustomFilter";
import { format } from "date-fns";

import { useNavigate } from "react-router-dom/dist";

export default function TouristicDestinationTable({
    rows,
    enableDisable,
    deleteRecord,
    onRowClicked,
    rightBtnTxt,
    rightBtnClick,
}) {
    const defaultColDef = useMemo(() => {
        return {
            filter: true,
            // filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, []);

    const [columnVisibility, setColumnVisibility] = useState({
        Name: false,
        Images:false,
        StartingPrice: false,
        Order: false,
        PackageType: false,
        ExternalRedirect: false,
        CreatedDate: false,
    });


    const enableDisableLocal = (e, params) => {
        const confirmed = window.confirm(
            `Do you want to disable the ${params.node.data.name}?`
        );

        if (confirmed) {
            e.target.checked = !params.node.data.isActive;

            enableDisable(params.node.data.id, !params.node.data.isActive);
        } else {
            e.target.checked = params.node.data.isActive;
        }
    };

    const deleteLocal = (e, params) => {
        const confirmed = window.confirm(
            `Do you want to delete the ${params.node.data.name}?`
        );

        if (confirmed) {
            deleteRecord(params.node.data.id);
        }
    };

    const handleColumnVisibilityChange = (event) => {
        const columnName = event.target.value;
        const isChecked = event.target.checked;
    
        setColumnVisibility({
          ...columnVisibility,
          [columnName]: isChecked,
        });
    };

    const hideColumns = [
        "Name",
        "Images",
        "StartingPrice",
        "Order",
        "PackageType",
        "ExternalRedirect",
        "CreatedDate",
    ];

    const columns = [
        {
            // field: "Copertina",ssss
            headerName: "Attivo",
            maxWidth: 80,
            suppressMenu: true,
            cellRenderer: function (params) {
                return (
                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <input
                            type="checkbox"
                            checked={params.data.isActive}
                            onChange={(e) => enableDisableLocal(e, params)}
                        />
                        <img
                            src="/static/media/delete.5756e7189ef69e97a5d76639b98b91ee.svg"
                            alt="img"
                            onClick={(e) => deleteLocal(e, params)}
                        ></img>
                    </div>
                );
            },
        },

        {
            field: "Images",
            headerName: "Destination Image ",
            hide: columnVisibility.Images,
            minWidth: 150, 
            cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
            cellRenderer: (params) => {
                return params?.data?.images && params?.data?.images[0] ? (
                    <img
                      src={params.data.images[0]}
                      alt="Image"
                      style={{ maxWidth: "30%", height: "auto" }}
                    />
                  ) : "-";
            },
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 150,
            hide: columnVisibility.Name,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => params.value ? params.value : '-',
        },
        {
            field: "startingPrice",
            headerName: "Starting Price",
            minWidth: 150,
            hide: columnVisibility.StartingPrice,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => params.value ? params.value : '-',
        },
        {
            field: "order",
            headerName: "Order",
            minWidth: 150,
            hide: columnVisibility.Order,
            filter: "agNumberColumnFilter",
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => params.value ? params.value : '-',
        },
        {
            field: "packageType",
            headerName: "Package Type",
            minWidth: 150,
            hide: columnVisibility.PackageType,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) =>{
                return  params.value === "1"
                                ? "External Redirect"
                                : params.value === "2"
                                ? "Plain Query"
                                : params.value === "3"
                                ? "Landing Page"
                                : "-";
            },
        },
        {
            field: "ExternalRedirect",
            headerName: "External Redirect Url",
            minWidth: 200,
            hide: columnVisibility.ExternalRedirect,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => params.value ? params.value : '-',
        },
        {
            field: "createdDate",
            headerName: "Created When",
            width: 200,
            hide: columnVisibility.CreatedDate,
            cellStyle: { textAlign: 'center' },
            valueFormatter: (params) => {
                return params.value
                  ? format(new Date(params.value), " dd MMM yyyy")
                  : '-';
              },
        },
        
    ];

    return (
        <div className="ag-theme-quartz" style={{ height: 350 }}>
            <Box
                sx={{
                    margin: "30px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <CustomFilter
                    hideColumns={hideColumns}
                    columnVisibility={columnVisibility}
                    handleColumnVisibilityChange={handleColumnVisibilityChange}
                />
                <Box className="text-[20px] font-semibold text-primary placeholder:text-primary border-[1.5px] border-primary rounded-[5px] px-[10px] h-[42px] bg-primary bg-opacity-[0.13]">
                    <Button onClick={rightBtnClick}>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                color: "#26529C",
                                fontWeight: "600",
                                textTransform: "none",
                            }}
                        >
                            {rightBtnTxt}
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <AgGridReact
                onCellClicked={onRowClicked}
                rowData={rows}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                includeHiddenColumnsInQuickFilter={true}
                pagination={true}
                paginationPageSize={10}
            />
        </div>
    );
}