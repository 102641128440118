import axiosConfig from "../axiosConfig";
class AuthenticationService {
  loginUser = async (credentials) => {
    try {
      const response = await axiosConfig.post("/Employee/login", credentials);
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Login failed");
    }
  };

  newUser = async (credentials, accessToken) => {
    try {
      const response = await axiosConfig.post(
        `/Employee/resetPassword`,
        credentials,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Password change failed"
      );
    }
  };

  forgotPassword = async (email) => {
    try {
      if (!email) {
        throw new Error("Email not specified");
      }
      const response = await axiosConfig.get(
        `/Employee/forgotPassword/${email}`
      );
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Forgot password request failed"
      );
    }
  };

  resetPassword = async (credentials) => {
    try {
      const response = await axiosConfig.post(
        `/Employee/resetPassword`,
        credentials
      );
      return response;
    } catch (error) {
      console.log(error);
      throw new Error(error.response?.data?.message || "Reset password failed");
    }
  };

  fetchEmployeeById = async (employeeId) => {
    try {
      if (!employeeId) {
        throw new Error("Employee ID not specified");
      }
      const response = await axiosConfig.get(`/Employee/get/${employeeId}`);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Fetch employee by ID failed"
      );
    }
  };

  fetchAllEmployees = async () => {
    try {
      const response = await axiosConfig.get(`/Employee/getAll`);
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Fetch all employees failed"
      );
    }
  };
  createEmployee = async (employeeData) => {
    try {
      const response = await axiosConfig.post(
        "/Employee/create",
        employeeData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response;
    } catch (error) {
      console.error("Error creating employee:", error.response?.data);
      throw new Error(
        error.response?.data?.message || "Create employee failed"
      );
    }
  };
  updateEmployee = async (employeeData) => {
    try {
      const response = await axiosConfig.post(
        "/Employee/update",
        employeeData,

        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Employee update failed"
      );
    }
  };
  deleteEmployee = async (employeeId) => {
    try {
      const response = await axiosConfig.delete(
        `/Employee/delete/${employeeId}`
      );
      return response;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Employee delete failed"
      );
    }
  };
}

const instance = new AuthenticationService();

export default instance;
