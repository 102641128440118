import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Button, Typography } from "@mui/material";
import deleteIcon from "../../assets/delete.svg";
import circularIcon from "../../assets/circular.svg";
import HotelDashboardService from "../../library/services/API/hotelDashboard";
import BlogModel from "../../Pages/BlogModel/BlogModel";
import { truncateText } from "../../utils/functions/common";

const scrollbarStyles = `
    ::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent; /* make scrollbar transparent */
    }
`;

export default function BlogCard() {
  const [dataArr, setDataArr] = useState([]);
  const [items, setItems] = useState(dataArr);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [imageDetails, setImageDetails] = useState();

  const updateDataArr = (index, newData) => {
    const updatedDataArr = [...dataArr];
    updatedDataArr[index] = { ...newData };
    setDataArr(updatedDataArr);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await HotelDashboardService.getBlogs();
        setDataArr(response);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setItems(dataArr);
  }, [dataArr, updateDataArr]);

  const handleAddButton = () => {
    setIsModalOpen(true);
    setIsEdit(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setImageDetails();
    setIsEdit(false);
  };

  const handleDeleteImage = async (index, blogId) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
      await HotelDashboardService.deteleBlog(blogId);
      try {
        const response = await HotelDashboardService.getBlogs();
        setDataArr(response);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    }
  };

  const handleEditImage = (index, item) => {
    setImageDetails(item);
    setEditIndex(index);
    setIsModalOpen(true);
    setIsEdit(true);
  };
  return (
    <>
      <style>{scrollbarStyles}</style>
      <Box sx={{ overflowX: "auto", overflowY: "hidden", borderRadius: "5px" }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginTop: "10px", display: "flex" }}>
            {items?.map((item, index) => (
              <Box
                sx={{
                  width: "462px",
                  height: "465px",
                  border: "1px solid #26529C",
                  marginRight: "20px",
                  borderRadius: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      width: "461px",
                      height: "289px",
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "32px",
                      fontWeight: "600",
                      textDecoration: "underline",
                      flexShrink: 0,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item.imageUrl}
                      alt="image"
                      style={{
                        width: "461px",
                        maxWidth: "461px",
                        maxHeight: "289px",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "70px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditImage(index, item)}
                  >
                    <img src={circularIcon} alt="img" />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "30px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="img"
                      onClick={() => handleDeleteImage(index, item?.id)}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: "22px",
                    marginTop: "15px",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  {moment(item.createdWhen).format("DD MMM YYYY")}
                </Box>
                <Box
                  sx={{
                    marginLeft: "22px",
                    marginTop: "5px",
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  {truncateText(item.articleName, 60)}
                </Box>
                <Box
                  sx={{
                    marginLeft: "22px",
                    marginTop: "5px",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {truncateText(item.description, 110)}
                </Box>
              </Box>
            ))}
            {(dataArr?.length === 0 || dataArr?.length < 2) && (
              <Button
                sx={{
                  marginLeft: "0px",
                  width: "489px",
                  height: "465px",
                  border: "1px dashed #26529C",
                  borderWidth: "2px",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                }}
                onClick={handleAddButton}
              >
                <Typography
                  sx={{
                    color: "#26529C",
                    textDecoration: "underline",
                    fontSize: "32px",
                    fontWeight: "600",
                    textTransform: "none",
                  }}
                  component="div"
                >
                  Upload Article <br />
                  Highlighted
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      {isModalOpen && (
        <BlogModel
          onClose={handleCloseModal}
          updateDataArr={updateDataArr}
          index={editIndex}
          item={items[editIndex]}
          imageDetails={imageDetails}
          isEdit={isEdit}
          setDataArr={setDataArr}
        />
      )}
    </>
  );
}
