import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Button,
  Typography,
  Grid,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHotelSearchParamContext } from "../../library/store/slices/HotelSearchParamsSlicer";

export default function LHiddenDynamicBox(props) {
  const {
    selectedTagOption,
    setSelectedTagOption
  } = props;
  const { state: searchParamResponse } = useHotelSearchParamContext();
  const [autoCompleteList, setAutoCompleteList] = useState([]);

  useMemo(() => {
    let hotelList = [];

    let param;

    for (const property in searchParamResponse) {
      param = searchParamResponse[property];
      hotelList.push({
        id: param.key,
        title: param.description,
        ...param,
      });
    }
    const sortedHotelList = hotelList
      .slice()
      .sort((a, b) => a.title.localeCompare(b.title));
    setAutoCompleteList(sortedHotelList);
  }, [searchParamResponse]);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleTagChange = (event, newValue) => {
    setSelectedTagOption(newValue);
  };

  return (
    <Box sx={{ marginTop: "0px" }}>
      <Box sx={{ marginLeft: "30px", width: "876px" }}>
        <Grid container spacing={1} alignItems="center">
          <Autocomplete
            sx={{ marginTop: 1 }}
            fullWidth
            multiple
            id="checkboxes-tags-demo"
            options={autoCompleteList}
            value={selectedTagOption} // Set the selected options here
            getOptionLabel={(option) => option.title}
            onChange={handleTagChange}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  {option.title}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Seleziona Località da Collegare"
              />
            )}
          />
        </Grid>
      </Box>
    </Box>
  );
}
