import { notifications } from "Pages/Team/dummy/dummyData";
import React, { useState } from "react";

export const DashboardNotification = () => {
  const groupNotificationsByDate = (notifications) => {
    return notifications.reduce((acc, notification) => {
      if (!acc[notification.date]) {
        acc[notification.date] = [];
      }
      acc[notification.date].push(notification);
      return acc;
    }, {});
  };

  const [notificationGroups, setNotificationGroups] = useState(
    groupNotificationsByDate(notifications)
  );

  return (
    <div className="rounded-[20px] h-[1304px] bg-white overflow-y-auto shadow-lg pb-[27px]">
      <div className="h-[12px] bg-white z-10 sticky top-0" />
      {Object.entries(notificationGroups).map(([date, notifications]) => (
        <div key={date}>
          {/* Header  */}
          <div className="flex pl-[28px] pr-[24px] h-[58px] items-center space-x-5 sticky top-[12px] z-10 bg-white">
            <h2 className="text-[20px] font-bold text-[#171B1E]">{date}</h2>
            <div className="border-[1px] flex-1 border-[#D7D7D7] border-dashed" />
          </div>
          {/* notification list  */}
          {notifications.map((notification, ind) => (
            <div
              key={notification.id}
              className={`flex items-center ${
                ind === 0 ? "" : "mt-[14px]"
              } space-x-[17px] hover:bg-gray-100 transition-all duration-200 pl-[29px] pr-[13px]`}
            >
              <img
                className="w-[51px] h-[51px] rounded-full"
                src={notification?.icon}
                alt="icon"
              />
              <div>
                <h2 className="text-[18px] font-medium line-clamp-2 text-[#171B1E]">
                  {notification.title}{" "}
                  <strong>{notification.highlightedContent}</strong>
                </h2>
                <p className="text-[16px] font-normal text-[#717579] mt-[4px] leading-[24px]">
                  {notification?.time}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
      {/* <div className="flex mt-[11px] items-center space-x-[17px]">
        <img
          className="w-[51px] h-[51px] rounded-full"
          // src={icon2}
          alt="icon2"
        />
        <div>
          <h2 className="text-[18px] font-medium line-clamp-2 text-[#171B1E]">
            Marco Sciosia ti ha assegnato una task{" "}
            <strong>
              “Controlla tutti i contatti che hanno richiesto un preventivo in
            </strong>
          </h2>
          <p className="text-[16px] leading-[24px] font-normal text-[#717579] mt-[4px]">
            6 minuti fa{" "}
          </p>
        </div>
      </div> */}
    </div>
  );
};
