import Layout from "../../Components/Layout/Layout";
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import AboutContactForm from "../ContactNegotiationDetailPages/components/AboutContactForm";
import InformationAboutActivityForm from "../ContactNegotiationDetailPages/components/InformationAboutActivityForm";
import SearchField from "../../Components/Search/SearchField";
import LeftSideHeader from "../ContactNegotiationDetailPages/components/LeftSideHeader";
import LeftSideContactInformation from "../ContactNegotiationDetailPages/components/LeftSideContactInformation";
import ReservationsMadeSection from "../ContactNegotiationDetailPages/components/ReservationsMadeSection";
import RequestQuotesSection from "../ContactNegotiationDetailPages/components/RequestQuotesSection";
import FavoriteHotelList from "../ContactNegotiationDetailPages/components/FavoriteHotelList";
import ClicksLogComponent from "../ContactNegotiationDetailPages/components/ClicksLogComponent";
import ConfigurationsComponent from "../ContactNegotiationDetailPages/components/ConfigurationsComponent";
import SearchInput from "../../Components/Search/SearchInput";
import TabBar from "../../Components/Tab/TabBar";
import Slider from "../ContactNegotiationDetailPages/components/Slider";
import useContactSetting from "../../library/hooks/Contact/Contact";
import { useContactContext } from "../../library/store/slices/ContactSlicer";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";
import Loading from "Components/Loading/Loading";
import ContactService from "../../library/services/API/contact";

const NegotiationDetails = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [negotiationsInfo, setNegotiationsInfo] = React.useState();
  const [employeeList, setEmployeeList] = React.useState([]);
  const [centerData, setCeneterData] = React.useState();
  const [isBookingVisible, setIsBookingVisible] = React.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [skip,setSkip]= useState(0);
  const [take,setTake]= useState(5);
  const [reservationList,setReservationsList] = useState([]);
  const [isResLoading,setIsResLoading] = useState(false);


  const { dispatchForContactNegotiationsSearch } = useContactSetting();
  const { state: negotiationsResponse } = useContactContext();

  const { dispatchFetchAllEmployees } = useAuthentication();
  const { dispatchForOnRequestEmailSend } = useContactSetting();
  const { state: employeeResponse } = useAuthenticationContext();

  let { id } = useParams();

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  useEffect(() => {
    setIsLoading(true);
    const body = { search: id };
    dispatchForContactNegotiationsSearch(body);
    dispatchFetchAllEmployees();
  }, [])


  useMemo(() => {
    if (negotiationsResponse != null) {
      setNegotiationsInfo(negotiationsResponse.contactNegotiation)
      if (negotiationsResponse?.quotes?.totelQuotes > 0) {
        setCeneterData(negotiationsResponse.quotes?.quotesList[0]);
        setIsBookingVisible(false);
      } else {
        if (negotiationsResponse?.reservations?.totelReservations > 0) {
          setReservationsList(negotiationsResponse?.reservations.reservationsList);
          setCeneterData(negotiationsResponse.reservations?.reservationsList[0]);
          setIsBookingVisible(true);
        }
      }
      setIsLoading(false);
    }
  }, [negotiationsResponse]);

  const onChangeCenterData = (arrayList, isReservation) => {
    const index = arrayList.findIndex(value => value === true);
    if (index > -1) {
      if (isReservation) {
        var res = reservationList[index];
        setCeneterData(res);
      } else {
        var res = negotiationsResponse.quotes?.quotesList[index];
        setCeneterData(res);
      }
    }
  }
  
  const onLoadMoreData = async (pageCount)=>{
    setIsResLoading(true);
    const request = {search: id,skip:pageCount,take:5};
    const response = await ContactService.fetchContactNegotiations(request);
    if (response) {
      if(response?.reservations?.totelReservations > 0){
        setReservationsList((prevRes)=>{
          const newRoomSelected = [
            ...prevRes,
            ...response?.reservations?.reservationsList
          ];
          return newRoomSelected;
        })
        setIsResLoading(false);
      }
    }

  }

  const onSendEmail = async () => {
    setIsLoading(true);
    const request = {
      isActive: centerData?.bookingFileStatus?.status == "Quote Requested" ? true : false,
      id: negotiationsResponse?.contactNegotiation?.id,
      bookingFileCode: centerData?.bookingId
    }
    const response = await ContactService.onRequestSendEmail(request);
    if (response) {
      const body = { search: id };
      dispatchForContactNegotiationsSearch(body);
    } else {
      setIsLoading(false);
    }
  }

  useMemo(() => {
    const empDataList = employeeResponse;
    if (empDataList.allEmployees != null && empDataList.allEmployees.length > 0) {
      setEmployeeList(empDataList.allEmployees)
    }
  }, [employeeResponse]);

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Loading isLoading={isLoading} />
      <div className="flex items-start w-full h-full overflow-hidden">
        {/* Left Component  */}
        <div className="bg-primary bg-opacity-10 h-full overflow-auto w-[30%] pb-[50px]">
          <LeftSideHeader info="negotiation" />
          <LeftSideContactInformation info={negotiationsInfo} />
          <div className="mt-[22px] border-[1.3px] border-primary w-full" />
          <AboutContactForm employees={employeeList} info={negotiationsResponse} type="negotiation" />
        </div>
        {/* Middle Component  */}
        <div className="bg-white  pb-[50px] h-full overflow-auto w-[40%]">
          {/* <div className="flex flex-col mt-[40px] items-center w-full">
            <SearchField />
          </div> */}
          {centerData && (
            <div className="bg-[#D9D9D9] mx-[19px] mt-[40px] py-2 pl-[13px] pr-[30px] rounded-[10px] bg-opacity-[0.28] flex items-center justify-between">
              <h2 className="text-[17px] font-bold text-primary">Prenotazione su Richiesta</h2>

              <div>
                <button
                  className={`button ${centerData?.bookingFileStatus?.status === "Quote Requested" ? "Reqprimary" : "ReqDprimary"}`}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => onSendEmail()}
                >
                  {centerData?.bookingFileStatus?.status === "Quote Requested" ? "Attiva" : "DISATTIVA"}
                </button>
              </div>
            </div>
          )}

          {/* conter section */}
          <Slider info={centerData} isNegotiationsPage={true} isBooking={isBookingVisible} />
        </div>
        {/* Right Component */}
        <div className="bg-primary bg-opacity-10 h-full overflow-auto w-[30%] pb-[50px] text-primary text-[16px] ">
          <RequestQuotesSection info={negotiationsResponse} onChangeCenterData={onChangeCenterData} />
          <ReservationsMadeSection info={reservationList} onChangeCenterData={onChangeCenterData} onLoadMoreData={onLoadMoreData} totelReservations={negotiationsResponse?.reservations?.totelReservations} isResLoading={isResLoading}/>
        </div>
      </div>
    </Layout>
  );
};

export default NegotiationDetails;
