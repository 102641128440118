import React from "react";
import hotel from "assets/images/Hotel1.png";
import hotel2 from "assets/images/Hotel2.png";
import hotel3 from "assets/images/hotel3.png";

const hotelsData = [
  {
    id: 1,
    image: hotel,
    title: "Luxurious Hotel AV Isola Verde with Spectacular Ocean Views",
    visitors: 1.235,
  },
  {
    id: 2,
    image: hotel2,
    title: "Tranquil Retreat: Hotel Paradise Amidst Pristine Nature",
    visitors: 1.235,
  },
  {
    id: 3,
    image: hotel3,
    title:
      "Grand Hotel Excelsior: A Historic Landmark Offering Opulent Accommodations",
    visitors: 1.235,
  },
];

const topDestinations = [
  { id: 1, place: "Trentino", visitors: 12.234 },
  { id: 2, place: "Campania", visitors: 9.234 },
  { id: 3, place: "Sardegna", visitors: 6.234 },
];

const TopHotels = () => {
  return (
    <div className="flex w-[60%] items-center h-[271px] bg-white rounded-[20px] shadow-lg pl-[32px] pr-[35px]">
      <div className="pt-[22px]  w-[55%] pb-[29px] pr-[29px]">
        <h2 className="text-[##171B1E] text-[20px] font-bold leading-[30px]">
          Top 3 Hotel
        </h2>
        <div className="mt-[10px]">
          {hotelsData.map(({ id, image, title, visitors }) => (
            <div
              key={id}
              className="flex items-center space-x-[11px] mt-[10px]"
            >
              <img
                className="w-[50px] h-[50px] rounded-[10px] object-cover"
                src={image}
                alt="hotel_image"
              />
              <div>
                <h2 className="text-[16px] font-medium line-clamp-1 text-[##171B1E] leading-[24px]">
                  {title}
                </h2>
                <p className="text-[16px] font-semibold leading-[24px] text-[#26529C]">
                  {visitors}{" "}
                  <span className="text-[12px] text-[#171B1E]">Visitatori</span>{" "}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="border-[1px] w-[1px] border-[#D7D7D7] border-dashed h-full" />
      <div className="pt-[22px]  w-[45%] pl-[37px]">
        <h2 className="text-[##171B1E] text-[20px] font-bold leading-[30px]">
          Top 3 Destinazioni
        </h2>
        <div className="mt-[8px]">
          {topDestinations.map(({ id, place, visitors }, index) => (
            <div
              key={id}
              className="flex items-center space-x-[11px] mt-[15px]"
            >
              {/* <img
                className="w-[50px] h-[50px] rounded-[10px] object-cover"
                src={image}
                alt="hotel_image"
              /> */}
              <h2 className="text-[#D7D7D7] font-medium text-[58px] leading-[0px]">
                {index + 1}
              </h2>
              <div>
                <h2 className="text-[16px] font-medium text-[##171B1E] leading-[24px]">
                  {place}
                </h2>
                <p className="text-[16px] font-semibold leading-[24px] text-[#26529C]">
                  {visitors}{" "}
                  <span className="text-[12px] text-[#171B1E]">Visitatori</span>{" "}
                </p>
              </div>
            </div>
          ))}
          <p className="text-[12px] text-[#9291A5] font-thin text-end -mr-[20px]">
            Ultimi 7 Giorni
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopHotels;
