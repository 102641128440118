import axiosConfig from "../axiosConfig";
class NegotiationService{

    fetchAllNegotiations = async(body) => {
         return await axiosConfig.get(`/Dashboard/Hotels/Negotiations`, {
            params: body,
          });
        // return await axiosConfig.get(`/Dashboard/Hotels/Negotiations/${payload.pageNumber}/${payload.pageSize}`);
    };
}

const instance = new NegotiationService();

export default instance;