import React from "react";

const PopularContantSource = () => {
  return (
    <div className="bg-white shadow-lg rounded-[20px] h-[271px] pt-[23px] pl-[32px] pr-[15px]">
      <h2 className="text-[18px] leading-[20px] font-bold text-[#1E1B39]">
        Fonti Contatto Popolari
      </h2>
      <div className="mt-[24px] space-y-[19px]">
        <div className="flex items-center space-x-[15px]">
          <h3 className="text-[14px] leading-[16px] w-[25%] min-w-[101px] truncate  font-normal text-[#615E83]">
            Direct-Organic
          </h3>
          <div className="w-[75%]">
            <div className="bg-[#4A3AFF] h-[12px] flex items-center rounded-[4px] w-[67%] relative after:content-['67%'] after:absolute after:text-white after:right-[2px] after:text-[6px] after:font-semibold" />
          </div>
        </div>
        <div className="flex items-center space-x-[15px]">
          <h3 className="text-[14px] w-[25%] leading-[16px]  min-w-[101px] truncate   font-normal text-[#615E83]">
            Social Facebook
          </h3>
          <div className="w-[75%]">
            <div className="bg-[#E0C6FD] h-[12px] rounded-[4px] w-[16%] relative flex items-center after:content-['12%'] after:font-semibold after:absolute after:text-white after:right-[2px] after:text-[6px]" />
          </div>
        </div>{" "}
        <div className="flex items-center space-x-[15px]">
          <h3 className="text-[14px] w-[25%] leading-[16px] min-w-[101px] truncate  font-normal text-[#615E83]">
            Social Instagram
          </h3>
          <div className="w-[75%]">
            <div className="bg-[#962DFF] h-[12px] rounded-[4px] w-[8%] relative flex items-center after:content-['4%'] after:font-semibold after:absolute after:text-white after:right-[2px] after:text-[6px]" />
          </div>
        </div>
        <div className="flex items-center space-x-[15px]">
          <h3 className="text-[14px] w-[25%] leading-[16px] min-w-[101px] truncate  font-normal text-[#615E83]">
            Google Ads
          </h3>
          <div className="w-[75%]">
            <div className="bg-[#c6d2fe] h-[12px] rounded-[4px] w-[6%] relative flex items-center after:content-['2%'] after:font-semibold after:absolute after:text-white after:right-[2px] after:text-[6px]" />
          </div>
        </div>{" "}
        <div className="flex items-center space-x-[15px]">
          <h3 className="text-[14px] w-[25%] leading-[16px] min-w-[101px] truncate  font-normal text-[#615E83]">
            Altro
          </h3>
          <div className="w-[75%]">
            <div className="bg-[#962DFF] h-[12px] rounded-[4px] w-[4%] relative flex items-center after:content-['1%'] after:font-semibold after:absolute after:text-white after:right-[2px] after:text-[6px]" />
          </div>
        </div>
        <div className="ml-[114px] !mt-[13px] flex items-center justify-between">
          <p className="text-[14px] font-normal text-[#615E83]">0%</p>
          <p className="text-[14px] font-normal text-[#615E83]">50%</p>
          <p className="text-[14px] font-normal text-[#615E83]">100%</p>
        </div>
      </div>
    </div>
  );
};

export default PopularContantSource;
