export const initialState = [];


const NegotiationsAction = {
    FetchNegotiations: "FetchNegotiations",
}

export function FetchNegotiations(response) {
    return {
        type: NegotiationsAction.FetchNegotiations,
        payload: {
            value: response,
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case NegotiationsAction.FetchNegotiations:
            const newState = action.payload.value;
                 return newState
        default:
            throw new Error('Error while Get Negotiations Request: Negotiations.reducer');    
    }
}

const NegotiationsReducer = { initialState, reducer }

export default NegotiationsReducer;