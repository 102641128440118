import axiosConfig from "../axiosConfig";
class SettingService {
  fetchAllTags = async () => {
    return await axiosConfig.get(`/Search/Hotels/Data/Tags`);
  };

  saveAllTag = async (body) => {
    return await axiosConfig.post(`/setting/save`, body);
  };

  fetchSetting = async () => {
    return await axiosConfig.get(`/setting`);
  };

  GetContactConfiguration = async (id) => {
    return await axiosConfig.get(`/setting/GetContactConfiguration/`+id);
  };

  GetNegotiationConfiguration = async (id) => {
    return await axiosConfig.get(`/setting/GetNegotiationConfiguration/`+id);
  };

  saveContactConfiguration = async (body) => {
    return await axiosConfig.post(`/setting/saveContactConfiguration`,body);
  };

  saveNegotiationConfiguration = async (body) => {
    return await axiosConfig.post(`/setting/saveNegotiationConfiguration`,body);
  };

}

const instance = new SettingService();

export default instance;
