
const initialState = [
  
];

const FetchHotelDashboardFilterAction = {
    FetchAllHotelsFilter: "HotelsDashboarFilter",
}

export function HotelsDashboarFilter(response) {
    return {
        type:FetchHotelDashboardFilterAction.FetchAllHotelsFilter,
        payload: {
            value: response
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case FetchHotelDashboardFilterAction.FetchAllHotelsFilter:
            const newState = action.payload.value;
            return newState;
            default:
                throw new Error('Error while updating Hotel Dashboard Request: HotelDashboardReducer.reducer');    
    }
}


const hotelsDashboardFilterReducer = { initialState, reducer }

export default hotelsDashboardFilterReducer;