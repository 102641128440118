// utils/formatDate.js

export function formatDate(dateString) {
    if (!dateString) {
        return ""; // Return an empty string if dateString is undefined, null, or falsy
    }
    const date = new Date(dateString);
  
    // Define months in Italian
    const months = [
      "gen", "feb", "mar", "apr", "mag", "giu", 
      "lug", "ago", "set", "ott", "nov", "dic"
    ];
  
    const day = date.getUTCDate(); // Get the day of the month
    const month = months[date.getUTCMonth()]; // Get the month (from 0 to 11)
    const year = date.getUTCFullYear(); // Get the year
    const hours = date.getUTCHours(); // Get the hours
    const minutes = date.getUTCMinutes(); // Get the minutes
  
    // Format the time as "hh:mm"
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  
    // Construct the final formatted string
    return `${day} ${month} ${year} alle ${formattedTime}`;
  }
  

  export function formatToDDMMYYYY(dateString) {
    const date = new Date(dateString);
  
    const day = date.getUTCDate().toString().padStart(2, '0'); // Get the day of the month
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based, so add 1)
    const year = date.getUTCFullYear(); // Get the year
  
    // Return the formatted date as "dd/mm/yyyy"
    return `${day}/${month}/${year}`;
  }

  export function formatDateRange(startDate) {
    if (!startDate) {
      return ''; 
    }
    const date = new Date(startDate);
    const day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);

    // Combine the parts to form the desired format: dd MM yyyy
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  export function calculateTimeLeft(targetDate) {
    const now = new Date();
    const target = new Date(targetDate);
    let timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    if (targetDate && !isNaN(target.getTime())) {
        const difference = target - now;
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
    }

    return timeLeft;
}