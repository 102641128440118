import { Box, Checkbox, Typography, Grid } from "@mui/material";

export default function SelectCheckbox({ label, items ,handleCheckboxChange}) {
  return (
    <Grid container spacing={1}>
      {items.map((item, index) => (
        <Grid xs={12} md={6} key={index}>
          <Box sx={{ display: "flex" }}>
            <Checkbox {...label} checked={item.isChecked} name={item.displayName} onChange={handleCheckboxChange} sx={{ width: "16px", height: "16px", marginTop: "4px" }} />
            <Typography sx={{ marginLeft: "10px", fontSize: "16px", fontWeight: "400" }}>
              {item.displayName}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}