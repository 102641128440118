import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import Stack from "@mui/material/Stack";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import LandingPageSidebar from "../../Components/LandingPageSidebar/LandingPageSidebar";
import LandingPageInfo from "../../Components/LandingPageInfo/LandingPageInfo";
import LandingPageExtra from "../../Components/LandingPageExtra/LandingPageExtra";
import LandingPageHotel from "../../Components/LandingPageHotel/LandingPageHotel";
import EventPackageSidebar from "../../Components/EventPackageSidebar/EventPackageSidebar";
import EventPackageInfo from "../../Components/EventPackageInfo/EventPackageInfo";
import useEventPackage from "../../library/hooks/useEventPackage/useEventPackage";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#26529c",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      color: "#26529c",
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  separator: {
    fontSize: "20px",
    color: "#26529c",
  },
}));

export default function EventPackagePage() {
  const navigate = useNavigate();
  let { eventPackageId } = useParams();

  /**
   * Api Context
   */
  const {
    callFetchEventPackageByIdApi,
    callCreateEventPackageApi,
    callUpdateEventPackageApi,
  } = useEventPackage();
  const [form, setForm] = useState({
    id: "-",
    name: "",
    order: null,
    isActive: false,
    createdDate: new Date().toISOString(),
    createdBy: "-",
    landingPageId: ""
  });

  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const classes = useStyles();

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const CustomSeparator = () => (
    <Typography variant="body1" className={classes.separator}>
      ›
    </Typography>
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to="/"
      className={classes.link}
    >
      HoEscape
    </Link>,
    <Link underline="hover" key="2" color="inherit" className={classes.link}>
      Create Event Package
    </Link>,
  ];

    /**
   * Handler Functions
   */
    const saveInformation = async (
      name,
      order,
      landingPageId
    ) => {
   
      let tempObject = {
        ...form,
        name: name,
        order: order == null ? 0 : order,
        landingPageId: landingPageId == null? 0: landingPageId,
      };
      console.log(tempObject,"<==Temp")
      await save(tempObject);
    };

    //Save function

    const save = async (saveObject) => {
      //call Api
      if (saveObject.id === "-") {
        await callCreateEventPackageApi(
          {
            ...saveObject
          },
        )
          .then((res) => {
            if (res.isSuccess) {
              navigate(`/landing-page/0/0/${res.data.id}`);
              
            } else {
              console.error("Error: Create Event Package", res.message);
            }
          })
          .catch((err) => {
            console.error("Error: Create Event Package", err);
          });
      } else {
        await callUpdateEventPackageApi(
          {
            ...saveObject,
          },
        )
          .then((res) => {
            if (res.isSuccess) {
             
                navigate(
                  `/landing-page/${
                    res.data.landingPageId !== null &&
                    res.data.landingPageId !== undefined &&
                    res.data.landingPageId !== ""
                      ? res.data.landingPageId
                      : "0"
                  }/0/${res.data.id}`
                );
             
            } else {
              console.error("Error: Update Event Package", res.message);
            }
          })
          .catch((err) => {
            console.error("Error: Update Event Package", err);
          });
      }
    };
  // Setup Function

  const setupForm = async (data) => {
    if (!data) return;
    // assign values to form
    setForm({
      ...data,
    });
  };

  /**
   * side Effects
   */

  useEffect(() => {
    if (eventPackageId) {
      callFetchEventPackageByIdApi(eventPackageId)
        .then((res) => {
          if (res && res.data) {
            setupForm(res.data);
          } else {
            alert("No record Found!");
            navigate("/hoescape");
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    }
  }, [eventPackageId]);

  return (
    <Layout handleToggle={handleToggle} isSidebarOpened={isSidebarOpened}>
      <Box sx={{ height: "48px", padding: "10px" }}>
        <Stack spacing={2} sx={{ marginLeft: "20px" }}>
          <Breadcrumbs separator={<CustomSeparator />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        sx={{ backgroundColor: "#f2f2f2", minHeight: "90vh", padding: "40px" }}
      >
        <Box sx={{ display: "flex" }}>
          <EventPackageSidebar />
          <EventPackageInfo 
             propEventName={form.name}
             propEventOrder={form.order}
             propEventLandingPageId = {form.landingPageId}
             sendValueToParent={saveInformation}
          
          />
        </Box>
      </Box>
    </Layout>
  );
}
