import editIcon from "../../../assets/icon/edit.svg";
import emailIcon from "../../../assets/icon/email.svg";
import whatsappIcon from "../../../assets/icon/whatsapp.svg";
import callIcon from "../../../assets/icon/call.svg";
import threeDotsIcon from "../../../assets/icon/three_dots.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import SecondaryButton from "../../../Components/Button/SecondaryButton";
import PrimaryButton from "../../../Components/Button/PrimaryButton";

const ActionButtons = () => {
  const [openNoteModal, setOpenNoteModal] = useState(false);

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  const addNote = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const note = formJson.note;
    console.log(note);
    handleCloseNoteModal();
  };

  return (
    <div className="mt-[21px] flex items-center space-x-[22px] pl-[38px]">
      <button
        onClick={() => setOpenNoteModal(true)} 
        className="flex-col flex items-center justify-center"
      >
        <div className="w-[49px] h-[49px] cursor-pointer rounded-full flex items-center justify-center bg-primary bg-opacity-[0.07] border-[1px] border-primary border-opacity-30">
          <img className="w-[25px] h-[25px]" src={editIcon} alt="edit-icon" />
        </div>
        <p className="text-primary text-center mt-[6px] text-[15px] font-normal leading-5">
          Note
        </p>
      </button>
      <Dialog
        open={openNoteModal}
        onClose={handleCloseNoteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          component: "form",
          onSubmit: addNote,
          sx: {
            width: "400px",
            maxHeight: "500px",
            paddingBottom: "15px",
            paddingRight: "15px",
          },
        }}
      >
        <DialogTitle
          sx={{ color: "#26529C", fontWeight: "600" }}
          id="alert-dialog-title"
        >
          Add Note
        </DialogTitle>
        <DialogContent sx={{ paddingRight: "8px" }}>
          <textarea
            name="note"
            rows={4}
            placeholder="Enter your note"
            className="mt-[5px] placeholder:text-primary placeholder:text-opacity-50 bg-primary bg-opacity-[0.07] w-full rounded-[5px] text-primary text-[16px] font-semibold p-4"
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton
            onClick={handleCloseNoteModal}
            backgroundColor="white"
            buttonText="Close"
            width="100px"
            height="36px"
          />
          <PrimaryButton width="100px" buttonText="Add" />
          {/* <Button onClick={handleCloseNoteModal}>Close</Button> */}
          {/* <Button type="submit">Add</Button> */}
        </DialogActions>
      </Dialog>
      <button
        onClick={() => window.open("mailto:")}
        className="flex-col flex items-center justify-center"
      >
        <div className="w-[49px] h-[49px] cursor-pointer rounded-full flex items-center justify-center bg-primary bg-opacity-[0.07] border-[1px] border-primary border-opacity-30">
          <img className="w-[25px] h-[25px]" src={emailIcon} alt="edit-icon" />
        </div>
        <p className="text-primary text-center mt-[6px] text-[15px] font-normal leading-5">
          Email
        </p>
      </button>
      <button
        disabled={true}
        className="flex-col flex items-center justify-center"
      >
        <div className="w-[49px] h-[49px] cursor-pointer rounded-full flex items-center justify-center bg-primary bg-opacity-[0.07] border-[1px] border-primary border-opacity-30">
          <img
            className="w-[25px] h-[25px]"
            src={whatsappIcon}
            alt="edit-icon"
          />
        </div>{" "}
        <p className="text-primary text-center  text-opacity-20 -tracking-[1px] mt-[6px] text-[15px] font-normal leading-5">
          Whatsapp
        </p>
      </button>
      <button
        disabled={true}
        className="flex-col flex items-center justify-center"
      >
        <div className="w-[49px] h-[49px] cursor-pointer rounded-full flex items-center justify-center bg-primary bg-opacity-[0.07] border-[1px] border-primary border-opacity-30">
          <img className="w-[25px] h-[25px]" src={callIcon} alt="edit-icon" />
        </div>
        <p className="text-primary text-center text-opacity-20  mt-[6px] text-[15px] font-normal leading-5">
          Call
        </p>
      </button>
      <button
        disabled={true}
        className="flex-col flex items-center justify-center"
      >
        <div className="w-[49px] h-[49px] cursor-pointer rounded-full flex items-center justify-center bg-primary bg-opacity-[0.07] border-[1px] border-primary border-opacity-30">
          <img
            className="w-[25px] h-[25px]"
            src={threeDotsIcon}
            alt="edit-icon"
          />
        </div>
        <p className="text-primary text-opacity-20  text-center mt-[6px] text-[15px] font-normal leading-5">
          Altro
        </p>
      </button>
    </div>
  );
};

export default ActionButtons;
