import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { AccountCircle, Email, Visibility } from "@mui/icons-material";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import { ReactComponent as EyeOff } from "assets/icons/eye-off.svg";

import PrimaryButton from "Components/Button/PrimaryButton";
import AuthCard from "Components/Card/AuthCard";
import { useNavigate } from "react-router-dom";
import useAuthentication from "library/hooks/UserAuthenticatication/useAuthentication";
import { useAuthenticationContext } from "library/store/slices/AuthenticationSlicer";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { dispatchLoginUser } = useAuthentication();
  const { state: employeeResponse } = useAuthenticationContext();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setPassword(password);
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else {
      setPasswordError("");
    }
  };

  const handleLogin = async () => {
    let hasError = false;
    if (!email) {
      setEmailError("Please enter your email");
      hasError = true;
    }
    if (!password) {
      setPasswordError("Please enter your password");
      hasError = true;
    }

    if (!emailError && !passwordError && !hasError) {
      try {
        const credentials = { email, password };

        const res = await dispatchLoginUser(credentials);
        console.log("res.isPasswordChanged", res.isPasswordChanged);
        if (!res.isPasswordChanged) {
          navigate("/new-user");
        } else {
          navigate("/team", { replace: true });
        }
      } catch (error) {
        console.error("Login failed: ", error);
      }

      // Save local storage
      if (rememberMe) {
        localStorage.setItem("userInfo", JSON.stringify({ email, password }));
      } else {
        localStorage.removeItem("userInfo");
      }
    }
  };

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const { email, password } = JSON.parse(userInfo);
      setEmail(email);
      setPassword(password);
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="bg-white w-screen h-full min-h-screen grid grid-cols-2 p-[21px]">
      <div className="grid place-items-center">
        <div>
          <h1 className="text-[30px] font-medium left-[45px] text-black mb-[39px]">
            Login
          </h1>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              width: 400,
              margin: "auto",
              mt: 5,
            }}
          >
            <TextField
              required
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              onBlur={handleEmailChange}
              helperText={emailError}
              label="Eamil"
              placeholder="Enter your email address"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon width={17} height={17} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: { color: "#26529C", fontSize: 16, fontWeight: 400 },
                sx: {
                  "&::placeholder": {
                    opacity: 1,
                    color: "#26529C",
                  },
                },
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              value={password}
              onChange={handlePasswordChange}
              onBlur={handlePasswordChange} // Trigger validation on blur
              error={Boolean(passwordError)}
              label="Password"
              type={showPassword ? "text" : "password"}
              helperText={passwordError}
              placeholder="Enter your Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon width={17} height={17} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility sx={{ fontSize: 15, color: "#ABABAB" }} />
                      ) : (
                        <EyeOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  color: "#26529C",
                  fontSize: 16,
                  fontWeight: 400,
                },
                sx: {
                  "&::placeholder": {
                    opacity: 1,
                    color: "#26529C",
                  },
                },
              }}
              fullWidth
              variant="standard"
            />
          </Box>
          <div className="flex items-center justify-between mt-[17px] mb-[58px]">
            <div className="flex items-center space-x-[5px]">
              <Checkbox
                sx={{ padding: 0, margin: 0 }}
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                size="small"
              />

              <p className="text-[12px] font-light text-black leading-[18px]">
                Rememer me
              </p>
            </div>
            <p
              onClick={() => navigate("/forgot-password")}
              className="text-[12px] cursor-pointer font-light leading-[18px] text-[#4D4D4D]"
            >
              Forgot Password ?
            </p>
          </div>
          <PrimaryButton
            buttonText={"Login"}
            borderRadius="32px"
            height="53px"
            onClick={handleLogin}
            isLoading={employeeResponse.loading}
          />
        </div>
      </div>
      <AuthCard title="Login" subtitle="Insert Email and Password" />
    </div>
  );
};

export default Login;
