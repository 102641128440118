import { createContext, useContext, useReducer } from "react";
import  tagReducer from "../reducers/TagReducer";

export const TagContext = createContext({
    state: {...tagReducer.initialState },
    dispatch: () => { },
});

export const useTagContext = () => useContext(TagContext);

function TagSlicer({ children }) { 
    const [state, dispatch] = useReducer(
        tagReducer.reducer,
        tagReducer.initialState,
    );

    return (
        <TagContext.Provider value={{state, dispatch}}>
            {children}
        </TagContext.Provider>
    );
}

export default TagSlicer;