import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Ricavi Lordi Generati",
      font: {
        size: 22,
        color: "#000",
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June"];

export const data = {
  labels,
  datasets: [
    {
      label: "Campania",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10000000 })),
      backgroundColor: "#962DFF",
    },
    {
      label: "Sicilia",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10000000 })),
      backgroundColor: "#C893FD",
    },
    {
      label: "Sardegna",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10000000 })),
      backgroundColor: "#E0C6FD",
    },
    {
      label: "Trentino",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 10000000 })),
      backgroundColor: "#F0E5FC",
    },
  ],
};
const BarChart = () => {
  return (
    <div className="bg-white mt-[18px] pt-[25px] pb-[16px] h-[537.02px] rounded-[20px] flex items-center justify-center shadow-lg pl-[12px] pr-[28px]">
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
