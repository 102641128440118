import rightArrow from "assets/icons/right-arrow.svg";
import SecondaryButton from "Components/Button/SecondaryButton";
import React from "react";

const ArrowTextButtonHeader = ({
  title,
  isButton = false,
  buttonText,
  paddingLeft = 0,
  paddingRight = 0,
  marginTop = 24,
  onClick,
  isExpand,
  buttonClick,
}) => {
  return (
    <div
      style={{
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        marginTop: marginTop,
      }}
      className="flex cursor-pointer items-center text-primary justify-between  w-full"
    >
      <div onClick={onClick} className="flex items-center space-x-[10px]">
        <img
          src={rightArrow}
          alt="arrow"
          className={`transform font-bold w-[8px] transition-transform duration-200 ease-in-out ${
            isExpand ? "rotate-90" : "rotate-0"
          }`}
        />
        <h2 className="font-semibold leading-5">{title}</h2>
      </div>
      {isButton && (
        <SecondaryButton onClick={buttonClick} buttonText={buttonText} />
      )}
    </div>
  );
};

export default ArrowTextButtonHeader;
