import noti_icon1 from "assets/images/notification_icon1.png";
import noti_icon2 from "assets/images/notification_icon2.png";
import noti_icon3 from "assets/images/notification_icon3.png";
import noti_icon4 from "assets/images/notification_icon4.png";
import noti_icon5 from "assets/images/notification_icon5.png";

export const notifications = [
  {
    id: 1,
    icon: noti_icon1,
    title: "Alex Cannava ti ha menzionato nella",
    highlightedContent: "Pagina Contatti",
    time: "2 minuti fa",
    date: "Oggi",
  },
  {
    id: 2,
    icon: noti_icon2,
    title: "Marco Sciosia ti ha assegnato una task",
    highlightedContent:
      "Controlla tutti i contatti che hanno richiesto un preventivo",
    time: "6 minuti fa",
    date: "Oggi",
  },
  {
    id: 3,
    icon: noti_icon1,
    title: "Jane Doe ha messo mi piace al tuo post",
    highlightedContent: "Il tuo post sulla pagina principale",
    time: "1 ora fa",
    date: "Oggi",
  },
  {
    id: 4,
    icon: noti_icon2,
    title: "John Smith ha commentato la tua foto",
    highlightedContent: "Il commento sulla tua ultima foto",
    time: "3 ore fa",
    date: "Oggi",
  },
  {
    id: 5,
    icon: noti_icon4,
    title: "Il tuo articolo è stato condiviso da Maria Rossi",
    highlightedContent: "Il tuo articolo sull'ultima tendenza di moda",
    time: "4 ore fa",
    date: "Oggi",
  },
  {
    id: 6,
    icon: noti_icon3,
    title: "Hai un nuovo follower",
    highlightedContent: "Nuovo follower su Instagram",
    time: "Ieri",
    date: "Ieri",
  },
  {
    id: 7,
    icon: noti_icon1,
    title: "Hai ricevuto una notifica dall'applicazione XYZ",
    highlightedContent: "Notifica importante sull'app XYZ",
    time: "2 giorni fa",
    date: "2 giorni fa",
  },
  {
    id: 8,
    icon: noti_icon5,
    title: "Un nuovo evento è stato aggiunto al tuo calendario",
    highlightedContent: "Evento di oggi nel calendario",
    time: "3 giorni fa",
    date: "3 giorni fa",
  },
  {
    id: 9,
    icon: noti_icon5,
    title: "Il tuo ordine è stato spedito",
    highlightedContent: "Ordine numero 123456 spedito",
    time: "4 giorni fa",
    date: "4 giorni fa",
  },
  {
    id: 10,
    icon: noti_icon4,
    title: "Hai completato un'attività nel tuo elenco delle cose da fare",
    highlightedContent: "Attività di pulizia completata",
    time: "5 giorni fa",
    date: "5 giorni fa",
  },
  {
    id: 11,
    icon: noti_icon5,
    title: "Un amico ti ha menzionato in un commento",
    highlightedContent: "Menzione su Facebook",
    time: "6 giorni fa",
    date: "6 giorni fa",
  },
  {
    id: 12,
    icon: noti_icon3,
    title: "Hai ricevuto un premio per il tuo contributo",
    highlightedContent: "Premio per il miglior dipendente del mese",
    time: "1 settimana fa",
    date: "1 settimana fa",
  },
];
