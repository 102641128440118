import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import LandingPage from "Pages/LandingPage/LandingPage";
import Login from "Pages/Login/Login";
import MainPage from "Pages/MainPage/MainPage";
import ModificaBanner from "Pages/ModificaBanner/ModificaBanner";
import NewUser from "Pages/NewUser/NewUser";
import ResetPassword from "Pages/ResetPassword/ResetPassword";
import Settings from "Pages/Settings/Settings";
import Statista from "Pages/Statista/Statista";
import { Team } from "Pages/Team/Team";
import { PERMISSIONS } from "./permisson";
import Dashboard from "Pages/Dashboard/Dashboard";
import Autonomy from "Pages/Autonomy/Autonomy";
import IdealVacationPage from "Pages/IdealVacationPage/IdealVacationPage";
import HotelDetailsPage from "Pages/HotelDetailsPage/HotelDetailsPage";
import HoescapePage from "Pages/HoescapePage/HoescapePage";
import EventPackagePage from "Pages/EventPackagePage/EventPackagePage";
import TouristicDestinationPage from "Pages/TouristicDestinationPage/TouristicDestinationPage";
import ContactPage from "Pages/ContactPage/ContactPage";
import NegotiationPage from "Pages/NegotiationPage/NegotiationPage";
import ContactDetails from "Pages/ContactDetails";
import NegotiationDetails from "Pages/NegotiationDetailPage/NegotiationDetails"

export const routesConfig = [
  {
    path: "/",
    element: <Dashboard />,
    label: "Dashboard",
    requiredAuthentication: true,
    showInSidebar: true,
    requiredPermissions: [PERMISSIONS.ADMIN],
  },
  {
    path: "/statistiche",
    element: <Statista />,
    label: "Statistiche",
    requiredAuthentication: true,
    showInSidebar: true,
    requiredPermissions: [PERMISSIONS.STATISTICS, PERMISSIONS.ADMIN],
  },
  
  {
    path: "/contacts",
    label: "Contatti",
    requiredAuthentication: true,
    showInSidebar: true,
    element: <ContactPage />,
    requiredPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.CONTACTS_NEGOTIATIONS,
      PERMISSIONS.VIEW_CONTACT_NEGOTIATION_STATS,
      PERMISSIONS.EDIT_CONTACT_NEGOTIATION,
    ],
  },
  {
    path: "/contatti-details/:id",
    requiredAuthentication: true,
    showInSidebar: false,
    element: <ContactDetails />,
    requiredPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.CONTACTS_NEGOTIATIONS,
      PERMISSIONS.EDIT_CONTACT_NEGOTIATION,
    ],
  },
  {
    path: "/negotiation-details/:id?",
    requiredAuthentication: true,
    showInSidebar: false,
    element: <NegotiationDetails />,
    requiredPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.CONTACTS_NEGOTIATIONS,
      PERMISSIONS.EDIT_CONTACT_NEGOTIATION,
    ],
  },
  {
    path: "/negotiation",
    label: "Negoziazioni",
    requiredAuthentication: true,
    showInSidebar: true,
    element: <NegotiationPage />,
    requiredPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.CONTACTS_NEGOTIATIONS,
      PERMISSIONS.VIEW_CONTACT_NEGOTIATION_STATS,
      PERMISSIONS.EDIT_CONTACT_NEGOTIATION,
    ],
  },
  {
    path: "/hoEscape",
    label: "HoEscape",
    requiredAuthentication: true,
    showInSidebar: true,
    element: <HoescapePage />,
    requiredPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.HOESCAPE,
      PERMISSIONS.VIEW_HOESCAPE_STATS,
      PERMISSIONS.EDIT_HOESCAPE,
    ],
  },
  {
    path: "/hotel",
    element: <MainPage />,
    label: "Hotel",
    requiredAuthentication: true,
    showInSidebar: true,
    requiredPermissions: [
      PERMISSIONS.VIEW_HOTEL_STATS,
      PERMISSIONS.EDIT_HOTEL,
      PERMISSIONS.HOTEL,
      PERMISSIONS.ADMIN,
    ],
  },
  {
    path: "/hotel-details/:code",
    element: <HotelDetailsPage />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [
      PERMISSIONS.EDIT_HOTEL,
      PERMISSIONS.HOTEL,
      PERMISSIONS.ADMIN,
    ],
  },
  {
    path: "/landing-page/:landingPageId?/:idealVacationId?/:eventPackageId?",
    element: <LandingPage />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [],
  },
  {
    path: "/ideal-vacation/:idealVacationId?",
    element: <IdealVacationPage />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [],
  },
  {
    path: "/event-package/:eventPackageId?",
    element: <EventPackagePage />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [],
  },
  {
    path: "/touristic-destination/:touristicDestinationId?",
    element: <TouristicDestinationPage />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [],
  },
  {
    path: "/autonomy",
    label: "WP Autonomy",
    element: <Autonomy />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [
      PERMISSIONS.EDIT_WP_PLUGINS,
      PERMISSIONS.VIEW_WP_STATS,
      PERMISSIONS.ADMIN,
    ],
  },
  {
    path: "/team",
    label: "Team",
    element: <Team />,
    showInSidebar: true,
    requiredAuthentication: true,
    requiredPermissions: [],
  },

  {
    path: "/banner",
    element: <ModificaBanner />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [],
  },
  {
    path: "/settings",
    element: <Settings />,
    requiredAuthentication: true,
    showInSidebar: false,
    requiredPermissions: [PERMISSIONS.HOTEL, PERMISSIONS.ADMIN],
  },

  {
    path: "/login",
    element: <Login />,
    showInSidebar: false,
    requiredAuthentication: false,
    requiredPermissions: [],
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    showInSidebar: false,
    requiredAuthentication: false,
    requiredPermissions: [],
  },
  {
    path: "/reset/:token/:emailAdddress",
    showInSidebar: false,
    element: <ResetPassword />,
    requiredAuthentication: false,
    requiredPermissions: [],
  },
  {
    path: "/new-user",
    showInSidebar: false,
    element: <NewUser />,
    requiredAuthentication: false,
    requiredPermissions: [],
  },
];
