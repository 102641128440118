export const initialMetadataState = {
    title: '',
    description: '',
}

export const initialImageState = {
    imageUrl: '',
    imageName: '',
    order: null,
    AltText: null,
    metaData: initialMetadataState,
    redirectLink: '',
}
export const serviceTagInitialState = {
    id: '',
    displayName: '',
    code: '',
    TagType: '',
    Status: '',
}

export const roomHandlingInitialState = {
    RoomDescription: [], 
    Rooms: [],
    images: [],
}

export const phoneClientInitialState = {
    Label: '',
    PhoneNumber: '',
    IsDisplayed: false,
}

export const circleAreaDetailsInitialState = {
    latitude: '',
    longitude: '',
    radius: '',
}

export const rectangleAreaDetailsInitialState = {
    latitudeVertex1: '',
    longitudeVertex1: '',
    latitudeVertex2: '',
    longitudeVertex2: '',
}

export const geoFenceDetailsInitialState = {
    id: '',
    circleArea: circleAreaDetailsInitialState,
    rectangleAreaDetailsInitialState: rectangleAreaDetailsInitialState,
}

export const localityDetailsInitialState = {
    id: '',
    geoFenceDetail: geoFenceDetailsInitialState,
    dstCode: '',
    dstName: '',
    iataCode: '',
    subLocality: '',
    locality: '',
    subSubIsland: '',
    subIsland: '',
    islandCounty: '',
    subArchipelago: '',
    archipelago: '',
    regionState: '',
    subSubNation: '',
    subNation: '',
    nationCode: '',
    nationName: '',
    subSubContinent: '',
    subContinent: '',
    continent: '',
}

export const transportGroupInitialState = {
    transportName: []
}

export const ImageCenterPointInitialState = {
    x:0,
    y:0,
}

export const serviceDataResponseInitialResponse = {
    fileUrl: '',
    fileName: '',
    fileType: '',
    order: 0,
    imageCenterPoint: ImageCenterPointInitialState,
    alt: '',
}

export  const featureDetailsInitialState = {
    valueCode : '',
    valueName : '',
    resourceID : '',
    code : '',
    name : '',
    fileUrl: '',
    fileName: '',
    fileType: '',
    order: 0,
    imageCenterPoint: ImageCenterPointInitialState,
    alt: '',
}

export const distancesInitialState = {
    value: 0,
    unitOfMeasurement: '',

}

export const distanceFromInitialState = {
    serviceName:  '',
    distance: [
        distancesInitialState,
    ]
}
export const initialState = {
    id: '',
    hotelCode: '',
    images: [
        initialImageState,
    ],
    videoUrl: null,
    serviceTags: [
       
    ],
    serviceTagHighlighted: [
    ],
    allServiceTags: [
        
    ],
    description: '',
    minDescription: '',
    RoomHandling: roomHandlingInitialState,
    phoneClient: phoneClientInitialState,
    localityDetails: [
        localityDetailsInitialState,
    ],
    transportGroups: [
        transportGroupInitialState,
    ],
    featureDetails: [
        featureDetailsInitialState,
    ],
    distanceFrom: [
        distanceFromInitialState,
    ],
    highlightedFeatures: [
        featureDetailsInitialState,
    ],
    features: [
        featureDetailsInitialState,
    ],
    priorityField:0
}

const FetchHotelDetailsAction = {
    FetchHotelDetails: "FetchHotelDetails",
}

export function FetchHotelDetails(hoteldata) {
    return {
        type: FetchHotelDetailsAction.FetchHotelDetails,
        payload: {
            value: hoteldata,
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case FetchHotelDetailsAction.FetchHotelDetails:
            const newState = action.payload.value;
            return newState;
        default:
            throw new Error('Error while updating Hotel details Dashboard Request: HotelDashboardReducer.reducer');    
    }
}

const HotelDetailsConfigurationReducer = { initialState, reducer }

export default HotelDetailsConfigurationReducer;
