import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import img from "../../assets/tableImg.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useHotelDashboard from "../../library/hooks/UserHotelDashboard/useHotelDashboard";
import useSearchHotels from "../../library/hooks/UseSearchHotels/useSearchHotels";
import { useHotelDetailsConfigurationContext } from "../../library/store/slices/HotelDetailsConfigurationSlicer";

export default function LandingPageHotel({
  setSidebarIndex,
  propQuery,
  propHotelList,
  propStartDate,
  propEndDate,
  sendValueToParent,
}) {
  /**
   * Contaxt
   */
  const { callSearchHotelsApi } = useSearchHotels();
  const { dispatchFetchHotelDetails } = useHotelDashboard();

  const { state: hoteDetailsResponse } = useHotelDetailsConfigurationContext();

  const [open, setOpen] = useState(false);
  const [hotelName, setHotelName] = useState("");
  const [startDate, setStartDate] = useState(propStartDate);
  const [endDate, setEndDate] = useState(propEndDate);
  const [hotels, setHotels] = useState(propHotelList ?? []);
  const [query, setQuery] = useState(propQuery);

  const isStartDateValid = (selectedDate) => {
    return selectedDate <= endDate;
  };

  const isEndDateValid = (selectedDate) => {
    return selectedDate >= startDate;
  };

  const handleStartDateChange = (date) => {
    if (endDate !== null) {
      if (isStartDateValid(date)) {
        setStartDate(date);
      }
    } else {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    if (isEndDateValid(date)) {
      setEndDate(date);
    }
  };

  const handleSubmit = () => {
    sendValueToParent(query, hotels, startDate, endDate);
  };

  /**
   * Hotel list functions
   */

  const handleRemoveItem = (objectCode) => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      const newData = hotels.filter((item) => item.objectCode !== objectCode);
      setHotels(newData);
    }
  };

  /**
   * add Hotel by code functions
   */
  const extractWhereFromQuery = (link) => {
    const startIndex = link.indexOf("where=");
    if (startIndex === -1) return null;

    const endIndex = link.indexOf("&", startIndex);
    if (endIndex === -1) return null;

    return link.substring(startIndex + 6, endIndex);
  };

  const handleAddHotelByQuery = async () => {
    if (!query && query === "") return;

    await callSearchHotelsApi({
      searchText: extractWhereFromQuery(query) ?? "",
      searchByType: "LOCALITY",
      startDate: null,
      endDate: null,
      includeRates: null,
      plusAndMinusDays: null,
      rooms: null,
    })
      .then((res) => {
        let tempList = [];
        res.forEach((h) => {
          tempList.push(addHotelToList(h, "query"));
        });

        setHotels([...hotels, ...tempList]);
      })
      .catch((err) => console.log("Error: geting by query ", err));
  };

  const handleAddHotel = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setHotelName(event.target.value);
  };

  const handleSubmitByHotelCode = () => {
    setOpen(false);
    setHotelName("");
    dispatchFetchHotelDetails(hotelName);
  };

  const addHotelToList = (newHotel, source) => {
    if (!newHotel) return;

    if (hotels.some((hotel) => hotel.objectCode === newHotel.hotelCode)) {
      console.log("Hotel with this code already exists.");
      return;
    }

    return {
      objectCode: newHotel?.hotelCode ?? "",
      description: newHotel?.description ?? "",
      images:
        newHotel?.images?.length > 0 || newHotel?.gallery?.length > 0
          ? [
              {
                imageUrl:
                  source == "code"
                    ? newHotel?.images[0]?.imageUrl
                    : newHotel?.gallery[0]?.image,
                imageName: "",
                order: 0,
              },
            ]
          : [],
    };
  };

  const handleBack = () => {
    setSidebarIndex(1);
  };

  /**
   * SideEffects
   */
  useMemo(() => {
    if (hoteDetailsResponse && hoteDetailsResponse.id !== "") {
      setHotels([...hotels, addHotelToList(hoteDetailsResponse, "code")]);
    }
  }, [hoteDetailsResponse]);

  return (
    <Box
      sx={{
        marginLeft: "34px",
        width: "1003px",
        minHeight: "427px",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Start from a query
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            The system will automatically check that it is not already present
          </Typography>
        </Box>
        <TextField
          sx={{
            marginTop: "10px",
            width: "564px",
            height: "48px",
            borderRadius: "5px",
          }}
          variant="outlined"
          placeholder="url"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            width: "150px",
            height: "55px",
            marginTop: "11px",
            marginLeft: "4px",
          }}
          onClick={handleAddHotelByQuery}
        >
          <Typography sx={{ fontSize: "10px" }}>Add Hotel</Typography>
        </Button>
      </Box>

      <Divider sx={{ margin: "10px 0px" }} />

      <Button
        sx={{
          margin: "15px 20px",
          width: "182px",
          height: "42px",
          color: "black",
          border: "1px solid #E7E7E7",
        }}
        onClick={handleAddHotel}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
          Add Hotel
        </Typography>
      </Button>
      <Box
        sx={{
          margin: "15px 20px",
          borderRadius: "5px",
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        {hotels &&
          hotels.length > 0 &&
          hotels.map((item, index) => (
            <Box sx={{ border: "1px solid #E7E7E7", display: "flex" }}>
              <Box sx={{ display: "flex", margin: "10px" }}>
                <img
                  width={80}
                  height={50}
                  src={item.images[0]?.imageUrl}
                  alt="image"
                />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    marginLeft: "9px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </Box>
              <Box
                sx={{
                  borderLeft: "1px solid #E7E7E7",
                  borderRight: "1px solid #E7E7E7",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 50px",
                }}
              >
                {item?.objectCode}
              </Box>
              <Box
                sx={{
                  minwidth: "314px",
                  display: "block",
                  margin: "auto",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#FF0000",
                    width: "214px",
                    height: "35px",
                    color: "white",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#FF0000" },
                  }}
                  onClick={() => handleRemoveItem(item?.objectCode)}
                >
                  Remove
                </Button>
              </Box>
            </Box>
          ))}
      </Box>
      <Divider sx={{ marginTop: "40px" }} />

      <Box
        sx={{
          marginTop: "19px",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
              Default Dates
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingTop: "6px",
                marginLeft: "10px",
              }}
            >
              (optional)
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "313px",
              fontSize: "15px",
              fontWeight: "500",
              color: "#C7C7C7",
            }}
          >
            When the user arrives on this page he will find these pre-selected
            dates
          </Typography>
        </Box>
        <Box sx={{ marginTop: "10px", display: "flex" }}>
          <DatePicker
            className="date-picker"
            selected={startDate}
            onChange={handleStartDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsStart
            placeholderText="Start Date"
          />
          <DatePicker
            className="second-date-picker"
            selected={endDate}
            onChange={handleEndDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsEnd
            placeholderText="End Date"
            minDate={startDate}
          />
        </Box>
      </Box>
      <Box
        sx={{
          margin: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "186px",
            height: "44px",
            color: "#26529C",
          }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          sx={{
            width: "186px",
            height: "44px",
            backgroundColor: "#26529C",
            color: "white",
            "&:hover": { backgroundColor: "#26529C" },
          }}
          onClick={handleSubmit}
        >
          Publish
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} sx={{ margin: "200px" }}>
        <DialogTitle>Add Hotel</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter your hotel code:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="hotelCode"
            label="Hotel Code"
            type="text"
            fullWidth
            value={hotelName}
            onChange={handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ width: "150px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ width: "150px" }}
            onClick={handleSubmitByHotelCode}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
