import hotelIcon from "../../../assets/icon/hotel.svg";
import locationIcon from "../../../assets/icon/location.svg";
import vectorIcon from "../../../assets/icon/Vector (10).svg";

export const clicksLogDummyData = [
  {
    id: 1,
    name: "Hotel Chateux",
    time: "1 feb 2024 alle 16:43",
    icon: hotelIcon, // Icon url
    location: "🇮🇹 Italia, Milano",
  },
  {
    id: 2,
    name: "San Valentino",
    time: "1 feb 2024 alle 16:32",
    icon: vectorIcon,
    location: "",
  },
  {
    id: 3,
    name: "Abbruzzo",
    time: "1 feb 2024 alle 16:23",
    icon: locationIcon,
    location: "",
  },
  {
    id: 4,
    name: "Hotel Chateux",
    time: "1 feb 2024 alle 16:11",
    icon: hotelIcon,
    location: "🇮🇹 Italia, Milano",
  },
  {
    id: 5,
    name: "Hotel Chateux",
    time: "1 feb 2024 alle 16:39",
    icon: hotelIcon,
    location: "🇮🇹 Italia, Milano",
  },
  {
    id: 6,
    name: "Hotel Chateux",
    time: "1 feb 2024 alle 16:43",
    icon: hotelIcon,
    location: "🇮🇹 Italia, Milano",
  },
  {
    id: 7,
    name: "San Valentino",
    time: "1 feb 2024 alle 16:32",
    icon: vectorIcon,
    location: "",
  },
  {
    id: 8,
    name: "Abbruzzo",
    time: "1 feb 2024 alle 16:23",
    icon: locationIcon,
    location: "",
  },
  {
    id: 9,
    name: "Hotel Chateux",
    time: "1 feb 2024 alle 16:11",
    icon: hotelIcon,
    location: "🇮🇹 Italia, Milano",
  },
  {
    id: 10,
    name: "Hotel Chateux",
    time: "1 feb 2024 alle 16:39",
    icon: hotelIcon,
    location: "🇮🇹 Italia, Milano",
  },
];

export const requestQuotesData = [
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Italy, Milan",
    date: "12/04/2023 - 17/04/2023",
    rooms: [
      "1° Stanza - 2 Adulti, 3 Bambini di 7-9-11 anni",
      "2° Stanza - 1 Adulto, 0 Bambini",
    ],
    extraInfo: [
      {
        title: "Stanza Quadrupla Pensione Completa Bevande Incluse",
        price: "570€",
      },
      {
        title: "Stanza Singola Pensione Completa Bevande Incluse",
        price: "570€",
      },
      {
        title: "Traghetto - Partenza da Olbia",
        price: "202€",
      },
    ],
    accounting: [
      "🟢 Versamento 50% entro 20/09/2024",
      "🟠 Versamento 25% entro 29/09/2024",
      "⚪️ Versamento 25% entro 29/09/2024",
    ],
    price: "1342,00€",
    id: "#23133",
    quotationLink: "https://google.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 France, Paris",
    date: "22/05/2023 - 26/05/2023",
    rooms: ["1° Stanza - 2 Adulti, 1 Bambino di 5 anni"],
    extraInfo: [],
    accounting: [
      "🟢 Versamento 50% entro 15/06/2024",
      "⚪️ Versamento 50% entro 29/06/2024",
    ],
    price: "987,00€",
    id: "#23134",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Spain, Barcelona",
    date: "03/07/2023 - 09/07/2023",
    rooms: ["1° Stanza - 1 Adulto, 2 Bambini di 10-12 anni"],
    extraInfo: [
      {
        title: "Tour Guidato della Città",
        price: "100€",
      },
    ],
    accounting: [],
    price: "650,00€",
    id: "#23135",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Germany, Berlin",
    date: "15/08/2023 - 20/08/2023",
    rooms: ["1° Stanza - 1 Adulto, 1 Bambino di 8 anni"],
    extraInfo: [
      {
        title: "Biglietto per il Museo",
        price: "20€",
      },
    ],
    accounting: [
      "🟢 Versamento 50% entro 30/06/2024",
      "⚪️ Versamento 50% entro 15/07/2024",
    ],
    price: "300,00€",
    id: "#23136",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 United Kingdom, London",
    date: "10/09/2023 - 14/09/2023",
    rooms: ["1° Stanza - 1 Adulto, 2 Bambini di 6-9 anni"],
    extraInfo: [],
    accounting: [
      "🟢 Versamento 50% entro 10/07/2024",
      "⚪️ Versamento 50% entro 25/07/2024",
    ],
    price: "750,00€",
    id: "#23137",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Netherlands, Amsterdam",
    date: "02/10/2023 - 08/10/2023",
    rooms: ["1° Stanza - 2 Adulti, 0 Bambini"],
    extraInfo: [
      {
        title: "Giro in Barca sui Canali",
        price: "50€",
      },
      {
        title: "Visita al Museo di Van Gogh",
        price: "30€",
      },
    ],
    accounting: [],
    price: "800,00€",
    id: "#23138",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Greece, Athens",
    date: "20/11/2023 - 25/11/2023",
    rooms: ["1° Stanza - 2 Adulti, 1 Bambino di 10 anni"],
    extraInfo: [
      {
        title: "Escursione alle Rovine di Atene",
        price: "80€",
      },
      {
        title: "Gita in Barca alle Isole Greche",
        price: "120€",
      },
    ],
    accounting: [
      "🟢 Versamento 50% entro 25/08/2024",
      "⚪️ Versamento 50% entro 10/09/2024",
    ],
    price: "1200,00€",
    id: "#23139",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Portugal, Lisbon",
    date: "05/12/2023 - 10/12/2023",
    rooms: ["1° Stanza - 1 Adulto, 1 Bambino di 12 anni"],
    extraInfo: [
      {
        title: "Tour dei Punti Turistici Principali",
        price: "70€",
      },
      {
        title: "Giro in Tram Storico",
        price: "20€",
      },
    ],
    accounting: [],
    price: "500,00€",
    id: "#23140",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Switzerland, Zurich",
    date: "18/01/2024 - 22/01/2024",
    rooms: ["1° Stanza - 2 Adulti, 2 Bambini di 4-6 anni"],
    extraInfo: [],
    accounting: [
      "🟢 Versamento 50% entro 01/10/2024",
      "⚪️ Versamento 50% entro 15/10/2024",
    ],
    price: "1500,00€",
    id: "#23141",
    quotationLink: "https://example.com",
  },
  {
    hotelName: "Hotel Colia",
    timestamp: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Austria, Vienna",
    date: "10/02/2024 - 15/02/2024",
    rooms: ["1° Stanza - 2 Adulti, 1 Bambino di 8 anni"],
    extraInfo: [
      {
        title: "Biglietto per il Palazzo di Schönbrunn",
        price: "25€",
      },
      {
        title: "Tour delle Strade Principali",
        price: "15€",
      },
      {
        title: "Giro in Bici nei Parchi",
        price: "40€",
      },
    ],
    accounting: [],
    price: "900,00€",
    id: "#23142",
    quotationLink: "https://example.com",
  },
];

export const favouriteHotels = [
  {
    id: 1,
    name: "Hotel Chateux",
    date: "28 gen 2024 alle 19:21",
    location: "🇮🇹 Italia, Milano",
  },
  {
    id: 2,
    name: "Grand Resort",
    date: "15 feb 2024 alle 14:45",
    location: "🇪🇸 Spain, Barcelona",
  },
  {
    id: 3,
    name: "Seaside Villa",
    date: "10 mar 2024 alle 11:30",
    location: "🇫🇷 France, Nice",
  },
  {
    id: 4,
    name: "Mountain Lodge",
    date: "02 apr 2024 alle 09:00",
    location: "🇨🇭 Switzerland, Zermatt",
  },
  {
    id: 5,
    name: "Tropical Paradise Resort",
    date: "18 apr 2024 alle 16:20",
    location: "🇧🇷 Brazil, Rio de Janeiro",
  },
  {
    id: 6,
    name: "Urban Retreat",
    date: "25 apr 2024 alle 20:10",
    location: "🇺🇸 USA, New York City",
  },
  {
    id: 7,
    name: "Coastal Hideaway",
    date: "10 mag 2024 alle 10:15",
    location: "🇦🇺 Australia, Sydney",
  },
  {
    id: 8,
    name: "Historic Inn",
    date: "28 mag 2024 alle 12:30",
    location: "🏴 UK, London",
  },
  {
    id: 9,
    name: "Safari Lodge",
    date: "15 giu 2024 alle 08:45",
    location: "🇿🇦 South Africa, Kruger National Park",
  },
  {
    id: 10,
    name: "Island Resort",
    date: "03 lug 2024 alle 17:55",
    location: "🇲🇻 Maldives, Male",
  },
];

export const configurationDummyData = [
  {
    date: "1 feb 2024 alle 16:43",
    rooms: [
      { adults: 2, children: [7, 9, 11] },
      { adults: 1, children: [] },
    ],
  },
  {
    date: "2 feb 2024 alle 17:30",
    rooms: [
      { adults: 2, children: [8, 10, 12] },
      { adults: 2, children: [5, 6] },
    ],
  },
  {
    date: "3 feb 2024 alle 18:15",
    rooms: [
      { adults: 3, children: [6, 7, 8, 9] },
      { adults: 1, children: [10] },
    ],
  },
  {
    date: "4 feb 2024 alle 19:00",
    rooms: [
      { adults: 1, children: [12] },
      { adults: 2, children: [3, 4] },
    ],
  },
  {
    date: "5 feb 2024 alle 20:00",
    rooms: [
      { adults: 3, children: [5, 6, 7] },
      { adults: 2, children: [8, 9] },
    ],
  },
  {
    date: "6 feb 2024 alle 21:00",
    rooms: [
      { adults: 2, children: [10, 11] },
      { adults: 3, children: [4, 5, 6] },
    ],
  },
  {
    date: "7 feb 2024 alle 22:00",
    rooms: [
      { adults: 1, children: [] },
      { adults: 2, children: [7, 8] },
    ],
  },
  {
    date: "8 feb 2024 alle 23:00",
    rooms: [
      { adults: 3, children: [3, 4, 5] },
      { adults: 1, children: [6] },
    ],
  },
  {
    date: "9 feb 2024 alle 12:00",
    rooms: [
      { adults: 2, children: [9, 10] },
      { adults: 2, children: [11, 12] },
    ],
  },
  {
    date: "10 feb 2024 alle 13:00",
    rooms: [
      { adults: 1, children: [7] },
      { adults: 3, children: [8, 9, 10] },
    ],
  },
];

export const remaningDeposit = [
  {
    remainingDeposit: "100€",
    cancelBooking: "Cancella prenotazione",
    currentBalance: "400€ su 600€ totali",
    nextPaymentDate: "Data Prossimo Pagamento",
    upcomingPayment: "20 Giugno 2024",
    paymentAmount: "300€",
  },
  {
    remainingDeposit: "75€",
    cancelBooking: "Cancella prenotazione",
    currentBalance: "250€ su 450€ totali",
    nextPaymentDate: "Data Prossimo Pagamento",
    upcomingPayment: "10 Luglio 2024",
    paymentAmount: "200€",
  },
  {
    remainingDeposit: "200€",
    cancelBooking: "Cancella prenotazione",
    currentBalance: "1000€ su 1200€ totali",
    nextPaymentDate: "Data Prossimo Pagamento",
    upcomingPayment: "25 Agosto 2024",
    paymentAmount: "600€",
  },
];



export const tileLeftUntilDeparture = [
  {
    id: 1,
    days: "02",
    hours: "10",
    minutes: "30",
    seconds: "50",
    dateRange: "21 agosto 2024 - 28 agosto 2024",
  },
  {
    id: 2,
    days: "06",
    hours: "12",
    minutes: "46",
    seconds: "20",
    dateRange: "14 agosto 2023 - 22 agosto 2023",
  },
  {
    id: 3,
    days: "04",
    hours: "03",
    minutes: "22",
    seconds: "30",
    dateRange: "28 agosto 2024 - 29 agosto 2024",
  },
];

export const termsOfPayment = [
  {
    id: 1,
    paidAmount: "250€",
    paidDate: "6 ottobre 2024",
    remainingAmount: "€370",
    nextPaymentDate: "12 dicembre",
  },
  {
    id: 2,
    paidAmount: "150€",
    paidDate: "10 settembre 2024",
    remainingAmount: "€420",
    nextPaymentDate: "20 novembre",
  },
  {
    id: 3,
    paidAmount: "300€",
    paidDate: "15 novembre 2024",
    remainingAmount: "€300",
    nextPaymentDate: "30 dicembre",
  },
];
