import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Layout from "../../Components/Layout/Layout";
import Box from "@mui/material/Box";
import useContactSetting from "../../library/hooks/Contact/Contact";
import { useContactContext } from "../../library/store/slices/ContactSlicer";
import { format } from "date-fns";
import StatisticsBox from "../../Components/StatisticsBox/StatisticsBox";
import DashboardHeader from "../../Components/Header/DashboardHeader";
import TableNavMenu from "../../Components/Table/TableNavMenu";
import { makeStyles } from "@mui/styles";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import CustomFilter from "../../Components/CustomFilter/CustomFilter";
import TableApp from "../../Components/TableApp/TableApp";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Loading from "Components/Loading/Loading";
import {formatAmount} from "../../utils/formatAmount";
import SettingService from "../../library/services/API/setting";

const useStyles = makeStyles((theme) => ({
  select: {
    "&:hover": {
      color: "#26529C",
    },
  },
  icon: {
    fill: "#26529C",
  },
  menuItem: {
    color: "#26529C",
    "&:hover": {
      backgroundColor: "#e3e9f2",
    },
  },
}));

export default function ContactPage() {
  const classes = useStyles();
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [contactRows, setContactRows] = useState([]);
  // const [statisticsData, setStatisticsData] = useState([]);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [activeButton, setActiveButton] = useState("Tutti i Contatti");
  const [totalContact, setTotalContact] = useState(0);
  const [recentContactDone ,setRecentContactDone] = useState();
  const [recentContactWithMultipleDone ,setRecentContactWithMultipleDone] = useState();
  const [newTotal, setNewTotal] = useState(0);
  const [recentQuoteTotal, setRecentQuoteTotal] = useState(0);
  const [moreQuoteTotal, setMoreQuoteTotal] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchType, setSearchType] = useState("ALL");
  const [totalRecords, setTotalRecords] = useState(0);
  const [reqParams, setReqParams] = useState();
  const [contactDetail, setContactDetail] = useState("contact");
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const [isLoading ,setIsLoading] = useState(false);

  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const [sortedColumn,setSortedColumn] = useState("");
  const [sortedValue,setSortedValue] = useState("");
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [contactConfiguration,setContactConfiguration] = useState(null);
  const [userDataLocal,setUserDataLocal] = useState(null);


  const statisticsData = [
    {
      title: "TOTALE CONTATTI",
      number: totalContact,
      subtitle: "Tutti i Contatti",
    },
    {
      title: "NUOVI CONTATTI",
      number: newTotal,
      subtitle: "Ultimi 7 giorni",
    },
    {
      title: "CONTATTI PREVENTIVO RECENTE",
      number: recentContactDone,
      subtitle: "Ultimi 7 giorni",
    },
    {
      title: "CONTATTI CON PIÙ PREVENTIVI",
      number: recentContactWithMultipleDone,
      subtitle: "Ultimi 7 giorni",
    },
  ];

  const hideColumns = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "totalRevenue",
    "totalValueRequestedInQuotes",
    "numberOfRequestAQuoteDone",
    "averagePricePerPerson",
    "numberOfBookingsDone",
    "numberOfQuotesRequested",
    "lastNegotiationCreated",
    "status",
    "fidelityStatus",
    "mostFrequentTag",
    "mostFrequentNumberOfStars",
    "lastRoomConfigurationSearched",
    "remarketingAction",
    "numberRequestedSupport",
    "createdDate",
  ];

  const { callFetchAllContactsApi, dispatchForStatisticParamByText } =
    useContactSetting();
  const { state: contactResponse } = useContactContext();
  // const [pageNumber, setPageNumber] = useState(1);
  const gridApi = useRef(null);
  const columnApi = useRef(null);

  const [columnVisibility, setColumnVisibility] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    totalRevenue: false,
    numberOfBookingsDone: false,
    numberOfQuotesRequested: false,
    sourceFirstTouchPoint: false,
    fiscalCode: false,
    customerRecordCode: false,
    fidelityStatus: false,
    lastRoomConfigurationSearched: false,
    remarketingAction: false,
    numberRequestedSupport: false,
    createdDate: false,
  });

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 100,
      filter: "agNumberColumnFilter",
    },
    {
      field: "totalRevenue",
      headerName: "Total Revenue",
      minWidth: 50,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => {
        return formatAmount(params.value / 100)
        //return params.value / 100
      },
    },
    {
      field: "numberOfBookingsDone",
      headerName: "Number of Bookings Done",
      minWidth: 150,
      filter: "agNumberColumnFilter",
    },
    {
      field: "numberOfQuotesRequested",
      headerName: "Number of Quotes Requested",
      minWidth: 50
    },
    {
      field: "sourceFirstTouchPoint",
      headerName: "Source First TouchPoint",
      minWidth: 50
    },
    {
      field: "fiscalCode",
      headerName: "Fiscal Code",
      minWidth: 50
    },
    {
      field: "customerRecordCode",
      headerName: "Customer Record Code",
      minWidth: 100,
      filter: "agNumberColumnFilter"
    },
    {
      field: "lastNegotiationCreated",
      headerName: "Last Negotiation Created",
      minWidth: 100,
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), " dd MMM yyyy")
          : "";
      },
    },
    {
      field: "fidelityStatus",
      headerName: "Fidelity Status",
      minWidth: 100,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 100,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => {
        return params.value
          ? format(new Date(params.value), " dd MMM yyyy HH:mm:ss")
          : "";
      },
    },
  ];

  const handleColumnVisibilityChange = (event) => {
    const columnName = event.target.value;
    const isChecked = event.target.checked;

    setColumnVisibility({
      ...columnVisibility,
      [columnName]: isChecked,
    });
  };

  useEffect(() => {
    dispatchForStatisticParamByText(true);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const body = { skip, take ,searchType, sortedColumn, sortedValue };
    callFetchAllContactsApi(body);
    //handleGridReady();
  }, [skip, take,searchType]);

  const handleToggle = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  
  useMemo(() => {
    if (
      contactResponse.contactNegotiationsRS != null &&
      contactResponse.contactNegotiationsRS.length > 0
    ) {
      setContactRows(contactResponse.contactNegotiationsRS);
      setRowData(contactResponse.contactNegotiationsRS);
      setTotalRecords(contactResponse.totalData);

      reqParams?.successCallback(
        contactResponse.contactNegotiationsRS,
        totalContact
      );

      if (gridApi.current != null) {
        gridApi.current.hideOverlay();
      }
      setIsLoading(false); 
    }
    if (contactResponse.contactNegotiationStatisticRS != null) {
      setTotalContact(contactResponse.contactNegotiationStatisticRS.total);
      setNewTotal(contactResponse.contactNegotiationStatisticRS.newTotal);
      setRecentQuoteTotal(
        contactResponse.contactNegotiationStatisticRS.recentQuoteTotal
      );
      setMoreQuoteTotal(
        contactResponse.contactNegotiationStatisticRS.moreQuoteTotal
      );
      setRecentContactDone(contactResponse.contactNegotiationStatisticRS.recentContactDone);
      setRecentContactWithMultipleDone(contactResponse.contactNegotiationStatisticRS.recentContactWithMultipleDone);
      // callFetchAllContactsApi(pageNumber, 10)
    }
    setIsLoading(false); 
  }, [contactResponse]);

  
  const onPageChange = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    const newSkip = (pageNumber - 1) * take;
    setSkip(newSkip);
  };

  const handleSort = (sortedColumn, sortedValue) => {
    setSortedColumn(sortedColumn);
    setSortedValue(sortedValue);
    const body = { skip, take, searchType, sortedColumn, sortedValue };
    setIsLoading(true);
    callFetchAllContactsApi(body);
  };

  const GetContactConfiguration = async (userData) => {
    try {
      const response = await SettingService.GetContactConfiguration(userData?.data?.id);
      setContactConfiguration(response);
      return response;
    } catch (error) {
      console.error("Error fetching contact configuration:", error);
    }
  };

  const saveContactConfiguration = async (body) => {
    try {
      const response = await SettingService.saveContactConfiguration(body);
    } catch (error) {
      console.error("Error fetching contact configuration:", error);
    }
  };

  const onGridReadyOut = (params) => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;
    //handleGridReady();
  };

  const handleGridReady = (savedColumnState) => {
    if (columnApi.current) {
      if (savedColumnState) {
        try {
          const parsedState = savedColumnState;
          columnApi.current.applyColumnState({
            state: parsedState,
            applyOrder: true,
          });
        } catch (error) {
          console.error("Failed to apply column state:", error);
        }
      }
    }
  };

  const onColumnStateChangedOut = () => {
    if (columnApi.current) {
      const columnState = columnApi.current.getColumnState();
      if(userDataLocal){
          let body = {
            email : userDataLocal.data.email,
            user_id : userDataLocal.data.id,
            type :'CONTACT',
            configurations : columnState
          }
          saveContactConfiguration(body);
      }
      //localStorage.setItem('columnState' + 'Contact', JSON.stringify(columnState));
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    let userData = JSON.parse(storedUserData);
    setUserDataLocal(userData);
    GetContactConfiguration(userData);
  }, []);

  useEffect(() => {
    if(contactConfiguration){
      handleGridReady(contactConfiguration.configurations);
    }
  }, [contactConfiguration,columns]);

  const toPage = skip + take;
  return (
    <Layout
      handleToggle={handleToggle}
      isSidebarOpened={isSidebarOpened}
      isMessageIconVisable={false}
    >
        <Loading isLoading={isLoading} />
      <Box
        sx={{ marginLeft: "50px", marginRight: "50px", marginBottom: "20px" }}
      >
        <DashboardHeader
          title="Contatti"
          subtitle={totalContact + " Contatti"}
          isExportButton={true}
        />
        <div className="box-level flex items-center mt-[28px] space-x-[48px]">
          {statisticsData.map((item, ind) => (
            <StatisticsBox item={item} key={ind} ind={ind} />
          ))}
        </div>
        <TableNavMenu
          navBtnTxt1="Tutti i Contatti"
          navBtnTxt2="Nuovi Contatti"
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          onSearchTypeChange={(type) => {
            setIsLoading(true);
            setSearchType(type); // set the search type state
          }}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "26px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <CustomFilter
              hideColumns={hideColumns}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              columnVisibility={columnVisibility}
            />
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "36px",
            marginBottom: "20px",
            minWidth: "100%",
            overflowX: "auto",
          }}
        >
          {
            activeButton === "Tutti i Contatti" && (
              <div
                className="ag-theme-quartz" // applying the grid theme
                //style={{ height: 500 }} // the grid will fill the size of the parent container
              >
                <TableApp
                  rows={contactRows}
                  columns={columns}
                  take={take}
                  skip={skip}
                  contactDetail={contactDetail}
                  setSkip={setSkip}
                  setTake={setTake}
                  currentPageNumber={contactResponse?.currentPageNumber}
                  recordsPerPage={contactResponse?.recordsPerPage}
                  totalPages={contactResponse?.totalPages}
                  totalRecords={contactResponse?.totalRecords}
                  handleSort={handleSort}
                  type={"Contact"}
                  onGridReady={onGridReadyOut}
                  onColumnStateChanged={onColumnStateChangedOut}
                />
                <div className="pagination_menu">
                  <div className="page_size_part">
                    <span style={{ fontSize: "14px" }}>Page Size:</span>
                    <Select
                      value={take}
                      onChange={(e) => {
                        setTake(e.target.value);
                        setCurrentPageNumber(1);
                        setSkip(0);
                      }}
                      inputProps={{
                        "aria-label": "Select Assegnatario Contatti",
                      }}
                      classes={{ select: classes.select, icon: classes.icon }}
                      sx={{
                        marginLeft: "10px",
                        height: "25px",
                        width: "70px",
                        "&.MuiSelect-select .makeStyles-select-16 .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input ":
                          { padding: "10px" },
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </div>
                  <div className="pagination_count pagination_font">
                    <span style={{ fontSize: "14px" }}>
                      <span style={{ fontWeight: "600" }}>{skip + 1}</span>
                      {` to `}
                      <span style={{ fontWeight: "600" }}>
                        {toPage > totalRecords ? totalRecords : toPage}
                      </span>
                      {` of `}
                      <span style={{ fontWeight: "600" }}>{totalRecords}</span>
                      {` `}
                    </span>
                  </div>
                  <div className="pagination_count pagination_font">
                    <button
                      disabled={currentPageNumber === 1}
                      onClick={() => onPageChange(currentPageNumber - 1)}
                    >
                      <ChevronLeftIcon />
                    </button>
                    <span style={{ fontSize: "14px" }}>
                      {` Page `}
                      <span style={{ fontWeight: "600" }}>
                        {currentPageNumber}
                      </span>
                      {` of `}
                      <span style={{ fontWeight: "600" }}>
                        {Math.ceil(totalRecords / take)}
                      </span>
                      {` `}
                    </span>
                    <button
                      disabled={
                        currentPageNumber === Math.ceil(totalRecords / take)
                      }
                      onClick={() => onPageChange(currentPageNumber + 1)}
                    >
                      <ChevronRightIcon />
                    </button>
                  </div>
                </div>
              </div>
            )

            // renderTable(
            //   contactRows,
            //   columns,
            //   gridRef,
            //   "/contatti-details"
            // )
          }
          {/* {activeButton === "Nuovi Contatti" &&
              renderTable(nuovi_contatti, columns, gridRef, "/contatti-details")}
            {activeButton === "Assegnati a Me" &&
              renderTable(assegnati_a_me, columns, gridRef, "/contatti-details")} */}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <img className="-mr-[28px]" src={logo} alt="logo" />
        </Box>
      </Box>
    </Layout>
  );
}
