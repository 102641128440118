export const initialState = [];


const TagsAction = {
    FetchTags: "FetchTags",
}

export function FetchTags(hoteldata) {
    return {
        type: TagsAction.FetchTags,
        payload: {
            value: hoteldata,
        }
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case TagsAction.FetchTags:
            const newState = action.payload.value;
            if (!Array.isArray(newState)) {
                // Handle the case where newState is not an array
                // For example, you might want to wrap it in an array or handle it differently
                return state; // Returning the current state as a fallback
            }
            return [
                ...newState
            ];
        default:
            throw new Error('Error while Get Tags Dashboard Request: Tags.reducer');    
    }
}


const TagReducer = { initialState, reducer }

export default TagReducer;